import { styled } from '@mui/material/styles'
import { ListSubheader, ListItemButton } from '@mui/material'

export const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  borderRadius: 6,
  paddingLeft: 12,
  paddingRight: 12,
  ':not(:last-child)': {
    marginBottom: 4,
  },
  paddingTop: 4,
  paddingBottom: 4,
}))

export const StyledListSubheader = styled(ListSubheader)(() => ({
  lineHeight: 'inherit!important',
  marginBottom: 8,
}))
