import { FC, createContext, useContext } from 'react'
import { IntlProvider } from 'react-intl'
import _includes from 'lodash/includes'
import { useLocalStorage } from '../../hooks'

export type Locale = {
  id: string
  icon: string
  titleKey: string
}

type LocaleContext = {
  locale: string
  availableLocales: Locale[]
  setLocale: (locale: string) => void
}

const Context = createContext<LocaleContext>({
  locale: '',
  availableLocales: [],
  setLocale: () => {},
})

function getBrowserLocale(): string {
  return (navigator.language ?? '').split('-')[0]
}

export type LocaleProviderProps = {
  locales: Record<string, Record<string, string>>
  availableLocales: Locale[]
  defaultLocale: string
}

export const LocaleProvider: FC<LocaleProviderProps> = ({
  locales,
  availableLocales,
  defaultLocale,
  children,
}) => {
  const [storageLocale, setLocale] = useLocalStorage('locale', getBrowserLocale())
  const availableLocaleKeys = availableLocales.map(({ id }) => id)
  const locale = _includes(availableLocaleKeys, storageLocale) ? storageLocale : defaultLocale
  const messages = locales[locale]

  return (
    <Context.Provider value={{ availableLocales, locale, setLocale }}>
      <IntlProvider key={locale} locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export const useLocale = () => {
  return useContext(Context)
}
