import { ChangeEvent, FC } from 'react'
import {
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Country,
  getCountryByCode,
  useModalRef,
} from '@web-panel/shared'
import { FormikProps } from 'formik'
import { useIntl, FormattedMessage } from 'react-intl'
import * as Yup from 'yup'
import getFlag from 'country-flag-icons/unicode'
import { phone } from 'phone'
import { termsLink, privacyPolicyLink } from '@local/constants'
import CountryPickModal, { defaultCountry } from './Modals/CountryPick'

function autoFormatPhoneNumber(
  country: Country,
  phoneNumber: string
): { country: Country; phoneNumber: string } {
  let detectedCountry = country
  let formattedPhoneNumber = phoneNumber

  if (phoneNumber[0] === '+') {
    const match = phone(phoneNumber)
    if (match.isValid) {
      detectedCountry = getCountryByCode(match.countryIso2)
      formattedPhoneNumber = formattedPhoneNumber.replace(detectedCountry.phone, '')
    }
  } else {
    const match = phone(`${country.phone}${phoneNumber}`)
    if (match.isValid) {
      formattedPhoneNumber = match.phoneNumber.replace(country.phone, '')
    }
  }

  return {
    country: detectedCountry,
    phoneNumber: formattedPhoneNumber,
  }
}

export type FormFields = {
  country: Country
  phoneNumber: string
  accepted: boolean
}

export const defaultValues: FormFields = {
  country: defaultCountry,
  phoneNumber: '',
  accepted: false,
}

export const validationSchema = Yup.object().shape({
  country: Yup.object().required(),
  phoneNumber: Yup.string()
    .required()
    // eslint-disable-next-line func-names
    .test('full-phone-validation', 'Is not valid', function (phoneNumber) {
      const { isValid } = phone(`${this.parent.country.phone}${phoneNumber}`)
      return isValid
    }),
  accepted: Yup.boolean().oneOf([true]),
})

type SignInFormProps = FormikProps<FormFields>

const SignInForm: FC<SignInFormProps> = ({
  values,
  handleSubmit,
  handleChange,
  setValues,
  isValid,
  dirty,
}) => {
  const { formatMessage } = useIntl()
  const countryPickModalRef = useModalRef()

  const aggrementMessage = (
    <>
      <FormattedMessage id="login_agree_to_kooky" />
      <a target="_blank" href={termsLink} rel="noreferrer">
        <FormattedMessage id="login_tos" />
      </a>
      <FormattedMessage id="login_and" />
      <a target="_blank" href={privacyPolicyLink} rel="noreferrer">
        <FormattedMessage id="login_pp" />
      </a>
    </>
  )

  const handleCountryInput = (val: Country) => {
    const { country, phoneNumber } = autoFormatPhoneNumber(val, values.phoneNumber)
    setValues({ ...values, country, phoneNumber })
  }

  const handlePhoneInput = (e: ChangeEvent<HTMLInputElement>) => {
    const tel = e.target.value.replace(/\s/g, '')
    const { country, phoneNumber } = autoFormatPhoneNumber(values.country, tel)
    setValues({ ...values, country, phoneNumber })
  }

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="body1">
        <FormattedMessage id="web_login_description" />
      </Typography>
      <div className="flex flex-row mt-32">
        <CountryPickModal ref={countryPickModalRef} onPick={handleCountryInput} />
        <TextField
          className="w-128 max-w-128 mr-12"
          value={`${getFlag(values.country.code)} ${values.country.phone}`}
          onClick={() => countryPickModalRef.current?.open()}
        />
        <TextField
          autoFocus
          fullWidth
          inputProps={{
            inputMode: 'numeric',
            autoComplete: 'tel',
          }}
          label={formatMessage({ id: 'login_phone_number' })}
          value={values.phoneNumber}
          onChange={handlePhoneInput}
        />
      </div>
      <FormControlLabel
        className="mt-8"
        label={aggrementMessage}
        control={<Checkbox checked={values.accepted} onChange={handleChange('accepted')} />}
      />
      <Button
        disabled={!isValid || !dirty}
        fullWidth
        className="mt-24"
        variant="contained"
        onClick={() => handleSubmit()}
      >
        <FormattedMessage id="login_cta" />
      </Button>
    </form>
  )
}

export default SignInForm
