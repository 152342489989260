import { FC } from 'react'
import Helmet from 'react-helmet'
import { getApplicationTitleBrand } from '../../helpers/globals'
import { getApplicationName } from '../../contexts/PageTitle/registrations'

type PageTitleProps = {
  title: string
}

export const PageTitle: FC<PageTitleProps> = ({ title }) => {
  return (
    <Helmet>
      <title>
        {getApplicationTitleBrand()} | {getApplicationName()} - {title}
      </title>
    </Helmet>
  )
}
