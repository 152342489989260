import { gql } from '@apollo/client'

export type TriggerDispenserDocumentInput = {
  qrCode: string
}
export type TriggerDispenserDocumentResponse = void
export const TriggerDispenserDocument = gql`
  mutation TriggerDispenserDocument($qrCode: String!) {
    triggerDispenser(qrCode: $qrCode)
      @rest(
        type: "NoChache"
        path: "/finance-service/dispensers/qr/{args.qrCode}/item"
        method: "GET"
      ) {
      NoBody
    }
  }
`
