export enum RentalStatus {
  Requested = 'REQUESTED',
  Valid = 'VALID',
  Rejected = 'REJECTED',
  Deleted = 'DELETED',
}

export enum RentalInventoryType {
  Box = 'BOX',
  Dispenser = 'DISPENSER',
}

type BaseRental = {
  id: string
  externalId: string
  status: RentalStatus
  partnerId: string
  outletId: string | null
  startDate: string
  endDate: string | null
  amount: number
  countryCode: string
}

const baseRentalFields = `
  id
  externalId
  status
  partnerId
  outletId
  startDate
  endDate
  amount
  countryCode
`

export type Rental = BaseRental & {
  inventoryType: RentalInventoryType
}

export const rentalFields = `
  ${baseRentalFields}
  inventoryType
`

export type BoxRental = BaseRental

export const boxRentalFields = baseRentalFields
