import { ThemeOptions, isFeatureFlagDisabled } from '@web-panel/shared'

const palette = isFeatureFlagDisabled('neom')
  ? {
      primary: {
        main: '#0B3C4D',
      },
      secondary: {
        main: '#222222',
      },
      success: {
        main: '#1FCF86',
      },
      info: {
        main: '#FAB621',
      },
      error: {
        main: '#F44336',
      },
      background: {
        default: '#FFFFFF',
      },
      text: {
        disabled: '#B6C6C9',
      },
    }
  : {
      primary: {
        main: '#222222',
      },
      secondary: {
        main: '#222222',
      },
      success: {
        main: '#006B44',
      },
      info: {
        main: '#FAB621',
      },
      error: {
        main: '#E0403F',
      },
      background: {
        default: '#FFFFFF',
      },
      text: {
        disabled: '#B6C6C9',
      },
    }

export const themeOverrides: ThemeOptions = {
  typography: {
    fontFamily: ['Work Sans'].join(','),
    h1: {
      fontSize: '4.5rem',
      fontWeight: 800,
      lineHeight: '5.3rem',
    },
    h2: {
      fontSize: '3rem',
      fontWeight: 700,
      lineHeight: '3.5rem',
    },
    h3: {
      fontSize: '2.4rem',
      fontWeight: 700,
      lineHeight: '2.815rem',
    },
    h4: {
      fontSize: '1.8rem',
      lineHeight: '2.1rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.4rem',
      lineHeight: '1.6rem',
      fontWeight: 700,
    },
    body1: {
      fontSize: '1.6rem',
      lineHeight: '2.3rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '1.4rem',
      lineHeight: '1.9rem',
      fontWeight: 400,
    },
    caption: {
      fontSize: '1.2rem',
      lineHeight: '1.6rem',
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: '1.2rem',
      lineHeight: '1.6rem',
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: '1rem',
      lineHeight: '1.1rem',
      fontWeight: 500,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderWidth: 1,
          borderColor: '#E4E5E6',
          borderRadius: '1.6rem',
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          paddingTop: '0.8rem',
          paddingBottom: '0.8rem',
          color: '#D9DCE1',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingBottom: '0.8rem',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '0.8rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeMedium: {
          fontSize: '1.8rem',
          marginTop: '1.5rem',
          marginBottom: '1.5rem',
          fontWeight: 700,
          borderRadius: '3.0rem',
          height: '5rem',
        },
        sizeLarge: {
          borderWidth: '0.2rem',
          fontSize: '1.8rem',
          lineHeight: '2.1rem',
          fontWeight: 700,
        },
        containedSecondary: {
          backgroundColor: '#FFFFFF',
          color: '#0B3C4D',
          '&:hover': {
            backgroundColor: '#FFFFFF',
            color: '#0B3C4D',
          },
        },
        containedPrimary: {
          backgroundColor: isFeatureFlagDisabled('neom') ? palette.primary.main : '#13100D',
        },
        outlinedPrimary: {
          color: isFeatureFlagDisabled('neom') ? palette.primary.main : '#13100D',
          borderColor: isFeatureFlagDisabled('neom') ? palette.primary.main : '#13100D',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: '3.2rem',
          height: '3.2rem',
          border: '1px solid #E5E5E5',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: '2.4rem',
          height: '2.4rem',
          fill: palette.primary.main,
          // stroke: palette.primary.main,
        },
      },
    },
  },
  palette,
}
