import { FC } from 'react'
import { Button, Typography, setCurrentRouteAsAfterAuth } from '@web-panel/shared'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { appLogo, appLogoSize } from '@local/app-logo'
import illustrations from '@local/assets/illustrations'

export const WelcomeComponent: FC = () => {
  const navigate = useNavigate()

  const onNavigate = () => {
    setCurrentRouteAsAfterAuth()
    navigate('/sign-in')
  }

  return (
    <div className="h-full px-24 pt-48 pb-20">
      <div className="h-full flex flex-col">
        <div>
          <img className={`h-${appLogoSize}`} src={appLogo} alt="logo" />
        </div>
        <div className="pt-24">
          <Typography variant="h2" color="primary">
            <FormattedMessage id="welcome_screen_title" />
          </Typography>
          <Typography className="mt-6" variant="body1">
            <FormattedMessage id="welcome_screen_description" />
          </Typography>
        </div>
        <div className="flex flex-1 flex-col justify-center items-center">
          <img src={illustrations.connectCup} height="50" alt="welcome" />
        </div>
        <div className="flex flex-col justify-end">
          <Button className="m-0" fullWidth variant="contained" onClick={onNavigate}>
            <FormattedMessage id="welcome_screen_button" />
          </Button>
        </div>
      </div>
    </div>
  )
}
