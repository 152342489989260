import { FC } from 'react'
import { Typography, Skeleton, useTheme } from '@web-panel/shared'
import { CupIcon } from '@local/components/Icons'

export const LoyaltyCardContainerLoading: FC = () => {
  const theme = useTheme()
  const items = new Array(4).fill(null)

  return (
    <div className="pt-13 pb-18 px-16" style={{ backgroundColor: theme.palette.background.paper }}>
      <div className="flex flex-row">
        <div className="pt-3">
          <Skeleton className="rounded" width={31} height={31} variant="rectangular" />
        </div>
        <div className="flex flex-col pl-8 flex-1">
          <Skeleton variant="text">
            <Typography variant="h4" color="primary">
              Lost and Found
            </Typography>
          </Skeleton>
          <Skeleton variant="text">
            <Typography variant="caption" color="text.commentary">
              Kookytopia
            </Typography>
          </Skeleton>
        </div>
        <div className="flex flex-row">
          <div>
            <Skeleton variant="text">
              <Typography variant="h4" color="primary">
                10
              </Typography>
            </Skeleton>
          </div>
          <div className="pt-2 ml-2">
            <CupIcon active size={16} />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-5 mt-12">
        {items.map((_, id) => (
          <div key={id} className="flex flex-col items-center">
            <Skeleton className="rounded" width={60} height={60} variant="rectangular" />
            <Skeleton variant="text">
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                +0.00 CHF
              </Typography>
            </Skeleton>
            <Skeleton>
              <Typography variant="caption" color="text.commentary">
                AAAAAA
              </Typography>
            </Skeleton>
          </div>
        ))}
        <div className="flex flex-col items-center">
          <Skeleton className="rounded" height={76} width={60} variant="rectangular" />
        </div>
      </div>
    </div>
  )
}
