import { FC } from 'react'
import {
  DialogContent,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  buildModal,
  ModalBodyProps,
  styled,
  formatCurrency,
} from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { useGoldenCupCampaign } from '@local/hooks/golden-cup-hooks'

import { useUpdateCampaignExceptions } from '@web-panel/consumer-api'

const StyledListItem = styled(ListItem)(() => ({
  borderTop: '1px solid rgba(0, 0, 0, 0.24)',
  padding: 0,
}))

const StyledListItemText = styled(ListItemText)(() => ({
  fontSize: '1.7rem',
  lineHeight: '2.2rem',
  color: '#007AFF',
  textAlign: 'center',
}))

type GoldenCupOptOutModalBodyProps = ModalBodyProps & {
  onCloseParent: () => void
}
const GoldenCupOptOutModalBody: FC<GoldenCupOptOutModalBodyProps> = ({
  onClose,
  onCloseParent,
}) => {
  const { formatNumber } = useIntl()
  const { request: updateCampaignExceptions } = useUpdateCampaignExceptions()
  const { goldenCupCampaign } = useGoldenCupCampaign()

  const amount = goldenCupCampaign
    ? formatCurrency(goldenCupCampaign.amount, goldenCupCampaign.currencyCode)
    : formatNumber(0)

  const handleOptOut = async () => {
    if (!goldenCupCampaign) return
    await updateCampaignExceptions({ campaignId: goldenCupCampaign.id })
    onClose()
    onCloseParent()
  }

  return (
    <DialogContent className="flex flex-col items-center px-0">
      <Typography variant="body2" align="center">
        <FormattedMessage id="golden_cup_opt_out_modal_description" values={{ amount }} />
      </Typography>
      <List className="w-full p-0 mt-20">
        <StyledListItem>
          <ListItemButton onClick={handleOptOut}>
            <StyledListItemText>
              <b>
                <FormattedMessage
                  id="golden_cup_opt_out_modal_confirm_button"
                  values={{ amount }}
                />
              </b>
            </StyledListItemText>
          </ListItemButton>
        </StyledListItem>
        <StyledListItem>
          <ListItemButton onClick={onClose}>
            <StyledListItemText>
              <FormattedMessage id="golden_cup_opt_out_modal_cancel_button" />
            </StyledListItemText>
          </ListItemButton>
        </StyledListItem>
      </List>
    </DialogContent>
  )
}

export const GoldenCupOptOutModal = buildModal(GoldenCupOptOutModalBody)
