import { FC, useMemo } from 'react'
import { Paper, ButtonBase, Typography } from '@web-panel/shared'
import classNames from 'classnames'

type WidgetProps = {
  illustration: string
  illustrationAlign: 'center' | 'right'
  title: string
  value?: number
  valueColor: 'primary' | 'success' | 'error'
  co2Value?: number
  co2Unit?: string
  co2Label?: string
  co2Sign?: string
  forceKilo?: boolean
  onClick?: () => void
}

export const Widget: FC<WidgetProps> = ({
  illustration,
  title,
  value,
  valueColor,
  co2Value,
  co2Unit,
  co2Label,
  co2Sign,
  forceKilo = false,
  onClick,
  illustrationAlign,
}) => {
  const emissionString = useMemo(() => {
    if (!co2Unit || !co2Value) return null

    const val = co2Value >= 1000 || forceKilo ? (co2Value / 1000).toFixed(2) : co2Value.toFixed(2)
    const unit = co2Value >= 1000 || forceKilo ? `k${co2Unit}` : co2Unit

    return `${co2Sign ?? ''}${val}${unit}`
  }, [co2Value, co2Unit])

  return (
    <Paper className="w-full pl-8 pb-8">
      <ButtonBase className="block text-left w-full" onClick={onClick}>
        <div
          className={classNames('mb-12', 'flex', 'flex-row', {
            'justify-end': illustrationAlign === 'right',
            'justify-center': illustrationAlign === 'center',
          })}
        >
          <img src={illustration} width="86" height="86" alt="cup-illustration" />
        </div>
        <Typography variant="body2">{title}</Typography>
        {value && (
          <Typography className="mt-2" variant="h3" color={`${valueColor}.main`}>
            {value}
          </Typography>
        )}
        {emissionString && (
          <Typography variant={value ? 'caption' : 'h3'} color={`${valueColor}.main`}>
            {emissionString}
            {value && <>&nbsp;{co2Label}</>}
          </Typography>
        )}
      </ButtonBase>
    </Paper>
  )
}
