import { Currency, currencyFields } from './currency'

export enum DonationPaymentType {
  BankAccount = 'BANK_ACCOUNT',
  CreditCard = 'CREDIT_CARD',
  WWFAccount = 'WWF_ACCOUNT',
}

export enum DonationStatus {
  Created = 'CREATED',
  Processed = 'PROCESSED',
  Failed = 'FAILED',
  Rejected = 'REJECTED',
}

export enum DonationOrganization {
  WWF = 'WWF',
}

export type Donation = {
  id: string
  externalId: number
  consumerId: string
  createDate: string
  amount: number
  amountToWithdraw: number
  currency: Currency
  status: DonationStatus
  donationOrganization: DonationOrganization
  receipt: string | null
}

export const donationFields = `
  id
  externalId
  consumerId
  createDate
  amount
  amountToWithdraw
  currency {
    ${currencyFields}
  }
  status
  donationOrganization
  receipt
`
