import { FC } from 'react'
import { Typography, Button } from '@web-panel/shared'
import PageHeader from '@local/components/PageHeader'
import { useNavigate } from 'react-router-dom'
import illustrations from '@local/assets/illustrations/neom'

const DispenserNextStepPage: FC = () => {
  const navigate = useNavigate()
  const title = 'Time to scan the cup'

  return (
    <div className="flex flex-col h-full">
      <div className="px-24 pb-20">
        <PageHeader hideLogo htmlTitle={title} title={title} />
        <Typography className="" variant="body1">
          Scan the QR code on the cup
        </Typography>
      </div>
      <div className="flex flex-col flex-1 mx-24 items-center">
        <div className="flex-1 flex flex-col justify-center">
          <img width="267" className="mb-40" src={illustrations.cupScanning} alt="cup scanning" />
        </div>
        <Button
          fullWidth
          className="mb-0"
          variant="contained"
          onClick={() => navigate('/scan', { replace: true, state: { onlyCupScan: true } })}
        >
          Scan the cup
        </Button>
        <Button fullWidth variant="outlined" onClick={() => navigate('/', { replace: true })}>
          Scan later
        </Button>
      </div>
    </div>
  )
}

export default DispenserNextStepPage
