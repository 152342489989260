import { FC, ReactElement } from 'react'
import { Route } from 'react-router-dom'
import path from 'path'
import _flatten from 'lodash/flatten'
import ProtectedRoute, { AccessStrategy } from './ProtectedRouteElement'
import { getLayoutElement } from '../../registrations/layouts'

export type PathConfig = {
  key: string
  path: string
  Component: FC
  Layout?: FC
  accessStrategy?: AccessStrategy
  routes?: PathConfig[]
}

export type RouteConfig = PathConfig[]

export function buildRoute(route: PathConfig, parentPath = '/'): ReactElement[] {
  const { key, Component, accessStrategy } = route
  const Layout = route.Layout ?? getLayoutElement('DefaultLayout')
  const routePath = path.join(parentPath, route.path)

  const element = (
    <Layout>
      <Component />
    </Layout>
  )

  const routeElement = accessStrategy ? (
    <ProtectedRoute routeKey={key} accessStrategy={accessStrategy}>
      {element}
    </ProtectedRoute>
  ) : (
    element
  )

  if (!route.routes || route.routes.length === 0)
    return [<Route key={key} path={routePath} element={routeElement} />]

  const subRoutes = _flatten(
    route.routes.map((subRoute) =>
      buildRoute(
        { accessStrategy: route.accessStrategy, Layout: route.Layout, ...subRoute },
        routePath
      )
    )
  )
  return [<Route key={key} path={routePath} element={routeElement} />, ...subRoutes]
}
