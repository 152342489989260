import { FC } from 'react'
import { DefaultLayout } from '../layouts/DefaultLayout'

type LayoutElements = {
  DefaultLayout: FC
}

type LayoutElementName = keyof LayoutElements

const elements: LayoutElements = {
  DefaultLayout,
}

export function registerLayoutElement<T extends LayoutElementName>(
  name: T,
  content: LayoutElements[T]
) {
  elements[name] = content
}

export function getLayoutElement<T extends LayoutElementName>(name: T): LayoutElements[T] {
  return elements[name]
}
