import { FC, MouseEventHandler } from 'react'
import { ListItemButton, Icon, ListItemText } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import classNames from 'classnames'

const Root = styled(ListItemButton)(({ theme, ...props }) => ({
  height: 40,
  width: '100%',
  borderRadius: '6px',
  margin: '0 0 4px 0',
  paddingRight: 12,
  color: alpha(theme.palette.text.primary, 0.7),
  cursor: 'pointer',
  textDecoration: 'none!important',
  '&:hover': {
    color: theme.palette.text.primary,
  },
  '&.active': {
    color: theme.palette.text.primary,
    backgroundColor: 'rgba(0, 0, 0, .05)!important',
    pointerEvents: 'none',
    transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
    '& > .list-item-text-primary': {
      color: 'inherit',
    },
    '& > .list-item-icon': {
      color: 'inherit',
    },
  },
  '& >.list-item-icon': {
    fontSize: '2rem',
    marginRight: 12,
    color: 'inherit',
  },
  '& > .list-item-text': {},
}))

type NavbarSectionItemProps = {
  active?: boolean
  icon?: string
  title: string
  badge?: FC
  onClick: MouseEventHandler
}

export const NavbarSectionItem: FC<NavbarSectionItemProps> = ({
  active = false,
  icon,
  title,
  badge,
  onClick,
}) => {
  const Badge = badge

  return (
    <Root className={classNames('nav-list-item', { active })} role="button" onClick={onClick}>
      <Icon className="list-item-icon flex-shrink-0" color="action">
        {icon}
      </Icon>
      <ListItemText
        className="list-item-text"
        primary={title}
        classes={{
          primary: 'text-13 font-medium list-item-text-primary',
        }}
        primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
      />
      {Badge && <Badge />}
    </Root>
  )
}
