import _includes from 'lodash/includes'
import { ApplicationEnv } from '../globals'

export function getAppEnv(): string {
  return ApplicationEnv ?? 'development'
}

type UseAppEnvResponse = {
  appEnv: string
  isAppEnv: (env: string[] | string) => boolean
}

export const useAppEnv = (): UseAppEnvResponse => {
  const appEnv = getAppEnv()

  const isAppEnv = (match: string[] | string): boolean => {
    if (typeof match === 'string') {
      return ApplicationEnv === match
    }
    return _includes(match, ApplicationEnv)
  }

  return {
    appEnv,
    isAppEnv,
  }
}
