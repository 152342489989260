export enum RentalConfigurationStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type BoxRentalConfiguration = {
  id: string
  status: RentalConfigurationStatus
  countryCode: string
  currencyCode: string
  price: number
}

export type CollectionBoxRentalConfiguration = Omit<BoxRentalConfiguration, 'id'> & {
  id: BoxRentalConfiguration['id'] | null
}

export const boxRentalConfigurationFields = `
  id
  status
  countryCode
  currencyCode
  price
`
