import { FC, useMemo } from 'react'
import { ConsumerItemHistoryCount, ProductItemType } from '@web-panel/api'
import { useModalRef } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import illustrations from '@local/assets/illustrations/neom'
import { useUserItemsCount, useItemsEnvironmentalInfo } from '@web-panel/consumer-api'
import { Widget } from '../Widget'
import InfoDescriptionModal from '../../../Modals/InfoDescriptionModal'

const emptyCount: ConsumerItemHistoryCount = { inUse: 0, returned: 0, losted: 0 }

export const SummaryWidgets: FC = () => {
  const { formatMessage } = useIntl()
  const { data: environmentalInfo = [] } = useItemsEnvironmentalInfo()
  const { data: itemsCount } = useUserItemsCount({ fetchPolicy: 'cache-and-network' })
  const descriptionModal = useModalRef()

  const { co2Saved, co2Produced, co2TotalImpact, unit } = useMemo(() => {
    const cupInfo = environmentalInfo.find(({ type }) => type === ProductItemType.Cup)
    if (!cupInfo)
      return {
        co2Saved: 0,
        co2Produced: 0,
        co2TotalImpact: 0,
        unit: 'g',
      }

    const produced = cupInfo.co2EmissionsProducedValue * (itemsCount?.losted ?? 0)
    const saved = cupInfo.co2EmissionsSavedValue * (itemsCount?.returned ?? 0)

    return {
      co2Produced: produced,
      co2Saved: saved,
      co2TotalImpact: produced - saved,
      unit: cupInfo.co2EmissionsUnit,
    }
  }, [itemsCount, environmentalInfo])

  const { returned, losted } = itemsCount ?? emptyCount

  return (
    <div className="flex flex-row space-x-12 w-full">
      <Widget
        title={formatMessage({ id: 'history_labelReturned' })}
        value={returned}
        valueColor="success"
        illustration={illustrations.cupReturned}
        illustrationAlign="center"
        co2Value={co2Saved}
        co2Unit={unit}
        co2Sign="-"
        onClick={() => descriptionModal.current?.open()}
      />
      <Widget
        title={formatMessage({ id: 'history_labelLost' })}
        value={losted}
        valueColor="error"
        illustration={illustrations.cupLost}
        illustrationAlign="center"
        co2Value={co2Produced}
        co2Unit={unit}
        co2Sign="+"
        onClick={() => descriptionModal.current?.open()}
      />
      <Widget
        forceKilo
        title={formatMessage({ id: 'history_labelTotalImpact' })}
        valueColor="primary"
        illustration={illustrations.cupTotalImpact}
        illustrationAlign="center"
        co2Value={co2TotalImpact}
        co2Unit={unit}
        co2Sign={co2TotalImpact > 0 ? '+' : ''}
        onClick={() => descriptionModal.current?.open()}
      />
      <InfoDescriptionModal ref={descriptionModal} />
    </div>
  )
}
