import { FC, ReactElement } from 'react'
import { BrowserRouter } from 'react-router-dom'
import {
  LocaleProvider,
  LocaleProviderProps,
  ThemeContextProvider,
  NotificationProvider,
  AuthStateContextProvider,
  AuthStateContextProviderProps,
  NavbarProvider,
  ToolbarContextProvider,
  ToolbarContextProviderProps,
  RouterProvider,
  PageTitleProvider,
} from './contexts'

type AppProps = {
  auth: AuthStateContextProviderProps
  locale: LocaleProviderProps
  IntermediateContext?: FC<{ children: ReactElement }>
  toolbar: ToolbarContextProviderProps
}

const EmptyContext: FC<{ children: ReactElement }> = ({ children }) => children

const App: FC<AppProps> = ({ auth, locale, IntermediateContext = EmptyContext, toolbar }) => {
  return (
    <LocaleProvider {...locale}>
      <ThemeContextProvider>
        <NotificationProvider>
          <AuthStateContextProvider {...auth}>
            <IntermediateContext>
              <NavbarProvider>
                <ToolbarContextProvider {...toolbar}>
                  <PageTitleProvider>
                    <BrowserRouter>
                      <RouterProvider />
                    </BrowserRouter>
                  </PageTitleProvider>
                </ToolbarContextProvider>
              </NavbarProvider>
            </IntermediateContext>
          </AuthStateContextProvider>
        </NotificationProvider>
      </ThemeContextProvider>
    </LocaleProvider>
  )
}

export default App
