import { FC } from 'react'
import { motion } from 'framer-motion'
import { styled, darken } from '@mui/material/styles'
import { LanguageSwitcher } from '../components/LanguageSwitcher'

const Root = styled('div')(({ theme }) => ({
  background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
    theme.palette.primary.dark,
    0.5
  )} 100%)`,
  color: theme.palette.primary.contrastText,
}))

export const AuthLayout: FC = ({ children }) => {
  return (
    <div id="layout" className="w-full flex">
      <div className="flex flex-auto min-w-0">
        <main className="flex flex-col flex-auto min-h-screen min-w-0 relative z-10">
          <div className="flex flex-col flex-auto min-h-0 relative z-10">
            <Root className="flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24">
              <div className="flex w-full max-w-400 md:max-w-3xl justify-end pb-24">
                <LanguageSwitcher />
              </div>
              <motion.div
                initial={{ opacity: 0, scale: 0.6 }}
                animate={{ opacity: 1, scale: 1 }}
                className="flex w-full max-w-400 md:max-w-3xl rounded-20 shadow-2xl overflow-hidden"
              >
                {children}
              </motion.div>
            </Root>
          </div>
        </main>
      </div>
    </div>
  )
}
