import { FC, useState, useRef, ChangeEvent, MouseEvent, KeyboardEvent } from 'react'
import { TextField } from '@mui/material'
import classNames from 'classnames'

type CodeInputProps = {
  length: number
  disabled?: boolean
  onCodeEnter?: (code: string) => void
  inputMode?: 'text' | 'numeric'
  autoComplete?: string
  autoFocus?: boolean
}

export const CodeInput: FC<CodeInputProps> = ({
  length,
  onCodeEnter,
  disabled = false,
  inputMode = 'text',
  autoComplete,
  autoFocus,
}) => {
  const [codeCells, setCodeCells] = useState<string[]>([])
  const refs = useRef<(HTMLInputElement | null)[]>([])

  if (refs.current.length === 0) {
    for (let i = 0; i < length; i += 1) {
      refs.current.push(null)
    }
  }

  const handleCellChange = (id: number) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const str = e.target.value

      const cells = [...codeCells]

      if (str) {
        refs.current[id + 1]?.focus()
        for (let i = 0; i < Math.min(str.length, length - id); i += 1) {
          cells[i + id] = str[i].toUpperCase()
        }

        const nextFocusId = Math.min(str.length + id, length - 1)
        refs.current[nextFocusId]?.focus()
      } else if (id - 1 < length && !str) {
        refs.current[id - 1]?.focus()
        cells[id] = ''
      }

      setCodeCells(cells)
      const code = cells.join('')

      if (code.length === length && onCodeEnter) onCodeEnter(code)
    }
  }

  const handleCellKeyDown = (id: number) => {
    return (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.keyCode === 8 && !codeCells[id] && id > 0) {
        refs.current[id - 1]?.focus()
        e.preventDefault()
      }
    }
  }

  const handleCellFocus = (id: number) => {
    return (e: MouseEvent<HTMLInputElement>) => {
      const firstEmptyCellId = codeCells.length > 0 ? codeCells.findIndex((el) => el === '') : 0
      const cellId = firstEmptyCellId >= 0 && firstEmptyCellId < id ? firstEmptyCellId : id
      const cellRef = refs.current[cellId]
      if (cellId !== id) {
        e.preventDefault()
        refs.current[id]?.blur()
        cellRef?.focus()
      }
      cellRef?.select()
    }
  }

  return (
    <div className={classNames('grid', 'gap-12', `grid-cols-${length}`)}>
      {refs.current.map((ref, id) => (
        <TextField
          key={id}
          autoFocus={id === 0 ? autoFocus : false}
          autoComplete={autoComplete}
          disabled={disabled}
          inputRef={(el) => {
            refs.current[id] = el
          }}
          inputProps={{
            inputMode,
          }}
          value={codeCells[id] ?? ''}
          onChange={handleCellChange(id)}
          onKeyDown={handleCellKeyDown(id)}
          onMouseDown={handleCellFocus(id)}
        />
      ))}
    </div>
  )
}
