import { gql } from '@apollo/client'
import {
  ConsumerLoyaltyOutlet,
  consumerLoyaltyOutletFields,
  ConsumerLoyaltyOutletItem,
  consumerLoyaltyOutletItemFields,
  ConsumerLoyaltyOutletReward,
  consumerLoyaltyOutletRewardFields,
} from '@web-panel/api'

export type ConsumerLoyaltyOutletsDocumentInput = undefined
export type ConsumerLoyaltyOutletsDocumentResponse = {
  consumerLoyaltyOutlets: {
    outlets: ConsumerLoyaltyOutlet[]
    hasNext: boolean
  }
}

export const ConsumerLoyaltyOutletsDocument = gql`
  query ConsumerLoaltyOultets {
    consumerLoyaltyOutlets @rest(type: "ConsumerLoyaltyOutlets", path: "/data-repository/loyalty/users/outlets") {
      outlets @type(name: "ConsumerLoyaltyOultet") {
        ${consumerLoyaltyOutletFields}
      }
      hasNext
    }
  }
`

export type ConsumerLoyaltyOutletItemsDocumentInput = {
  limit?: number
  offset?: number
  outletId: string
}

export type ConsumerLoyaltyOutletItemsDocumentResponse = {
  consumerLoyaltyOutletItems: {
    items: ConsumerLoyaltyOutletItem[]
    hasNext: boolean
  }
}

export const ConsumerLoyaltyOutletItemsDocument = gql`
  query ConsumerLoyaltyOutletItemsDocument($outletId: String!, $limit: Int!, $offset: Int!) {
    consumerLoyaltyOutletItems(outletId: $outletId, limit: $limit, offset: $offset) @rest(type: "ConsumerLoyaltyOutletItems", path: "/data-repository/loyalty/users/outlets/{args.outletId}/items?limit={args.limit}&offset={args.offset}") {
      items @type(name: "ConsumerLoyaltyOutletItem") {
        ${consumerLoyaltyOutletItemFields}
      }
      hasNext
    }
  }
`

export type ConsumerLoyaltyOutletDocumentInput = {
  outletId: string
}
export type ConsumerLoyaltyOutletDocumentResponse = {
  consumerLoyaltyOutlet: {
    outlet: ConsumerLoyaltyOutlet
    items: ConsumerLoyaltyOutletItem[]
  }
}

export const ConsumerLoyaltyOutletDocument = gql`
  query ConsumerLoaltyOutlet($outletId: String!) {
    consumerLoyaltyOutlet(outletId: $outletId) @rest(type: "ConsumerLoyaltyOutletResponse", path: "/operations-service/users/items/loyalty/outlet/{args.outletId}") {
      outlet @type(name: "ConsumerLoyaltyOutlet") {
        ${consumerLoyaltyOutletFields}
      }
      items @type(name: "ConsumerLoyaltyOutletItem") {
        ${consumerLoyaltyOutletItemFields}
      }
    }
  }
`

export type ConsumerLoyaltyOutletRewardsDocumentInput = {
  outletId: string
}
export type ConsumerLoyaltyOutletRewardsDocumentResponse = {
  consumerLoyaltyOutletRewards: {
    rewards: ConsumerLoyaltyOutletReward[]
  }
}

export const ConsumerLoyaltyOutletRewardsDocument = gql`
  query ConsumerLoaltyOutletRewards($outletId: String!) {
    consumerLoyaltyOutletRewards(outletId: $outletId) @rest(type: "ConsumerLoyaltyOutletRewards", path: "/operations-service/users/loyalty/rewards/outlet/{args.outletId}") {
      rewards @type(name: "ConsumerLoyaltyOutletReward") {
        ${consumerLoyaltyOutletRewardFields}
      }
    }
  }
`
