import { useQuery } from '@apollo/client'
import { QueryHook } from '@web-panel/api'
import {
  ChatCredentialsDocument,
  ChatCredentialsDocumentInput,
  ChatCredentialsDocumentResponse,
} from '../docs'

export const useChatCredentials: QueryHook<
  ChatCredentialsDocumentInput,
  ChatCredentialsDocumentResponse['chatCredentials']
> = (options) => {
  const { data, loading, refetch } = useQuery<ChatCredentialsDocumentResponse>(
    ChatCredentialsDocument,
    options
  )

  return {
    data: data?.chatCredentials,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}
