import { FC, ReactElement } from 'react'
import { styled } from '@mui/material/styles'
import { Navbar } from '../components/Navbar'
import { Toolbar } from '../components/Toolbar'

const Root = styled('div')(({ theme }) => ({
  '& .container': {
    width: '100%',
    margin: '0 auto',
  },
}))

type DefaultLayoutProps = {
  banner?: ReactElement
}

export const DefaultLayout: FC<DefaultLayoutProps> = ({ banner, children }) => {
  return (
    <Root id="layout" className="w-full flex flex-col">
      {banner}
      <div className="flex flex-auto min-w-0">
        <Navbar />
        <div id="main" className="flex flex-col flex-auto min-h-screen min-w-0 relative z-10">
          <Toolbar />
          {children}
        </div>
      </div>
    </Root>
  )
}
