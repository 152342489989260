import { useMutation, useQuery } from '@apollo/client'
import { MutationHook, QueryHook } from '@web-panel/api'
import {
  PaymentMethodsDocument,
  PaymentMethodsDocumentInput,
  PaymentMethodsDocumentResponse,
  CreateWWFAccountDocument,
  CreateWWFAccountDocumentInput,
  CreateWWFAccountDocumentResponse,
  DeleteWWFAccountDocumentInput,
  DeleteWWFAccountDocumentResponse,
  DeleteWWFAccountDocument,
  UpdateWWFAccountDocumentInput,
  UpdateWWFAccountDocumentResponse,
  UpdateWWFAccountDocument,
} from '../docs'

export const usePaymentMethods: QueryHook<
  PaymentMethodsDocumentInput,
  PaymentMethodsDocumentResponse['paymentMethods']
> = (options) => {
  const { data, loading, refetch } = useQuery<PaymentMethodsDocumentResponse>(
    PaymentMethodsDocument,
    options
  )

  return {
    data: data?.paymentMethods,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useCreateWWFAccount: MutationHook<
  CreateWWFAccountDocumentInput,
  CreateWWFAccountDocumentResponse
> = (options) => {
  const [execute, { loading }] =
    useMutation<CreateWWFAccountDocumentResponse>(CreateWWFAccountDocument)

  async function request(variables: CreateWWFAccountDocumentInput) {
    await execute({ variables, refetchQueries: [{ query: PaymentMethodsDocument }], ...options })
  }

  return {
    request,
    loading,
  }
}

export const useDeleteWWFAccount: MutationHook<
  DeleteWWFAccountDocumentInput,
  DeleteWWFAccountDocumentResponse
> = (options) => {
  const [execute, { loading }] =
    useMutation<DeleteWWFAccountDocumentResponse>(DeleteWWFAccountDocument)

  async function request(variables: DeleteWWFAccountDocumentInput) {
    await execute({ variables, refetchQueries: [{ query: PaymentMethodsDocument }], ...options })
  }

  return {
    request,
    loading,
  }
}

export const useUpdateWWFAccount: MutationHook<
  UpdateWWFAccountDocumentInput,
  UpdateWWFAccountDocumentResponse['updateWWFAccount']
> = (options) => {
  const [execute, { loading }] =
    useMutation<UpdateWWFAccountDocumentResponse>(UpdateWWFAccountDocument)

  async function request(variables: UpdateWWFAccountDocumentInput) {
    const { data } = await execute({
      variables,
      refetchQueries: [{ query: PaymentMethodsDocument }],
      ...options,
    })
    if (!data) throw new Error('Could not update wwf account')
    return data.updateWWFAccount
  }

  return {
    request,
    loading,
  }
}
