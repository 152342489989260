import { useState, useMemo } from 'react'
import { countries, Country } from '../constants/countries'

const perPage = 50

function getFilteredCountries(filter?: string) {
  if (!filter) return countries

  return countries.filter((country) => {
    const matchPhone = country.phone.indexOf(filter) > -1
    const matchName = country.name.toLowerCase().indexOf(filter.toLowerCase()) > -1
    const matchNative = country.native.toLowerCase().indexOf(filter.toLowerCase()) > -1
    return matchPhone || matchName || matchNative
  })
}

export const useCountries = (
  filter?: string
): {
  data: Country[]
  loadMore: () => void
} => {
  const [page, setPage] = useState(1)
  const filteredCountries = useMemo(() => getFilteredCountries(filter), [filter])
  const data = filteredCountries.slice(0, page * perPage)

  const loadMore = () => {
    const nextPage = page + 1
    if (nextPage * perPage > filteredCountries.length) return

    setPage(nextPage)
  }

  return {
    data,
    loadMore,
  }
}
