import { FC } from 'react'
import { Typography, styled, FormattedCurrency } from '@web-panel/shared'
import illustrations from '@local/assets/illustrations'
import { useGoldenCupCampaign } from '@local/hooks/golden-cup-hooks'
import { FormattedMessage } from 'react-intl'

const Container = styled('div')(() => ({
  backgroundColor: '#FDF5DF',
}))

export const GoldenCardSuccessScreenSection: FC = () => {
  const { goldenCupCampaign: campaign } = useGoldenCupCampaign()
  if (!campaign) return null

  return (
    <Container className="py-8 px-24">
      <Typography variant="body1" color="primary.main" sx={{ fontWeight: 600 }}>
        <FormattedMessage id="golden_cup_success_screen_section_title" />
      </Typography>
      <div className="flex flex-row items-center">
        <img width="92px" src={illustrations.goldenCup} alt="golden cup" />
        <div className="mt-10">
          <Typography variant="body2" color="secondary.main">
            <FormattedMessage id="golden_cup_success_screen_section_description1" />
            <b>
              <FormattedMessage id="golden_cup_success_screen_section_description2" />
              &nbsp;
            </b>
            <FormattedMessage id="golden_cup_success_screen_section_description3" />
          </Typography>
          <Typography variant="h3" color="primary.main" className="mt-4">
            <FormattedCurrency
              value={campaign.amount}
              currency={campaign.currencyCode}
              minimumFractionDigits={0}
            />
          </Typography>
        </div>
      </div>
    </Container>
  )
}
