import { FC, createContext, useContext } from 'react'
import _includes from 'lodash/includes'
import { useSearchParams } from '../hooks'

export enum SortDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export function buildSortingContext<SortingFieldName extends string>() {
  type ContextData =
    | {
        field: SortingFieldName
        direction: SortDirection
      }
    | {
        field: undefined
        direction: undefined
      }
  type ContextMethods = {
    toggleSort: (field: SortingFieldName) => void
    handleToggleSort: (field: SortingFieldName) => () => void
  }

  type SortingContext = ContextData & ContextMethods

  const Context = createContext<SortingContext>({
    field: undefined,
    direction: undefined,
    toggleSort: () => {},
    handleToggleSort: () => () => {},
  })

  const SortingContextProvider: FC = ({ children }) => {
    const [searchParams, setSearchParams] = useSearchParams()

    const field = searchParams.sortfield as SortingFieldName | undefined
    const direction = _includes(Object.values(SortDirection), searchParams.sortdir)
      ? (searchParams.sortdir as SortDirection)
      : undefined

    const toggleSort = (togglingField: SortingFieldName) => {
      if (togglingField === field) {
        const newDirection =
          direction === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending
        setSearchParams({
          sortfield: togglingField,
          sortdir: newDirection,
        })
      } else {
        setSearchParams({
          sortfield: togglingField,
          sortdir: SortDirection.Ascending,
        })
      }
    }

    const handleToggleSort = (togglingField: SortingFieldName) => {
      return () => toggleSort(togglingField)
    }

    const data =
      !field || !direction
        ? {
            field: undefined,
            direction: undefined,
          }
        : {
            field,
            direction,
          }

    return (
      <Context.Provider value={{ ...data, toggleSort, handleToggleSort }}>
        {children}
      </Context.Provider>
    )
  }

  function withSorting(Component: FC): FC {
    return () => (
      <SortingContextProvider>
        <Component />
      </SortingContextProvider>
    )
  }

  const useSorting = () => {
    return useContext(Context)
  }

  return { SortingContext: SortingContextProvider, withSorting, useSorting }
}
