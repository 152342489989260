import { Currency, currencyFields } from './currency'
import { RewardTemplateSubType, RewardTemplateType } from './loyalty-campaign'

export enum ConsumerLoyaltyOutletItemStatus {
  Unknown = 'UNKNOWN',
  InUse = 'IN_USE',
  Lost = 'LOST',
  Returned = 'RETURNED',
  Transferred = 'TRANSFERRED',
  Reusing = 'REUSING',
  BoxCheckedIn = 'BOX_CHECKED_IN',
}

export type ConsumerLoyaltyOutlet = {
  id: string
  name: string
  address: string
  logo: string | null
  itemsCount: number
}

export const consumerLoyaltyOutletFields = `
  id
  name
  address
  logo
  itemsCount
`

export type ConsumerLoyaltyOutletItemRefund = {
  amount: number
  currency: Currency
}

export const consumerLoyaltyOutletItemRefundFields = `
  amount
  currency {
    ${currencyFields}
  }
`

export type ConsumerLoyaltyOutletItemCampaign = {
  id: string
  code: string
  amount: number
  currency: Currency
}

export const consumerLoyaltyOutletItemCampaignFields = `
  id
  code
  amount
  currency {
    ${currencyFields}
  }
`

export type ConsumerLoyaltyOutletReward = {
  targetReturnsToUnlock: number
  remainingReturnsToUnlock: number
  remainingNumberOfRewards: number
  campaignEndDate?: string
  rewardType: RewardTemplateType
  rewardSubType: RewardTemplateSubType
  description: string
  logo?: string
  amount: number
  currency: Currency
  createDate: string
  validTillDate?: string
  redemptionDate?: string
  redemptionValidTillDate?: string
}

export const consumerLoyaltyOutletRewardFields = `
  targetReturnsToUnlock
  remainingReturnsToUnlock
  remainingNumberOfRewards
  campaignEndDate
  rewardType
  rewardSubType
  description
  logo
  amount
  currency {
    ${currencyFields}
  }
  createDate
  validTillDate
  redemptionDate
  redemptionValidTillDate
`

export type ConsumerLoyaltyOutletItem = {
  id: string
  itemId: string
  itemQrCode: string
  checkoutDate: string
  takeDate: string
  returnDate: string
  status: ConsumerLoyaltyOutletItemStatus
  refund: ConsumerLoyaltyOutletItemRefund | null
  campaign: ConsumerLoyaltyOutletItemCampaign | null
  rewards: ConsumerLoyaltyOutletReward[] | null
}

export const consumerLoyaltyOutletItemFields = `
  id
  itemId
  itemQrCode
  checkoutDate
  takeDate
  returnDate
  status
  refund {
    ${consumerLoyaltyOutletItemRefundFields}
  }
  campaign {
    ${consumerLoyaltyOutletItemCampaignFields}
  }
  rewards {
    ${consumerLoyaltyOutletRewardFields}
  }
`
