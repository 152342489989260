import { gql } from '@apollo/client'
import { ConsumerAccount, consumerAccountFields } from '@web-panel/api'

export type AccountDocumentInput = undefined

export type AccountDocumentResponse = {
  account: ConsumerAccount
}

export const AccountDocument = gql`
  query Account {
    account @rest(type: "ConsumerAccount", path: "/finance-service/account") {
      ${consumerAccountFields}
    }
  }
`
