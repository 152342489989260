import { Address, addressFields } from './address'

export type SequenceNode = {
  id: string
  partnerAddress: Address
}

export const sequenceNodeFields = `
  id
  partnerAddress {
    ${addressFields}
  }
`
