import { countries } from './countries'

export function getCountryByCode(requestedCode: string) {
  const country = countries.find(({ code }) => code === requestedCode)

  if (!country) throw new Error('can not find country')

  return country
}

export function getCountryByCodeOrNull(requestedCode: string) {
  try {
    return getCountryByCode(requestedCode)
  } catch (err) {
    return null
  }
}
