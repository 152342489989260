import { FC, ReactElement } from 'react'

export type NavigationItemConfig = {
  icon: string
  translationKey: string
  badge?: FC
  path: string
}

export type NavigationSectionConfig = {
  translationKey?: string
  routes: NavigationItemConfig[]
}

export type NavigationConfig = NavigationSectionConfig[]
export type NavigationProvider = FC<{
  children: (navigationConfig: NavigationConfig) => ReactElement
}>

type Registrations = {
  config: NavigationConfig
  NavigationProvider: NavigationProvider | null
  defaultBackLabelKey?: string
}

let registrations: Registrations = {
  config: [],
  NavigationProvider: null,
  defaultBackLabelKey: undefined,
}

type RegistrationKey = keyof Registrations

export function registerNavigation<Key extends RegistrationKey>(
  key: Key,
  value: Registrations[Key]
) {
  registrations[key] = value
}

export function clearNavigationRegistrations() {
  registrations = {
    config: [],
    NavigationProvider: null,
    defaultBackLabelKey: undefined,
  }
}

export function getNavigation<Key extends RegistrationKey>(key: Key): Registrations[Key] {
  return registrations[key]
}
