import { FC } from 'react'
import { List } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledList = styled(List)(({ theme }) => ({
  paddingLeft: '1.2rem',
  paddingRight: '1.2rem',
  '& .nav-list-item': {
    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, .04)',
    },
    '&:focus:not(.active)': {
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.06)' : 'rgba(0, 0, 0, .05)',
    },
  },
  '&.active-square-list': {
    '& .nav-list-item, & .active.nav-list-item': {
      width: '100%',
      borderRadius: '0',
    },
  },
  '&.dense': {
    '& .nav-list-item': {
      paddingTop: 0,
      paddingBottom: 0,
      height: 32,
    },
  },
}))

export const NavbarSectionList: FC = ({ children }) => {
  return <StyledList className="navigation whitespace-nowrap">{children}</StyledList>
}
