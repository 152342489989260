import { FC } from 'react'
import {
  DialogContent,
  Typography,
  Button,
  buildModal,
  ModalBodyProps,
  countries,
  useModalRef,
  useFormatDate,
  FormattedCurrency,
  formatCurrency,
} from '@web-panel/shared'
import { useUserGoldenCupCampaign } from '@local/hooks/golden-cup-hooks'
import { FormattedMessage } from 'react-intl'
import { getAppLink } from '@local/helpers'
import illustrations from '@local/assets/illustrations'
import { GoldenCupOptOutModal } from './OptOutModal'

export const defaultCountry = countries.find(({ code }) => code === 'AT') ?? countries[0]

type GoldenCupModalContentProps = ModalBodyProps

const GoldenCupModalContent: FC<GoldenCupModalContentProps> = ({ onClose }) => {
  const formatDate = useFormatDate()
  const optOutModalRef = useModalRef()
  const { goldenCupCampaign } = useUserGoldenCupCampaign()
  if (!goldenCupCampaign) return null

  const amount = formatCurrency(goldenCupCampaign.amount, goldenCupCampaign.currencyCode)

  const handleFindPartner = async () => {
    const link = await getAppLink('/map')
    window.open(link, '_blank')
  }

  return (
    <>
      <DialogContent className="flex flex-col items-center">
        <img width="163px" height="163px" src={illustrations.goldenCup} alt="golden cup" />
        <Typography variant="h2" color="primary.main" className="mb-12">
          <FormattedMessage id="golden_cup_info_modal_title1" />
          &nbsp;
          <span style={{ color: '#FAB621' }}>
            <FormattedMessage id="golden_cup_info_modal_title2" />
          </span>
        </Typography>
        <Typography variant="body1" color="secondary.main" align="center" className="mb-28">
          <FormattedMessage
            id="golden_cup_info_modal_description1"
            values={{ amount, date: formatDate('human-date', goldenCupCampaign.endDate) }}
          />
        </Typography>
        <Typography variant="body1" color="secondary.main">
          <FormattedMessage id="golden_cup_info_modal_description2" />
        </Typography>
        <Typography variant="body1" color="secondary.main" sx={{ fontWeight: '600' }}>
          <FormattedMessage id="golden_cup_info_modal_description3" />
        </Typography>
        <a
          target="participation-terms"
          href={goldenCupCampaign.termsAndConditionsUrl}
          className="my-12"
        >
          <Typography variant="caption" sx={{ fontWeight: 600 }}>
            <FormattedMessage id="golden_cup_info_modal_participation_terms_link" />
          </Typography>
        </a>
        <Typography variant="h1" color="success.main">
          <FormattedCurrency
            value={goldenCupCampaign.amount}
            currency={goldenCupCampaign.currencyCode}
            minimumFractionDigits={0}
          />
        </Typography>
        <Button variant="contained" color="primary" onClick={handleFindPartner}>
          <FormattedMessage id="golden_cup_info_modal_find_partner_button" />
        </Button>
        <Typography
          variant="body2"
          sx={{ fontWeight: 600, color: '#A7A7A7' }}
          className="mb-16"
          onClick={() => optOutModalRef.current?.open()}
        >
          <FormattedMessage id="golden_cup_info_modal_optout_link" />
        </Typography>
      </DialogContent>
      <GoldenCupOptOutModal ref={optOutModalRef} onCloseParent={onClose} />
    </>
  )
}

export const GoldenCupModal = buildModal(GoldenCupModalContent, {
  maxWidth: 'sm',
  classes: { container: 'items-end', paper: 'w-full m-0 rounded-b-none' },
})
