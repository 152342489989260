import { setContext } from '@apollo/client/link/context'
import { getAccessToken } from '../tokens-store'

export const authHeaderLink = setContext(async (request, previousContext) => {
  if (previousContext.isPublic) return previousContext

  const accessToken = await getAccessToken()

  return {
    ...previousContext,
    headers: {
      ...previousContext.headers,
      Authorization: accessToken ?? '',
    },
  }
})
