import { FC } from 'react'
import { IconButton, Icon } from '@mui/material'

type NavbarToggleButtonProps = {
  className?: string
  onClick: () => void
}

export const NavbarToggleButton: FC<NavbarToggleButtonProps> = ({ className, onClick }) => {
  return (
    <IconButton
      data-testid="NavbarToggleButton"
      className={className}
      color="inherit"
      size="small"
      onClick={onClick}
    >
      <Icon fontSize="inherit" className="text-16">
        menu_open
      </Icon>
    </IconButton>
  )
}
