import { VFC } from 'react'
import { Typography } from '@web-panel/shared'
import illustrations from '@local/assets/illustrations/neom'
import { DownloadAppButtonSection } from '@local/components/DownloadAppButtonSection'

export const EmptyList: VFC = () => {
  return (
    <div className="pb-100 pt-24">
      <div className="flex flex-col items-center ">
        <img width="129" src={illustrations.emptyList} alt="empty list" />
        <Typography className="mt-8" sx={{ color: '#B6C6C9' }} variant="h3">
          No cups yet
        </Typography>
        <Typography className="mt-4" variant="body1">
          Get your first coffee in the kooky cup
        </Typography>
      </div>
      <div className="mt-32">
        <DownloadAppButtonSection />
      </div>
    </div>
  )
}
