import { FC } from 'react'
import { Typography, Button, Icon } from '@web-panel/shared'
import { LoyaltyCardContainer } from '@local/components/LoyaltyCard/Container'
import { FormattedMessage } from 'react-intl'
import { getAppLink } from '@local/helpers'

export const DownloadAppButtonSection: FC = () => {
  const onClickButton = async () => {
    const link = await getAppLink()
    window.open(link, '_blank')
  }

  return (
    <LoyaltyCardContainer>
      <Typography variant="body1">
        <FormattedMessage id="full_experience_label" />
      </Typography>
      <Button
        fullWidth
        className="mt-32"
        variant="outlined"
        size="large"
        color="primary"
        onClick={onClickButton}
      >
        <FormattedMessage id="full_experience_button" />
        <Icon className="ml-10">arrow_outward</Icon>
      </Button>
    </LoyaltyCardContainer>
  )
}
