import { VFC } from 'react'
import { Typography, Link } from '@web-panel/shared'
import PageHeader from '@local/components/PageHeader'
import { useLocation, Navigate } from 'react-router-dom'
import illustrations from '@local/assets/illustrations/neom'

const CheckInboxPage: VFC = () => {
  const { state } = useLocation()
  const email = state?.email

  if (!email) return <Navigate to="/sign-in" replace />

  return (
    <>
      <div className="flex-1">
        <PageHeader htmlTitle="Check your inbox" title="Check your inbox" />
        <Typography variant="body1" className="mt-7">
          We’ve sent you an email with the magic link to <b>{email}</b>.
        </Typography>
        <div className="flex flex-row justify-center">
          <img src={illustrations.checkInbox} alt="check inbox" className="mt-64" />
        </div>
      </div>
      <div className="flex flex-row justify-center">
        <Typography variant="body1">
          Link doesn’t work?{' '}
          <Link to="/sign-in/enter-code" state={{ email }}>
            <b>Enter the code</b>
          </Link>
        </Typography>
      </div>
    </>
  )
}

export default CheckInboxPage
