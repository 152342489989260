import { FC, useMemo } from 'react'
import { ConsumerItemHistory, ProductItemStatus } from '@web-panel/api'
import { Typography, isFeatureFlagDisabled } from '@web-panel/shared'
import { cupNegativeCo2Impact, cupPositiveCo2Impact } from '@local/constants'
import _compact from 'lodash/compact'

type CupCO2LabelProps = {
  data: ConsumerItemHistory
}

export const CupCO2Label: FC<CupCO2LabelProps> = ({ data }) => {
  const co2Label = isFeatureFlagDisabled('neom') ? 'Co2' : undefined

  const color = useMemo(() => {
    switch (data.itemStatus) {
      case ProductItemStatus.Returned:
        return 'success.main'
      case ProductItemStatus.Lost:
        return 'error.main'
      case ProductItemStatus.Transferred:
        return '#979797'
      default:
        return '#979797'
    }
  }, [data.itemStatus])

  const message = useMemo(() => {
    switch (data.itemStatus) {
      case ProductItemStatus.Returned:
        return _compact([`-${cupPositiveCo2Impact}g`, co2Label, '🎉']).join(' ')
      case ProductItemStatus.Lost:
        return _compact([`+${cupNegativeCo2Impact}g`, co2Label, '😡']).join(' ')
      case ProductItemStatus.Transferred:
        return _compact(['0g', co2Label]).join(' ')
      default:
        return null
    }
  }, [data.itemStatus])

  if (
    data.itemStatus !== ProductItemStatus.Returned &&
    data.itemStatus !== ProductItemStatus.Lost &&
    data.itemStatus !== ProductItemStatus.Transferred
  )
    return null

  return (
    <Typography className="mt-2" variant="caption" color={color}>
      {message}
    </Typography>
  )
}
