import { FC } from 'react'
import { Routes } from 'react-router-dom'
import { buildRoute } from './helpers'
import { getRegisteredRoutes } from '../../registrations/routes'

export const RouterProvider: FC = () => {
  const routes = getRegisteredRoutes()
  const routeElements = routes.map((route, id) => buildRoute(route))

  return <Routes>{routeElements}</Routes>
}
