import { InventoryTask, inventoryTaskFields } from './inventory-task'
import { EditableLocation, editableLocationFields } from './location'

export enum DispenserStatus {
  Available = 'AVAILABLE',
  NotAvailable = 'NOT_AVAILABLE',
  NoLastSignal = 'NO_LAST_SIGNAL',
  Broken = 'BROKEN',
  Warehouse = 'WAREHOUSE',
  WarehouseBroken = 'WAREHOUSE_BROKEN',
  UnderRepair = 'UNDER_REPAIR',
  Deleted = 'DELETED',
}

export enum DispenserOperationType {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
}

export enum DispenserLocationType {
  Outdoor = 'OUTDOOR',
  Indoor = 'INDOOR',
}

export enum DispenserStandType {
  Wheels = 'WHEELS',
  NoWheels = 'NO_WHEELS',
}

export type Dispenser = {
  id: string
  coreId: string
  imei: string | null
  dispenserAppId: string | null
  activationCode: string
  customLocation: EditableLocation | null
  outletId: string | null
  outletLocation: EditableLocation | null
  qrCode: string
  status: DispenserStatus
  operationType: DispenserOperationType | null
  locationType: DispenserLocationType | null
  standType: DispenserStandType | null
  capacity: number
  itemsProcessed: number
  battery: number | null
  lastSignalDate: string | null
  createDate: string
}

export const dispenserFields = `
  id
  coreId
  imei
  dispenserAppId
  activationCode
  customLocation {
    ${editableLocationFields}
  }
  outletId
  outletLocation {
    ${editableLocationFields}
  }
  qrCode
  status
  operationType
  locationType
  standType
  capacity
  itemsProcessed
  battery
  lastSignalDate
  createDate
`

export type DispenserTask = {
  dispenserId: string
} & InventoryTask

export const dispenserTaskFields = `
  dispenserId
  ${inventoryTaskFields}
`
