import { ConsumableProductItemType, ProductItemModel, productItemModelFields } from './product-item'
import { PaymentType } from './financial'
import { OptimoRouteStatus } from './optimo-route'

export enum OrderReturnType {
  Consumer = 'CONSUMER',
  Anonymous = 'ANONYMOUS',
}

export enum OrderStatus {
  Requested = 'REQUESTED',
  Processing = 'PROCESSING',
  Delivered = 'DELIVERED',
  Rejected = 'REJECTED',
}

export enum OrderInvoiceStatus {
  Open = 'OPEN',
  Unpaid = 'UNPAID',
  Paid = 'PAID',
}

export enum OrderInvoiceType {
  PartnerInvoice = 'PARTNER_INVOICE',
  OutletInvoice = 'OUTLET_INVOICE',
}

export type OrderRefundable = {
  id: string
  model: ProductItemModel
  itemCost: number
  itemServiceFee: number
  itemsAmount: number
}

export const orderRefundableFields = `
  id
  model {
    ${productItemModelFields}
  }
  itemCost
  itemServiceFee
  itemsAmount
`

export type OrderConsumable = {
  id: string
  consumableType: ConsumableProductItemType.Lid
  consumableCost: number
  consumablesAmount: number
}

export const orderConsumableFields = `
  id
  consumableType
  consumableCost
  consumablesAmount
`

export type Order = {
  id: string
  orderNumber: string
  partnerId: string
  firstName: string
  lastName: string
  phone: string
  totalCost: number
  status: OrderStatus
  invoiceStatus: OrderInvoiceStatus
  invoiceType: OrderInvoiceType
  paymentType: PaymentType
  receiptPdf: string | null
  invoicePdf: string | null
  deliveryPdf: string | null
  deliverySignature: string | null
  partnerBankAccountId: string | null
  partnerCreditCardId: string | null
  currencyCode: string
  outletId: string
  comment: string
  createDate: string
  orderRefundables: OrderRefundable[]
  orderConsumables: OrderConsumable[]
  expectedDeliveryDateFrom: string | null
  expectedDeliveryDateTo: string | null
  optimoRouteOrderNo: string | null
  optimoRouteUrl: string | null
  optimoRouteStatus: OptimoRouteStatus | null
  returnType: OrderReturnType
}

export const orderFields = `
  id
  orderNumber
  partnerId @export(as: "partnerId")
  firstName
  lastName
  phone
  totalCost
  status
  invoiceStatus
  invoiceType
  paymentType
  receiptPdf
  invoicePdf
  deliveryPdf
  deliverySignature
  partnerBankAccountId
  partnerCreditCardId
  currencyCode
  outletId @export(as: "outletId")
  comment
  createDate
  orderRefundables {
    ${orderRefundableFields}
  }
  orderConsumables {
    ${orderConsumableFields}
  }
  expectedDeliveryDateFrom
  expectedDeliveryDateTo
  optimoRouteOrderNo
  optimoRouteUrl
  optimoRouteStatus
  returnType
`
