export enum RewardTemplateProvider {
  WWF = 'WWF',
  Amazon = 'AMAZON',
}

export enum RewardTemplateType {
  Donation = 'DONATION',
  Voucher = 'VOUCHER',
  CashBack = 'CASH_BACK',
  Physical = 'PHYSICAL',
}

export enum RewardTemplateSubType {
  Coffee = 'COFFEE',
}

export type RewardTemplate = {
  id: string
  externalId: number
  type: RewardTemplateType
  logo: string
  referenceValue: number
  referenceCurrencyCode: string
  provider: RewardTemplateProvider
  unitCost: number
  unitCurrencyCode: string
  countryCode: string
  cityCode: string
}

export const rewardTemplateFields = `
  id
  externalId
  type
  logo
  referenceValue
  referenceCurrencyCode
  provider
  unitCost
  unitCurrencyCode
  countryCode
  cityCode
`

export enum UnavailableRewardReason {
  NotTheOnlyCitySelected = 'NOT_THE_ONLY_CITY_SELECTED',
}

export type UnavailableRewardTemplate = {
  unavailableReason: UnavailableRewardReason
} & RewardTemplate

export const unavailableRewardTemplateFields = `
  unavailableReason
  ${rewardTemplateFields}
`
export enum LoyaltyCampaignFunctionType {
  SingleUse = 'SINGLE_USE',
  Recurrent = 'RECURRENT',
}

export type LoyaltyCampaignCalculatedDetailsReward = {
  id: string
  rewardTemplateId: string
  type: RewardTemplateType
  subType: RewardTemplateSubType
  logo: string | null
  referenceValue: number
  referenceCurrencyCode: string
  provider: RewardTemplateProvider
  unitCost: number
  unitCurrencyCode: string
  functionType: LoyaltyCampaignFunctionType
  unlockRuleAmount: number
  description: string
  numberOfRewards: number
  validTillDate: string | null
  estimatedItemsToSell: number
}

export const loyaltyCampaignCalculatedDetailsRewardFields = `
  id
  rewardTemplateId
  type
  subType
  logo
  referenceValue
  referenceCurrencyCode
  provider
  unitCost
  unitCurrencyCode
  functionType
  unlockRuleAmount
  description
  numberOfRewards
  validTillDate
  estimatedItemsToSell
`

export type LoyaltyCampaignCalculatedDetails = {
  rewards: LoyaltyCampaignCalculatedDetailsReward[]
  outletIds: string[]
  totalCost: number
}

export const loyaltyCampaignCalculatedDetailsFields = `
  rewards {
    ${loyaltyCampaignCalculatedDetailsRewardFields}
  }
  outletIds
  totalCost
`

export enum LoyaltyCampaignStatus {
  Active = 'ACTIVE',
  Ended = 'ENDED',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Disabled = 'DISABLED',
}

export type LoyaltyCampaignReward = {
  id: string
  remainingNumberOfRewards: number
  rewardTemplateId: string
  type: RewardTemplateType
  subType: RewardTemplateSubType
  logo: string | null
  referenceValue: number
  referenceCurrencyCode: string
  provider: RewardTemplateProvider
  unitCost: number
  unitCurrencyCode: string
  functionType: LoyaltyCampaignFunctionType
  unlockRuleAmount: number
  description: string
  validTillDate: string | null
}

export const loyaltyCampaignRewardFields = `
  id
  remainingNumberOfRewards
  rewardTemplateId
  type
  subType
  logo
  referenceValue
  referenceCurrencyCode
  provider
  unitCost
  unitCurrencyCode
  functionType
  unlockRuleAmount
  description
  validTillDate
`

export type LoyaltyCampaignEarnedRewards = {
  outletId: string
  rewardsEarnedCount: number
}

export const loyaltyCampaignEarnedRewardsFields = `
  outletId
  rewardsEarnedCount
`

export type LoyaltyCampaign = {
  id: string
  externalId: number
  status: LoyaltyCampaignStatus
  name: string
  description: string
  startDate: string
  endDate: string | null
  committedCost: number
  currentCost: number
  updateDate: string
  createDate: string
  itemsSold: number
  rewardDetails: LoyaltyCampaignReward[]
  outletsEarnedRewards: LoyaltyCampaignEarnedRewards[]
}

export const loyaltyCampaignFields = `
  id
  externalId
  status
  name
  description
  startDate
  endDate
  committedCost
  currentCost
  updateDate
  createDate
  itemsSold
  rewardDetails {
    ${loyaltyCampaignRewardFields}
  }
  outletsEarnedRewards {
    ${loyaltyCampaignEarnedRewardsFields}
  }
`

export type CollectionLoyaltyCampaign = {
  id: string
  externalId: number
  status: LoyaltyCampaignStatus
  name: string
  description: string
  startDate: string
  endDate: string | null
  committedCost: number
  currentCost: number
  updateDate: string
  createDate: string
  rewardDetails: LoyaltyCampaignReward[]
}

export const collectionLoyaltyCampaignFields = `
  id @export(as: "loyaltyCampaignId")
  externalId
  status
  name
  description
  startDate
  endDate
  committedCost
  currentCost
  updateDate
  createDate
  rewardDetails {
    ${loyaltyCampaignRewardFields}
  }
`
