export type Country = {
  code: string
  name: string
  native: string
  phone: string
}

export const countries: readonly Country[] = [
  { code: 'AD', name: 'Andorra', native: 'Andorra', phone: '+376' },
  {
    code: 'AE',
    name: 'United Arab Emirates',
    native: 'دولة الإمارات العربية المتحدة',
    phone: '+971',
  },
  { code: 'AF', name: 'Afghanistan', native: 'افغانستان', phone: '+93' },
  { code: 'AG', name: 'Antigua and Barbuda', native: 'Antigua and Barbuda', phone: '+1268' },
  { code: 'AI', name: 'Anguilla', native: 'Anguilla', phone: '+1264' },
  { code: 'AL', name: 'Albania', native: 'Shqipëria', phone: '+355' },
  { code: 'AM', name: 'Armenia', native: 'Հայաստան', phone: '+374' },
  { code: 'AO', name: 'Angola', native: 'Angola', phone: '+244' },
  { code: 'AQ', name: 'Antarctica', native: 'Antarctica', phone: '+672' },
  { code: 'AR', name: 'Argentina', native: 'Argentina', phone: '+54' },
  { code: 'AS', name: 'American Samoa', native: 'American Samoa', phone: '+1684' },
  { code: 'AT', name: 'Austria', native: 'Österreich', phone: '+43' },
  { code: 'AU', name: 'Australia', native: 'Australia', phone: '+61' },
  { code: 'AW', name: 'Aruba', native: 'Aruba', phone: '+297' },
  { code: 'AX', name: 'Åland', native: 'Åland', phone: '+358' },
  { code: 'AZ', name: 'Azerbaijan', native: 'Azərbaycan', phone: '+994' },
  { code: 'BA', name: 'Bosnia and Herzegovina', native: 'Bosna i Hercegovina', phone: '+387' },
  { code: 'BB', name: 'Barbados', native: 'Barbados', phone: '+1246' },
  { code: 'BD', name: 'Bangladesh', native: 'Bangladesh', phone: '+880' },
  { code: 'BE', name: 'Belgium', native: 'België', phone: '+32' },
  { code: 'BF', name: 'Burkina Faso', native: 'Burkina Faso', phone: '+226' },
  { code: 'BG', name: 'Bulgaria', native: 'България', phone: '+359' },
  { code: 'BH', name: 'Bahrain', native: '‏البحرين', phone: '+973' },
  { code: 'BI', name: 'Burundi', native: 'Burundi', phone: '+257' },
  { code: 'BJ', name: 'Benin', native: 'Bénin', phone: '+229' },
  { code: 'BL', name: 'Saint Barthélemy', native: 'Saint-Barthélemy', phone: '+590' },
  { code: 'BM', name: 'Bermuda', native: 'Bermuda', phone: '+1441' },
  { code: 'BN', name: 'Brunei', native: 'Negara Brunei Darussalam', phone: '+673' },
  { code: 'BO', name: 'Bolivia', native: 'Bolivia', phone: '+591' },
  { code: 'BQ', name: 'Bonaire', native: 'Bonaire', phone: '+5997' },
  { code: 'BR', name: 'Brazil', native: 'Brasil', phone: '+55' },
  { code: 'BS', name: 'Bahamas', native: 'Bahamas', phone: '+1242' },
  { code: 'BT', name: 'Bhutan', native: 'ʼbrug-yul', phone: '+975' },
  { code: 'BV', name: 'Bouvet Island', native: 'Bouvetøya', phone: '+47' },
  { code: 'BW', name: 'Botswana', native: 'Botswana', phone: '+267' },
  { code: 'BY', name: 'Belarus', native: 'Белару́сь', phone: '+375' },
  { code: 'BZ', name: 'Belize', native: 'Belize', phone: '+501' },
  { code: 'CA', name: 'Canada', native: 'Canada', phone: '+1' },
  { code: 'CC', name: 'Cocos [Keeling] Islands', native: 'Cocos (Keeling) Islands', phone: '+61' },
  {
    code: 'CD',
    name: 'Democratic Republic of the Congo',
    native: 'République démocratique du Congo',
    phone: '+243',
  },
  { code: 'CF', name: 'Central African Republic', native: 'Ködörösêse tî Bêafrîka', phone: '+236' },
  { code: 'CG', name: 'Republic of the Congo', native: 'République du Congo', phone: '+242' },
  { code: 'CH', name: 'Switzerland', native: 'Schweiz', phone: '+41' },
  { code: 'CI', name: 'Ivory Coast', native: "Côte d'Ivoire", phone: '+225' },
  { code: 'CK', name: 'Cook Islands', native: 'Cook Islands', phone: '+682' },
  { code: 'CL', name: 'Chile', native: 'Chile', phone: '+56' },
  { code: 'CM', name: 'Cameroon', native: 'Cameroon', phone: '+237' },
  { code: 'CN', name: 'China', native: '中国', phone: '+86' },
  { code: 'CO', name: 'Colombia', native: 'Colombia', phone: '+57' },
  { code: 'CR', name: 'Costa Rica', native: 'Costa Rica', phone: '+506' },
  { code: 'CU', name: 'Cuba', native: 'Cuba', phone: '+53' },
  { code: 'CV', name: 'Cape Verde', native: 'Cabo Verde', phone: '+238' },
  { code: 'CW', name: 'Curacao', native: 'Curaçao', phone: '+5999' },
  { code: 'CX', name: 'Christmas Island', native: 'Christmas Island', phone: '+61' },
  { code: 'CY', name: 'Cyprus', native: 'Κύπρος', phone: '+357' },
  { code: 'CZ', name: 'Czech Republic', native: 'Česká republika', phone: '+420' },
  { code: 'DE', name: 'Germany', native: 'Deutschland', phone: '+49' },
  { code: 'DJ', name: 'Djibouti', native: 'Djibouti', phone: '+253' },
  { code: 'DK', name: 'Denmark', native: 'Danmark', phone: '+45' },
  { code: 'DM', name: 'Dominica', native: 'Dominica', phone: '+1767' },
  { code: 'DO', name: 'Dominican Republic', native: 'República Dominicana', phone: '+1809' },
  { code: 'DZ', name: 'Algeria', native: 'الجزائر', phone: '+213' },
  { code: 'EC', name: 'Ecuador', native: 'Ecuador', phone: '+593' },
  { code: 'EE', name: 'Estonia', native: 'Eesti', phone: '+372' },
  { code: 'EG', name: 'Egypt', native: 'مصر‎', phone: '+20' },
  { code: 'EH', name: 'Western Sahara', native: 'الصحراء الغربية', phone: '+212' },
  { code: 'ER', name: 'Eritrea', native: 'ኤርትራ', phone: '+291' },
  { code: 'ES', name: 'Spain', native: 'España', phone: '+34' },
  { code: 'ET', name: 'Ethiopia', native: 'ኢትዮጵያ', phone: '+251' },
  { code: 'FI', name: 'Finland', native: 'Suomi', phone: '+358' },
  { code: 'FJ', name: 'Fiji', native: 'Fiji', phone: '+679' },
  { code: 'FK', name: 'Falkland Islands', native: 'Falkland Islands', phone: '+500' },
  { code: 'FM', name: 'Micronesia', native: 'Micronesia', phone: '+691' },
  { code: 'FO', name: 'Faroe Islands', native: 'Føroyar', phone: '+298' },
  { code: 'FR', name: 'France', native: 'France', phone: '+33' },
  { code: 'GA', name: 'Gabon', native: 'Gabon', phone: '+241' },
  { code: 'GB', name: 'United Kingdom', native: 'United Kingdom', phone: '+44' },
  { code: 'GD', name: 'Grenada', native: 'Grenada', phone: '+1473' },
  { code: 'GE', name: 'Georgia', native: 'საქართველო', phone: '+995' },
  { code: 'GF', name: 'French Guiana', native: 'Guyane française', phone: '+594' },
  { code: 'GG', name: 'Guernsey', native: 'Guernsey', phone: '+44' },
  { code: 'GH', name: 'Ghana', native: 'Ghana', phone: '+233' },
  { code: 'GI', name: 'Gibraltar', native: 'Gibraltar', phone: '+350' },
  { code: 'GL', name: 'Greenland', native: 'Kalaallit Nunaat', phone: '+299' },
  { code: 'GM', name: 'Gambia', native: 'Gambia', phone: '+220' },
  { code: 'GN', name: 'Guinea', native: 'Guinée', phone: '+224' },
  { code: 'GP', name: 'Guadeloupe', native: 'Guadeloupe', phone: '+590' },
  { code: 'GQ', name: 'Equatorial Guinea', native: 'Guinea Ecuatorial', phone: '+240' },
  { code: 'GR', name: 'Greece', native: 'Ελλάδα', phone: '+30' },
  {
    code: 'GS',
    name: 'South Georgia and the South Sandwich Islands',
    native: 'South Georgia',
    phone: '+500',
  },
  { code: 'GT', name: 'Guatemala', native: 'Guatemala', phone: '+502' },
  { code: 'GU', name: 'Guam', native: 'Guam', phone: '+1671' },
  { code: 'GW', name: 'Guinea-Bissau', native: 'Guiné-Bissau', phone: '+245' },
  { code: 'GY', name: 'Guyana', native: 'Guyana', phone: '+592' },
  { code: 'HK', name: 'Hong Kong', native: '香港', phone: '+852' },
  {
    code: 'HM',
    name: 'Heard Island and McDonald Islands',
    native: 'Heard Island and McDonald Islands',
    phone: '+61',
  },
  { code: 'HN', name: 'Honduras', native: 'Honduras', phone: '+504' },
  { code: 'HR', name: 'Croatia', native: 'Hrvatska', phone: '+385' },
  { code: 'HT', name: 'Haiti', native: 'Haïti', phone: '+509' },
  { code: 'HU', name: 'Hungary', native: 'Magyarország', phone: '+36' },
  { code: 'ID', name: 'Indonesia', native: 'Indonesia', phone: '+62' },
  { code: 'IE', name: 'Ireland', native: 'Éire', phone: '+353' },
  { code: 'IL', name: 'Israel', native: 'יִשְׂרָאֵל', phone: '+972' },
  { code: 'IM', name: 'Isle of Man', native: 'Isle of Man', phone: '+44' },
  { code: 'IN', name: 'India', native: 'भारत', phone: '+91' },
  {
    code: 'IO',
    name: 'British Indian Ocean Territory',
    native: 'British Indian Ocean Territory',
    phone: '+246',
  },
  { code: 'IQ', name: 'Iraq', native: 'العراق', phone: '+964' },
  { code: 'IR', name: 'Iran', native: 'ایران', phone: '+98' },
  { code: 'IS', name: 'Iceland', native: 'Ísland', phone: '+354' },
  { code: 'IT', name: 'Italy', native: 'Italia', phone: '+39' },
  { code: 'JE', name: 'Jersey', native: 'Jersey', phone: '+44' },
  { code: 'JM', name: 'Jamaica', native: 'Jamaica', phone: '+1876' },
  { code: 'JO', name: 'Jordan', native: 'الأردن', phone: '+962' },
  { code: 'JP', name: 'Japan', native: '日本', phone: '+81' },
  { code: 'KE', name: 'Kenya', native: 'Kenya', phone: '+254' },
  { code: 'KG', name: 'Kyrgyzstan', native: 'Кыргызстан', phone: '+996' },
  { code: 'KH', name: 'Cambodia', native: 'Kâmpŭchéa', phone: '+855' },
  { code: 'KI', name: 'Kiribati', native: 'Kiribati', phone: '+686' },
  { code: 'KM', name: 'Comoros', native: 'Komori', phone: '+269' },
  { code: 'KN', name: 'Saint Kitts and Nevis', native: 'Saint Kitts and Nevis', phone: '+1869' },
  { code: 'KP', name: 'North Korea', native: '북한', phone: '+850' },
  { code: 'KR', name: 'South Korea', native: '대한민국', phone: '+82' },
  { code: 'KW', name: 'Kuwait', native: 'الكويت', phone: '+965' },
  { code: 'KY', name: 'Cayman Islands', native: 'Cayman Islands', phone: '+1345' },
  { code: 'KZ', name: 'Kazakhstan', native: 'Қазақстан', phone: '+76' },
  { code: 'LA', name: 'Laos', native: 'ສປປລາວ', phone: '+856' },
  { code: 'LB', name: 'Lebanon', native: 'لبنان', phone: '+961' },
  { code: 'LC', name: 'Saint Lucia', native: 'Saint Lucia', phone: '+1758' },
  { code: 'LI', name: 'Liechtenstein', native: 'Liechtenstein', phone: '+423' },
  { code: 'LK', name: 'Sri Lanka', native: 'śrī laṃkāva', phone: '+94' },
  { code: 'LR', name: 'Liberia', native: 'Liberia', phone: '+231' },
  { code: 'LS', name: 'Lesotho', native: 'Lesotho', phone: '+266' },
  { code: 'LT', name: 'Lithuania', native: 'Lietuva', phone: '+370' },
  { code: 'LU', name: 'Luxembourg', native: 'Luxembourg', phone: '+352' },
  { code: 'LV', name: 'Latvia', native: 'Latvija', phone: '+371' },
  { code: 'LY', name: 'Libya', native: '‏ليبيا', phone: '+218' },
  { code: 'MA', name: 'Morocco', native: 'المغرب', phone: '+212' },
  { code: 'MC', name: 'Monaco', native: 'Monaco', phone: '+377' },
  { code: 'MD', name: 'Moldova', native: 'Moldova', phone: '+373' },
  { code: 'ME', name: 'Montenegro', native: 'Црна Гора', phone: '+382' },
  { code: 'MF', name: 'Saint Martin', native: 'Saint-Martin', phone: '+590' },
  { code: 'MG', name: 'Madagascar', native: 'Madagasikara', phone: '+261' },
  { code: 'MH', name: 'Marshall Islands', native: 'M̧ajeļ', phone: '+692' },
  { code: 'MK', name: 'North Macedonia', native: 'Северна Македонија', phone: '+389' },
  { code: 'ML', name: 'Mali', native: 'Mali', phone: '+223' },
  { code: 'MM', name: 'Myanmar [Burma]', native: 'မြန်မာ', phone: '+95' },
  { code: 'MN', name: 'Mongolia', native: 'Монгол улс', phone: '+976' },
  { code: 'MO', name: 'Macao', native: '澳門', phone: '+853' },
  {
    code: 'MP',
    name: 'Northern Mariana Islands',
    native: 'Northern Mariana Islands',
    phone: '+1670',
  },
  { code: 'MQ', name: 'Martinique', native: 'Martinique', phone: '+596' },
  { code: 'MR', name: 'Mauritania', native: 'موريتانيا', phone: '+222' },
  { code: 'MS', name: 'Montserrat', native: 'Montserrat', phone: '+1664' },
  { code: 'MT', name: 'Malta', native: 'Malta', phone: '+356' },
  { code: 'MU', name: 'Mauritius', native: 'Maurice', phone: '+230' },
  { code: 'MV', name: 'Maldives', native: 'Maldives', phone: '+960' },
  { code: 'MW', name: 'Malawi', native: 'Malawi', phone: '+265' },
  { code: 'MX', name: 'Mexico', native: 'México', phone: '+52' },
  { code: 'MY', name: 'Malaysia', native: 'Malaysia', phone: '+60' },
  { code: 'MZ', name: 'Mozambique', native: 'Moçambique', phone: '+258' },
  { code: 'NA', name: 'Namibia', native: 'Namibia', phone: '+264' },
  { code: 'NC', name: 'New Caledonia', native: 'Nouvelle-Calédonie', phone: '+687' },
  { code: 'NE', name: 'Niger', native: 'Niger', phone: '+227' },
  { code: 'NF', name: 'Norfolk Island', native: 'Norfolk Island', phone: '+672' },
  { code: 'NG', name: 'Nigeria', native: 'Nigeria', phone: '+234' },
  { code: 'NI', name: 'Nicaragua', native: 'Nicaragua', phone: '+505' },
  { code: 'NL', name: 'Netherlands', native: 'Nederland', phone: '+31' },
  { code: 'NO', name: 'Norway', native: 'Norge', phone: '+47' },
  { code: 'NP', name: 'Nepal', native: 'नपल', phone: '+977' },
  { code: 'NR', name: 'Nauru', native: 'Nauru', phone: '+674' },
  { code: 'NU', name: 'Niue', native: 'Niuē', phone: '+683' },
  { code: 'NZ', name: 'New Zealand', native: 'New Zealand', phone: '+64' },
  { code: 'OM', name: 'Oman', native: 'عمان', phone: '+968' },
  { code: 'PA', name: 'Panama', native: 'Panamá', phone: '+507' },
  { code: 'PE', name: 'Peru', native: 'Perú', phone: '+51' },
  { code: 'PF', name: 'French Polynesia', native: 'Polynésie française', phone: '+689' },
  { code: 'PG', name: 'Papua New Guinea', native: 'Papua Niugini', phone: '+675' },
  { code: 'PH', name: 'Philippines', native: 'Pilipinas', phone: '+63' },
  { code: 'PK', name: 'Pakistan', native: 'Pakistan', phone: '+92' },
  { code: 'PL', name: 'Poland', native: 'Polska', phone: '+48' },
  {
    code: 'PM',
    name: 'Saint Pierre and Miquelon',
    native: 'Saint-Pierre-et-Miquelon',
    phone: '+508',
  },
  { code: 'PN', name: 'Pitcairn Islands', native: 'Pitcairn Islands', phone: '+64' },
  { code: 'PR', name: 'Puerto Rico', native: 'Puerto Rico', phone: '+1787' },
  { code: 'PS', name: 'Palestine', native: 'فلسطين', phone: '+970' },
  { code: 'PT', name: 'Portugal', native: 'Portugal', phone: '+351' },
  { code: 'PW', name: 'Palau', native: 'Palau', phone: '+680' },
  { code: 'PY', name: 'Paraguay', native: 'Paraguay', phone: '+595' },
  { code: 'QA', name: 'Qatar', native: 'قطر', phone: '+974' },
  { code: 'RE', name: 'Réunion', native: 'La Réunion', phone: '+262' },
  { code: 'RO', name: 'Romania', native: 'România', phone: '+40' },
  { code: 'RS', name: 'Serbia', native: 'Србија', phone: '+381' },
  { code: 'RU', name: 'Russia', native: 'Россия', phone: '+7' },
  { code: 'RW', name: 'Rwanda', native: 'Rwanda', phone: '+250' },
  { code: 'SA', name: 'Saudi Arabia', native: 'العربية السعودية', phone: '+966' },
  { code: 'SB', name: 'Solomon Islands', native: 'Solomon Islands', phone: '+677' },
  { code: 'SC', name: 'Seychelles', native: 'Seychelles', phone: '+248' },
  { code: 'SD', name: 'Sudan', native: 'السودان', phone: '+249' },
  { code: 'SE', name: 'Sweden', native: 'Sverige', phone: '+46' },
  { code: 'SG', name: 'Singapore', native: 'Singapore', phone: '+65' },
  { code: 'SH', name: 'Saint Helena', native: 'Saint Helena', phone: '+290' },
  { code: 'SI', name: 'Slovenia', native: 'Slovenija', phone: '+386' },
  { code: 'SJ', name: 'Svalbard and Jan Mayen', native: 'Svalbard og Jan Mayen', phone: '+4779' },
  { code: 'SK', name: 'Slovakia', native: 'Slovensko', phone: '+421' },
  { code: 'SL', name: 'Sierra Leone', native: 'Sierra Leone', phone: '+232' },
  { code: 'SM', name: 'San Marino', native: 'San Marino', phone: '+378' },
  { code: 'SN', name: 'Senegal', native: 'Sénégal', phone: '+221' },
  { code: 'SO', name: 'Somalia', native: 'Soomaaliya', phone: '+252' },
  { code: 'SR', name: 'Suriname', native: 'Suriname', phone: '+597' },
  { code: 'SS', name: 'South Sudan', native: 'South Sudan', phone: '+211' },
  { code: 'ST', name: 'São Tomé and Príncipe', native: 'São Tomé e Príncipe', phone: '+239' },
  { code: 'SV', name: 'El Salvador', native: 'El Salvador', phone: '+503' },
  { code: 'SX', name: 'Sint Maarten', native: 'Sint Maarten', phone: '+1721' },
  { code: 'SY', name: 'Syria', native: 'سوريا', phone: '+963' },
  { code: 'SZ', name: 'Swaziland', native: 'Swaziland', phone: '+268' },
  {
    code: 'TC',
    name: 'Turks and Caicos Islands',
    native: 'Turks and Caicos Islands',
    phone: '+1649',
  },
  { code: 'TD', name: 'Chad', native: 'Tchad', phone: '+235' },
  {
    code: 'TF',
    name: 'French Southern Territories',
    native: 'Territoire des Terres australes et antarctiques fr',
    phone: '+262',
  },
  { code: 'TG', name: 'Togo', native: 'Togo', phone: '+228' },
  { code: 'TH', name: 'Thailand', native: 'ประเทศไทย', phone: '+66' },
  { code: 'TJ', name: 'Tajikistan', native: 'Тоҷикистон', phone: '+992' },
  { code: 'TK', name: 'Tokelau', native: 'Tokelau', phone: '+690' },
  { code: 'TL', name: 'East Timor', native: 'Timor-Leste', phone: '+670' },
  { code: 'TM', name: 'Turkmenistan', native: 'Türkmenistan', phone: '+993' },
  { code: 'TN', name: 'Tunisia', native: 'تونس', phone: '+216' },
  { code: 'TO', name: 'Tonga', native: 'Tonga', phone: '+676' },
  { code: 'TR', name: 'Turkey', native: 'Türkiye', phone: '+90' },
  { code: 'TT', name: 'Trinidad and Tobago', native: 'Trinidad and Tobago', phone: '+1868' },
  { code: 'TV', name: 'Tuvalu', native: 'Tuvalu', phone: '+688' },
  { code: 'TW', name: 'Taiwan', native: '臺灣', phone: '+886' },
  { code: 'TZ', name: 'Tanzania', native: 'Tanzania', phone: '+255' },
  { code: 'UA', name: 'Ukraine', native: 'Україна', phone: '+380' },
  { code: 'UG', name: 'Uganda', native: 'Uganda', phone: '+256' },
  {
    code: 'UM',
    name: 'U.S. Minor Outlying Islands',
    native: 'United States Minor Outlying Islands',
    phone: '+1',
  },
  { code: 'US', name: 'United States', native: 'United States', phone: '+1' },
  { code: 'UY', name: 'Uruguay', native: 'Uruguay', phone: '+598' },
  { code: 'UZ', name: 'Uzbekistan', native: 'O‘zbekiston', phone: '+998' },
  { code: 'VA', name: 'Vatican City', native: 'Vaticano', phone: '+379' },
  {
    code: 'VC',
    name: 'Saint Vincent and the Grenadines',
    native: 'Saint Vincent and the Grenadines',
    phone: '+1784',
  },
  { code: 'VE', name: 'Venezuela', native: 'Venezuela', phone: '+58' },
  { code: 'VG', name: 'British Virgin Islands', native: 'British Virgin Islands', phone: '+1284' },
  {
    code: 'VI',
    name: 'U.S. Virgin Islands',
    native: 'United States Virgin Islands',
    phone: '+1340',
  },
  { code: 'VN', name: 'Vietnam', native: 'Việt Nam', phone: '+84' },
  { code: 'VU', name: 'Vanuatu', native: 'Vanuatu', phone: '+678' },
  { code: 'WF', name: 'Wallis and Futuna', native: 'Wallis et Futuna', phone: '+681' },
  { code: 'WS', name: 'Samoa', native: 'Samoa', phone: '+685' },
  { code: 'XK', name: 'Kosovo', native: 'Republika e Kosovës', phone: '+377' },
  { code: 'YE', name: 'Yemen', native: 'اليَمَن', phone: '+967' },
  { code: 'YT', name: 'Mayotte', native: 'Mayotte', phone: '+262' },
  { code: 'ZA', name: 'South Africa', native: 'South Africa', phone: '+27' },
  { code: 'ZM', name: 'Zambia', native: 'Zambia', phone: '+260' },
  { code: 'ZW', name: 'Zimbabwe', native: 'Zimbabwe', phone: '+263' },
]
