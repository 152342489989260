import { useMutation } from '@apollo/client'
import { MutationHook } from '@web-panel/api'
import {
  RequestSMSSecurityCodeDocument,
  RequestSMSSecurityCodeDocumentInput,
  RequestSMSSecurityCodeDocumentResponse,
  RequestSMSAuthTokensDocument,
  RequestSMSAuthTokensDocumentInput,
  RequestSMSAuthTokensDocumentResponse,
  RequestEmailSecurityCodeDocument,
  RequestEmailSecurityCodeDocumentInput,
  RequestEmailSecurityCodeDocumentResponse,
  RequestEmailAuthTokensDocument,
  RequestEmailAuthTokensDocumentInput,
  RequestEmailAuthTokensDocumentResponse,
} from '../docs'

export const useSMSRequestSecurityCode: MutationHook<
  RequestSMSSecurityCodeDocumentInput['input'],
  RequestSMSSecurityCodeDocumentResponse['requestSecurityCode']
> = () => {
  const [execute, { loading }] = useMutation<
    RequestSMSSecurityCodeDocumentResponse,
    RequestSMSSecurityCodeDocumentInput
  >(RequestSMSSecurityCodeDocument, {
    context: {
      isPublic: true,
    },
    fetchPolicy: 'network-only',
  })

  async function request(input: RequestSMSSecurityCodeDocumentInput['input']) {
    const { data } = await execute({ variables: { input } })

    if (!data) throw new Error('Request security token failed')

    return data.requestSecurityCode
  }

  return { loading, request }
}

export const useSMSRequestAuthTokens: MutationHook<
  RequestSMSAuthTokensDocumentInput['input'],
  RequestSMSAuthTokensDocumentResponse['requestAuthTokens']
> = () => {
  const [execute, { loading }] = useMutation<
    RequestSMSAuthTokensDocumentResponse,
    RequestSMSAuthTokensDocumentInput
  >(RequestSMSAuthTokensDocument, {
    context: {
      isPublic: true,
    },
    fetchPolicy: 'network-only',
  })

  async function request(input: RequestSMSAuthTokensDocumentInput['input']) {
    const { data } = await execute({ variables: { input } })

    if (!data) throw new Error('Request access tokens failed')

    return data.requestAuthTokens
  }

  return { loading, request }
}

export const useRequestEmailSecurityCode: MutationHook<
  RequestEmailSecurityCodeDocumentInput['input'],
  RequestEmailSecurityCodeDocumentResponse
> = () => {
  const [execute, { loading }] = useMutation<
    RequestEmailSecurityCodeDocumentResponse,
    RequestEmailSecurityCodeDocumentInput
  >(RequestEmailSecurityCodeDocument, { context: { isPublic: true }, fetchPolicy: 'network-only' })

  async function request(input: RequestEmailSecurityCodeDocumentInput['input']) {
    await execute({ variables: { input } })
  }

  return { loading, request }
}

export const useRequestEmailAuthTokens: MutationHook<
  RequestEmailAuthTokensDocumentInput['input'],
  RequestEmailAuthTokensDocumentResponse['requestEmailAuthTokens']
> = () => {
  const [execute, { loading }] = useMutation<
    RequestEmailAuthTokensDocumentResponse,
    RequestEmailAuthTokensDocumentInput
  >(RequestEmailAuthTokensDocument, { context: { isPublic: true }, fetchPolicy: 'network-only' })

  async function request(input: RequestEmailAuthTokensDocumentInput['input']) {
    const { data } = await execute({ variables: { input } })

    if (!data) throw new Error('Request email auth tokens failed')

    return data.requestEmailAuthTokens
  }

  return {
    loading,
    request,
  }
}
