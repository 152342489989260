import { FC } from 'react'
import { AppBar, Toolbar as MuiToolbar, Hidden } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useToolbar } from '../../contexts/Toolbar'
import { NavbarToggleButton } from '../Navbar/NavbarToggleButton'
import { useNavbar } from '../../contexts/Navbar'

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'static',
  backgroundColor: theme.palette.background.paper,
}))

export const Toolbar: FC = () => {
  const { rightTools, leftTools } = useToolbar()
  const { isOpen, isOpenMobile, openNavbar, openMobileNavbar } = useNavbar()

  return (
    <StyledAppBar id="toolbar" color="default" className="flex z-20 shadow-md top-0">
      <MuiToolbar className="p-0 min-h-48 md:min-h-64">
        <div className="flex flex-1 items-center px-16">
          <Hidden lgDown>
            {!isOpen && <NavbarToggleButton className="w-40 h-40 p-0 mx-0" onClick={openNavbar} />}
          </Hidden>
          <Hidden lgUp>
            {!isOpenMobile && (
              <NavbarToggleButton className="w-40 h-40 p-0 mx-0" onClick={openMobileNavbar} />
            )}
          </Hidden>
          {leftTools.map((Component, id) => (
            <Component key={id} />
          ))}
        </div>
        <div className="flex items-center px-8 h-full overflow-x-auto">
          {rightTools.map((Component, id) => (
            <Component key={id} />
          ))}
        </div>
      </MuiToolbar>
    </StyledAppBar>
  )
}
