import { FC } from 'react'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { GlobalStyles } from '@mui/material'
import CssBaseLine from '@mui/material/CssBaseline'
import { getTheme } from './theme'

import './extend'

const globalStyles = (
  <GlobalStyles
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    styles={(theme: any) => ({
      'a:not([role=button]):not(.MuiButtonBase-root)': {
        color: theme.palette[theme.components.MuiLink.defaultProps.color].main,
        textDecoration: 'underline',
        '&:hover': {},
      },
    })}
  />
)

export const ThemeContextProvider: FC = ({ children }) => {
  const theme = getTheme()

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <CssBaseLine />
        {globalStyles}
        {children}
      </StyledEngineProvider>
    </ThemeProvider>
  )
}
