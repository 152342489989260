import { FC, useMemo, useState } from 'react'
import { Typography, TextField, InputAdornment, Button, formatCurrency } from '@web-panel/shared'
import { DonationOrganization, DonationPaymentType } from '@web-panel/api'
import { FormattedMessage, useIntl } from 'react-intl'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import successAnimation from '@local/assets/animations/success.json'
import errorAnimation from '@local/assets/animations/error.json'
import { getAppLink } from '@local/helpers'
import { useSessionstorage } from 'rooks'
import { useAccount, useConsumerWallets, useCreateDonation } from '@web-panel/consumer-api'
import { ResultMessage } from './Components/ResultMessage'

type FormFields = {
  amount: number
}

const initialValues: FormFields = {
  amount: 0,
}

export const DonationAmountEnterPage: FC = () => {
  const [resultState, setResultState] = useState<'success' | 'error' | null>(null)
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const [walletId] = useSessionstorage('walletId', '')

  const { request: createDonation, loading: loadingCreateDonation } = useCreateDonation()
  const { data: account } = useAccount()
  const { data: wallets = [] } = useConsumerWallets()
  const wallet = useMemo(() => {
    if (!walletId) return wallets[0]

    const targetWallet = wallets.find((w) => w.id === walletId)
    return targetWallet ?? wallets[0]
  }, [wallets, walletId])

  if (!account || !wallet) return null

  const formattedAmount = formatCurrency(wallet.amount, wallet.currency.code)
  const exceedsMaximumError = `${formatMessage({ id: 'your_wallet_holds' })} ${formattedAmount}`

  const validationSchema = Yup.object().shape({
    amount: Yup.number().required('').min(0.01, '').max(wallet.amount, exceedsMaximumError),
  })

  if (resultState === 'success')
    return (
      <ResultMessage
        animationData={successAnimation}
        title={formatMessage({ id: 'thank_you' })}
        message={formatMessage({ id: 'request_sent_succesfully_message' })}
        primaryButtonTitle={formatMessage({ id: 'request_tax_exemption' })}
        primaryButtonOnClick={() => navigate('/donation/tax-exemption', { replace: true })}
        secondaryButtonTitle={formatMessage({ id: 'request_later' })}
        secondaryButtonOnClick={async () => {
          const link = await getAppLink('/wallet', false)
          window.open(link)
        }}
      />
    )
  if (resultState === 'error')
    return (
      <ResultMessage
        animationData={errorAnimation}
        title={formatMessage({ id: 'request_payout_error_title' })}
        message={formatMessage({ id: 'request_payout_error_body' })}
      />
    )

  const onSubmit = async (values: FormFields) => {
    try {
      await createDonation({
        input: {
          donationOrganization: DonationOrganization.WWF,
          paymentMethodType: DonationPaymentType.WWFAccount,
          amount: values.amount,
          walletId: wallet.id,
        },
      })
      setResultState('success')
    } catch (err) {
      setResultState('error')
      window.postMessage('failed')
    }
  }

  return (
    <Formik<FormFields>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, handleChange, isValid, dirty, handleSubmit }) => (
        <div>
          <Typography variant="h4" color="primary">
            <FormattedMessage id="wwf_bottom_sheet_title" />
          </Typography>
          <div className="mt-32">
            <TextField
              type="number"
              fullWidth
              label="Amount"
              value={values.amount}
              onChange={handleChange('amount')}
              error={!!errors.amount}
              helperText={errors.amount}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{wallet.currency.name}</InputAdornment>
                ),
              }}
            />
          </div>
          <Typography className="mt-12">
            <FormattedMessage id="wwf_bottom_sheet_message" />
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={!dirty || !isValid}
            loading={loadingCreateDonation}
            onClick={() => handleSubmit()}
          >
            <FormattedMessage id="bank_transfer_confirm" />
          </Button>
          <Typography
            className="my-8"
            variant="body2"
            color="primary"
            sx={{ fontWeight: 600 }}
            align="center"
            onClick={() => navigate('/donation/contact-information')}
          >
            <FormattedMessage id="manage_contact_information" />
          </Typography>
        </div>
      )}
    </Formik>
  )
}
