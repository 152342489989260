import { FC, useState, createContext, useContext, useEffect } from 'react'
import { ApolloProvider } from '@apollo/client'
import {
  storeTokens,
  getAccessTokenExists,
  cleanTokens,
  TokenPair,
  getLastSignIn,
  storeLastSignIn,
} from '../tokens-store'
import { getClient, setApiRequestCallback } from '../client'

type AuthContext = {
  authenticated: boolean
  lastSignIn: Date | null
  signin: (tokens: TokenPair) => void
  signout: () => void
}

const Context = createContext<AuthContext>({
  authenticated: false,
  lastSignIn: null,
  signin: () => {},
  signout: () => {},
})

export const AuthContextProvider: FC = ({ children }) => {
  const client = getClient()
  const [isReady, setIsReady] = useState(false)
  const [authenticated, setAuthenticated] = useState(false)
  const [lastSignIn, setLastSignIn] = useState<Date | null>(getLastSignIn())

  const signin = (tokens: TokenPair) => {
    storeTokens(tokens)
    setLastSignIn(storeLastSignIn())
    setAuthenticated(true)
  }

  const signout = () => {
    cleanTokens()
    setAuthenticated(false)
  }

  const checkInitial = async () => {
    const accessTokenExists = await getAccessTokenExists()
    if (accessTokenExists) setAuthenticated(true)
    setIsReady(true)
  }

  useEffect(() => {
    setApiRequestCallback('onUnauthorisedAccess', () => setAuthenticated(false))
    checkInitial()
  }, [])

  if (!isReady) return null

  return (
    <ApolloProvider client={client}>
      <Context.Provider
        value={{
          authenticated,
          lastSignIn,
          signin,
          signout,
        }}
      >
        {children}
      </Context.Provider>
    </ApolloProvider>
  )
}

export const AuthContextConsumer = Context.Consumer

export function useAuthContext() {
  return useContext(Context)
}
