import { gql } from '@apollo/client'
import { GeoCity, geoCityFields, GeoCountry, geoCountryFields } from '../data-types'

export type CitiesDocumentInput = undefined
export type CitiesDocumentResponse = {
  cities: GeoCity[]
}

export const CitiesDocument = gql`
  query Citites {
    cities @rest(type: "GeoCity", path: "/location-service/public/cities") {
      ${geoCityFields}
    }
  }
`

export type CityDocumentInput = {
  code: string
}
export type CityDocumentResponse = {
  city: GeoCity
}
export const CityDocument = gql`
  query City($code: String!) {
    city(code: $code) @rest(type: "GeoCity", path: "/location-service/public/cities/{args.code}") {
      ${geoCityFields}
    }
  }
`

export type CountriesDocumentInput = undefined
export type CountriesDocumentResponse = {
  countries: GeoCountry[]
}

export const CountriesDocument = gql`
  query Countries {
    countries @rest(type: "GeoCountry", path: "/location-service/public/countries") {
      ${geoCountryFields}
    }
  }
`

export type CountryDocumentInput = {
  code: string
}
export type CountryDocumentResponse = {
  country: GeoCountry
}

export const CountryDocument = gql`
  query Country($code: String!) {
    country(code: $code) @rest(type: "GeoCountry", path: "/location-service/public/countries/{args.code}") {
      ${geoCountryFields}
    }
  }
`
