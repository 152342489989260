import { useState, useEffect } from 'react'

const token = '2d06ee8bf9f734'

type IpLocation = {
  ip: string
  hostname: string
  city: string
  region: string
  country: string
  loc: string
  org: string
  postal: string
  timezone: string
}

let location: IpLocation | undefined

export const useMyIpLocation = () => {
  const [data, setData] = useState<IpLocation | undefined>(location)
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    if (location) return location
    const response = await fetch(`https://ipinfo.io/json?token=${token}`)
    location = await response.json()
    return location
  }

  useEffect(() => {
    let isSubscribed = true
    setLoading(true)

    fetchData().then((response) => {
      if (isSubscribed) {
        setData(response)
        setLoading(false)
      }
    })

    return () => {
      isSubscribed = false
    }
  }, [])

  return {
    data,
    loading,
  }
}
