import { gql } from '@apollo/client'

export type ChatCredentialsDocumentInput = undefined

export type ChatCredentialsDocumentResponse = {
  chatCredentials: {
    token: string
    email: string
  }
}

export const ChatCredentialsDocument = gql`
  query ChatCredentials {
    chatCredentials @rest(type: "ChatCredentials", path: "/integrator/hubspot/token") {
      token
      email
    }
  }
`
