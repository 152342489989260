import { FC, ReactNode } from 'react'
import { Typography, Icon, PageTitle } from '@web-panel/shared'
import { appLogo, appLogoSize } from '@local/app-logo'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

type PageHeaderProps = {
  hideLogo?: boolean
  className?: string
  htmlTitle: string
  title: ReactNode
  titleAlign?: 'left' | 'center' | 'right'
  status?: ReactNode
  buttons?: ReactNode[]
  closable?: boolean
}

const PageHeader: FC<PageHeaderProps> = ({
  hideLogo = false,
  className,
  title,
  htmlTitle,
  titleAlign,
  status,
  buttons = [],
  closable = false,
}) => {
  const navigate = useNavigate()

  const handleOnClose = () => {
    navigate('/')
  }

  return (
    <>
      <PageTitle title={htmlTitle} />
      <div className={className ?? 'mt-16'}>
        <div className="flex flex-row justify-between items-center min-h-32">
          {hideLogo ? (
            <div className="mt-32" />
          ) : (
            <>
              {closable ? (
                <Icon onClick={handleOnClose}>close</Icon>
              ) : (
                <img className={`h-${appLogoSize}`} src={appLogo} alt="logo" />
              )}
            </>
          )}
          {status}
        </div>
        <div className="flex flex-row items-center mt-20 min-h-56">
          <div className="flex-1">
            <Typography variant="h2" color="primary" align={titleAlign}>
              {title}
            </Typography>
          </div>
          {buttons.length > 0 && (
            <div className="flex flex-row items-center justify-end">
              {buttons.map((button, id) => (
                <div key={id} className={classNames({ 'ml-12': id > 0 })}>
                  {button}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default PageHeader
