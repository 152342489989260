export type Currency = {
  code: string
  name: string
  symbol: string
}

export const currencyFields = `
  code
  name
  symbol
`
