import { lazy, Suspense } from 'react'
import { RouteConfig, isFeatureFlagEnabled } from '@web-panel/shared'
import { Navigate } from 'react-router-dom'
import { Loader } from '@local/components/Loader'
import _compact from 'lodash/compact'
import DefaultLayout from './layouts/DefaultLayout'
import AuthLayout from './layouts/AuthLayout'
import FullScreenLayout from './layouts/FullScreenLayout'
import { DonationsLayout } from './layouts/DonationsLayout'
import SMSSignInPage from './pages/SMSAuth/SignInPage'
import SMSConfirmPhonePage from './pages/SMSAuth/ConfirmPhonePage'
import EmailSignInPage from './pages/EmailAuth/SignInPage'
import EmailCheckInboxPage from './pages/EmailAuth/CheckInboxPage'
import EmailEnterCodePage from './pages/EmailAuth/EnterCodePage'
import EmailAuthenticatePage from './pages/EmailAuth/AuthenticatePage'
import DashboardPage from './pages/Dashboard'
import ScanPage from './pages/Scan/ScanPage'
import CupVerificationPage from './pages/Scan/CupVerificationPage'
import ScanResultPage from './pages/Scan/ScanResultPage'
import { DonationIndexPage } from './pages/Donation/IndexPage'
import { DonationsContactInformationPage } from './pages/Donation/ContactInformationPage'
import { DonationAmountEnterPage } from './pages/Donation/AmountEnterPage'
import { TaxExemptionPage } from './pages/Donation/TaxExemptionPage'
import DispenserVerificationPage from './pages/Dispenser/DispenserVerificationPage'
import DispenserNextStepPage from './pages/Dispenser/DispenserNextStepPage'
import DispenserErrorPage from './pages/Dispenser/DispenserErrorPage'

const MobileChatPage = lazy(() => import('./pages/MobileChat'))

const routes: RouteConfig = _compact([
  {
    key: 'dashboard',
    path: '/',
    accessStrategy: 'authenticated',
    Component: DashboardPage,
    Layout: DefaultLayout,
  },
  {
    key: 'scan',
    path: '/scan',
    Component: ScanPage,
    Layout: FullScreenLayout,
    accessStrategy: 'authenticated',
    routes: [
      // TODO: remove this route
      {
        key: 'wild-scan',
        path: '/wild/:qrCode',
        Component: CupVerificationPage,
        accessStrategy: 'authenticated-and-public',
      },
      {
        key: 'cup-scan',
        path: '/cup/:qrCode',
        Component: CupVerificationPage,
        accessStrategy: 'authenticated-and-public',
      },
      {
        key: 'scan-result',
        path: '/result',
        Component: ScanResultPage,
        Layout: FullScreenLayout,
      },
    ],
  },
  isFeatureFlagEnabled('neom')
    ? {
        key: 'sign-in',
        path: '/sign-in',
        accessStrategy: 'public-only',
        Component: EmailSignInPage,
        Layout: AuthLayout,
        routes: [
          {
            key: 'check-inbox',
            path: '/check-inbox',
            accessStrategy: 'public-only',
            Component: EmailCheckInboxPage,
          },
          {
            key: 'enter-code',
            path: '/enter-code',
            accessStrategy: 'public-only',
            Component: EmailEnterCodePage,
          },
        ],
      }
    : {
        key: 'sign-in',
        path: '/sign-in',
        accessStrategy: 'public-only',
        Component: SMSSignInPage,
        Layout: AuthLayout,
        routes: [
          {
            key: 'confirm-phone',
            path: '/confirm-phone',
            accessStrategy: 'public-only',
            Component: SMSConfirmPhonePage,
          },
        ],
      },
  isFeatureFlagEnabled('neom')
    ? {
        key: 'login',
        path: '/login/:token',
        accessStrategy: 'authenticated-and-public',
        Component: EmailAuthenticatePage,
        Layout: AuthLayout,
      }
    : null,
  {
    key: 'donation',
    path: '/donation',
    Component: DonationIndexPage,
    Layout: DonationsLayout,
    routes: [
      {
        key: 'donations-contact-information',
        path: '/contact-information',
        Component: DonationsContactInformationPage,
      },
      {
        key: 'donations-amount-enter',
        path: '/amount',
        Component: DonationAmountEnterPage,
      },
      {
        key: 'donations-tax-exemption',
        path: '/tax-exemption',
        Component: TaxExemptionPage,
      },
    ],
  },
  {
    key: 'chat',
    path: '/chat',
    Component: () => (
      <Suspense fallback={<Loader />}>
        <MobileChatPage />
      </Suspense>
    ),
    Layout: FullScreenLayout,
  },
  {
    key: 'dispenser-root',
    path: '/dispensers',
    Component: () => <></>,
    accessStrategy: 'authenticated',
    Layout: FullScreenLayout,
    routes: [
      {
        key: 'dispenser-verification',
        path: '/:qrCode',
        Component: DispenserVerificationPage,
      },
      {
        key: 'dispensers-next-step',
        path: '/next-step',
        Component: DispenserNextStepPage,
      },
      {
        key: 'dispensers-error',
        path: '/error',
        Component: DispenserErrorPage,
      },
    ],
  },
  {
    key: '404',
    path: '*',
    Component: () => <Navigate to="/" />,
    accessStrategy: 'authenticated',
  },
])

export default routes
