import { BankAccount, CreditCard, bankAccountFields, creditCardFields } from './financial'
import { Country, countryFields } from './geo'
import { ConsumerWallet, consumerWalletFields } from './consumer-wallet'

export type WWFAccount = {
  wwfAccountId: string
  country: Country
  city: string
  postalCode: string
  address: string
  firstName: string
  lastName: string
  birthdate: string | null
  dataProcessConfirmed: boolean
  newslettersOptIn: boolean
  taxExemption: boolean
}

export const wwfAccountFields = `
  wwfAccountId
  country {
    ${countryFields}
  }
  city
  postalCode
  address
  firstName
  lastName
  birthdate
  dataProcessConfirmed
  newslettersOptIn
  taxExemption
`

export type ConsumerPaymentMethods = {
  bankAccount: BankAccount | null
  creditCard: CreditCard | null
  wwfAccount: WWFAccount | null
}

export const consumerPaymentMethodsFields = `
  bankAccount @type(name: "ConsumerBankAccount") {
    ${bankAccountFields}
  }
  creditCard @type(name: "ConsumerCreditCard") {
    ${creditCardFields}
  }
  wwfAccount @type(name: "ConsumerWWFAccount") {
    ${wwfAccountFields}
  }
`

export type ConsumerInfo = {
  userId: string
  paymentMethods: ConsumerPaymentMethods
  balance: ConsumerWallet[]
}

export const consumerInfoFields = `
  userId
  paymentMethods {
    ${consumerPaymentMethodsFields}
  }
  balance {
    ${consumerWalletFields}
  }
`

export type ConsumerAccount = ConsumerInfo & {
  id: number
  accountId: number
  firstName: string | null
  lastName: string | null
  status: number
  statusName: string
  email: string | null
  phone: string
  street: string | null
  houseNumber: string | null
  postalCode: string | null
  city: string | null
  country: string | null
  totalCups: number
  activeCups: number
  returnedCups: number
  lostCups: number
  paymentOutCount: number
}

export const consumerAccountFields = `
  ${consumerInfoFields}
  id
  accountId
  firstName
  lastName
  status
  statusName
  email
  phone
  street
  houseNumber
  postalCode
  city
  country
  totalCups
  activeCups
  returnedCups
  lostCups
  paymentOutCount
`
