export enum BatchStatus {
  Ready = 'READY',
  Delivered = 'DELIVERED',
}

export type Batch = {
  id: string
  batchNumber: string
  status: BatchStatus
  deliveryBox: string | null
  deliveredBy: string | null
  deliveredOutletId: string | null
  deliveryDate: string | null
  orderId: string | null
  createDate: string
  itemsNumber: number
  createdBy: string | null
  containerId: string | null
  containerQrCode: string | null
}

export const batchFields = `
  id
  batchNumber
  status
  deliveryBox
  deliveredBy
  deliveredOutletId
  deliveryDate
  orderId
  createDate
  itemsNumber
  createdBy
  containerId
  containerQrCode
`
