import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { Campaign } from '@web-panel/api'
import { PromoCampaignWinScreen } from '@local/components/PromoCampaign/WinScreen'
import { UseTakeItemResultCode } from '@web-panel/consumer-api'
import { SuccessfulScanResult } from './Components/Successful'
import { NotFoundScanResult } from './Components/NotFound'
import { AlreadyTakenScanResult } from './Components/AlreadyTaken'
import { TakenByAnotherScanResult } from './Components/TakenByAnother'
import { AlreadyTakenFinished } from './Components/AlreadyTakenFinished'
import { CanNotBeTaken } from './Components/CanNotBeTaken'

type State = {
  code: UseTakeItemResultCode
  campaign?: Campaign
  transferred: boolean
  outletId?: string
}

const ScanResultPage: FC = () => {
  const { state } = useLocation()
  const { code, campaign, transferred, outletId } = (state as State | null) ?? {}

  switch (code) {
    case UseTakeItemResultCode.Successful:
      return campaign ? (
        <PromoCampaignWinScreen campaign={campaign} />
      ) : (
        <SuccessfulScanResult cupIsTransferred={transferred ?? false} outletId={outletId} />
      )
    case UseTakeItemResultCode.NotFound:
      return <NotFoundScanResult />
    case UseTakeItemResultCode.AlreadyTaken:
      return <AlreadyTakenScanResult />
    case UseTakeItemResultCode.TakenByAnotherUser:
      return <TakenByAnotherScanResult />
    case UseTakeItemResultCode.AlreadyTakenLost:
      return <AlreadyTakenFinished />
    default:
      return <CanNotBeTaken />
  }
}

export default ScanResultPage
