import { Currency, currencyFields } from './currency'
import { ActivityTriggerType, ActivityType } from './activity'
import { Batch as BatchType, batchFields } from './batch'
import { Campaign } from './campaign'

export enum ProductItemType {
  Cup = 'CUP',
}

export enum ProductHolderType {
  OperationsCenter = 'OPERATIONS_CENTER',
  PartnerOutlet = 'PARTNER_OUTLET',
  Consumer = 'CONSUMER',
  Box = 'BOX',
  Unknown = 'UNKNOWN',
}

export enum ProductItemSize {
  Big = 'BIG',
  Medium = 'MEDIUM',
  Small = 'SMALL',
}

export enum ProductItemStatus {
  Stock = 'STOCK',
  Restock = 'RESTOCK',
  PartnerStock = 'PARTNER_STOCK',
  PartnerOut = 'PARTNER_OUT',
  InUse = 'IN_USE',
  Lost = 'LOST',
  Returned = 'RETURNED',
  Operations = 'OPERATIONS',
  BoxCheckedIn = 'BOX_CHECKED_IN',
  Reusing = 'REUSING',
  Transferred = 'TRANSFERRED',
  Unknown = 'UNKNOWN',
}

export enum ProductItemModelForm {
  Default = 'DEFAULT',
}

export type ProductItemModel = {
  id: string
  type: ProductItemType
  form: ProductItemModelForm
  capacityValue: number
  capacityUnit: string
  model: string | null
  design: string | null
  description: string
}

export const productItemModelFields = `
  id
  type
  form
  capacityValue
  capacityUnit
  model
  design
  description
`

export type ProductItem = {
  id: string
  qrCode: string
  rfid: string
  chip: string | null
  status: ProductItemStatus
  brand: string
  size: ProductItemSize
  currentHolderId: string | null
  currentHolderType: ProductHolderType
  type: ProductItemType
  batch: BatchType | null
  batches: number
  model: ProductItemModel
}

export const productItemFields = `
  id
  qrCode
  rfid
  chip
  status
  brand
  size
  currentHolderId
  currentHolderType
  type
  batch @type(name: "Batch") {
    ${batchFields}
  }
  batches
  model {
    ${productItemModelFields}
  }
`

export type ConsumerProductItemRefund = {
  currency: Currency
  amount: number
  updateDate: string
}

export const consumerProductItemRefundFields = `
  currency {
    ${currencyFields}
  }
  amount
  updateDate
`

export type ConsumerProductItem = {
  id: string
  qr: string
  rfid: string
  status: ProductItemStatus
  brand: string
  size: ProductItemSize
  type: ProductItemType
  takenFromOutletId: string
  takenFromOutletDate: string
  returnDate: string | null
  refundId: string | null
  refund: ConsumerProductItemRefund | null
}

export const consumerProductItemFields = `
  id
  qr
  rfid
  status
  brand
  size
  type
  takenFromOutletId
  takenFromOutletDate
  returnDate
  refundId
  refund {
    ${consumerProductItemRefundFields}
  }
`

export enum ProductItemActivityHolderType {
  Item = 'ITEM',
  Batch = 'BATCH',
  Box = 'BOX',
  OperationsCenter = 'OPERATIONS_CENTER',
  PartnerOutlet = 'PARTNER_OUTLET',
  Consumer = 'CONSUMER',
}

export type ProductItemActivity = {
  itemId: string
  type: ActivityType
  triggerBy: string | null
  itemStatus: ProductItemStatus | null
  referenceHolderId: string | null
  referenceHolderType: ProductItemActivityHolderType | null
  triggerType: ActivityTriggerType | null
  createDate: string
  error: string | null
}

export const productItemActivityFields = `
  itemId
  type
  triggerBy
  itemStatus
  referenceHolderId
  referenceHolderType
  triggerType
  error
  createDate
`

export type ProductItemStatusAggregation = {
  status: ProductItemStatus
  count: number
}

export const productItemStatusAggregationFields = `
  status
  count
`

export type ConsumerItemHistoryCount = {
  inUse: number
  returned: number
  losted: number
}

export const consumerItemHistoryCountFields = `
  inUse
  returned
  losted
`

export type ConsumerItemRefund = {
  currency: Currency
  amount: number
  updateDate: string
}

export type ConsumerItemHistory = {
  id: string
  itemId: string
  date: string
  qr: string
  status: number
  statusName: string
  itemStatus: ProductItemStatus
  brand: string
  type: number
  typeName: string
  itemType: ProductItemSize
  takenFrom: string
  takenWhen: string
  refund: ConsumerItemRefund | null
  campaignInfo: Campaign | null
}

export const consumerItemHistoryFields = `
  id
  itemId
  date
  qr
  status
  statusName
  itemStatus
  brand
  type
  typeName
  itemType
  takenFrom
  takenWhen
  refund {
    currency {
      ${currencyFields}
    }
    amount
    updateDate
  }
  campaignInfo
`

export type ProductItemModelType = {
  type: ProductItemType
  models: ProductItemModel[]
}

export const productItemModelTypeFields = `
  type
  models {
    ${productItemModelFields}
  }
`

export type ProductItemModelBrand = {
  brands: string[]
}

export const productItemModelBrandFields = `
  brands
`

export enum ConsumableProductItemType {
  Lid = 'LID',
  Flag = 'FLAG',
}

export type ConsumableProductItem = {
  id: string
  consumableType: ConsumableProductItemType
  currency: Currency
  cost: number
}

export const consumableProductItemFields = `
  id
  consumableType
  currency {
    ${currencyFields}
  }
  cost
`

export type ConsumablePartnerProductItem = ConsumableProductItem & {
  partnerId: string | null
  enabled: boolean | null
}

export const consumablePartnerProductItemFields = `
  ${consumableProductItemFields}
  partnerId
  enabled
`

export enum ProductItemModelConfigurationStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type ProductItemModelConfiguration = {
  itemModel: ProductItemModel
  status: ProductItemModelConfigurationStatus
  currency: Currency
  cost: number
  serviceFee: number
  refundFeePercent: number
  payoutFeePercent: number
}

export const productItemModelConfigurationFields = `
  itemModel {
    ${productItemModelFields}
  }
  status
  currency {
    ${currencyFields}
  }
  cost
  serviceFee
  refundFeePercent
  payoutFeePercent
`
