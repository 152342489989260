export enum InventoryTaskProvider {
  OptimoRoute = 'OPTIMO_ROUTE',
}

export enum InventoryTaskStatus {
  Created = 'CREATED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED',
  Rejected = 'REJECTED',
  Failed = 'FAILED',
  Unkonwn = 'UNKNOWN',
}

export enum InventoryTaskType {
  CollectTask = 'COLLECT_TASK',
  BrokenTask = 'BROKEN_TASK',
  HeartbeatTask = 'HEARTBEAT_TASK',
  CollectPredictedTask = 'COLLECT_PREDICTED_TASK',
}

export type InventoryTask = {
  status: InventoryTaskStatus
  provider: InventoryTaskProvider
  type: InventoryTaskType
  taskId: string
  taskUrl: string | null
  taskDateFrom: string
  taskDateTo: string
  createDate: string
  updateDate: string
}

export const inventoryTaskFields = `
  status
  provider
  type
  taskId
  taskUrl
  taskDateFrom
  taskDateTo
  createDate
  updateDate
`
