import { VFC } from 'react'
import PageHeader from '@local/components/PageHeader'
import { Typography, Button } from '@web-panel/shared'
import illustrations from '@local/assets/illustrations/neom'
import { SupportBlock } from '@local/components/SupportBlock'
import { useNavigate } from 'react-router-dom'

export const UnidentifiedError: VFC = () => {
  const navigate = useNavigate()

  return (
    <div className="px-24 pb-20 flex flex-col h-full">
      <div>
        <PageHeader hideLogo htmlTitle="Oops!" title="Oops!" status={<SupportBlock />} />
        <Typography className="mt-7" variant="body1">
          Something went wrong with the dispenser. Please try again or contact kooky support.
        </Typography>
      </div>
      <div className="flex-1 flex flex-col items-center">
        <div className="flex-1 flex flex-col justify-center">
          <img width="319" height="319" src={illustrations.scanResult.dispenserError} alt="error" />
        </div>
        <div className="w-full">
          <Button
            className="mb-0"
            fullWidth
            variant="contained"
            onClick={() => navigate('/scan', { replace: true })}
          >
            Scan again
          </Button>
          <Button
            className="mt-16"
            fullWidth
            variant="outlined"
            onClick={() => navigate('/', { replace: true })}
          >
            Dismiss
          </Button>
        </div>
      </div>
    </div>
  )
}
