import { VFC } from 'react'
import { useLocation } from 'react-router-dom'
import { UseTriggerDispenserCode } from '@web-panel/consumer-api'
import { InsufficientFundsError } from './Components/InsufficientFundsError'
import { UnidentifiedError } from './Components/UnidentifiedError'

const DispenserErrorPage: VFC = () => {
  const { state } = useLocation()
  const { code } = state ?? {}

  switch (code) {
    case UseTriggerDispenserCode.InsufficientFunds:
      return <InsufficientFundsError />
    default:
      return <UnidentifiedError />
  }
}

export default DispenserErrorPage
