import { useQuery } from '@apollo/client'
import { CollectionQueryHook, QueryHook } from '@web-panel/api'
import {
  ConsumerLoyaltyOutletsDocument,
  ConsumerLoyaltyOutletsDocumentInput,
  ConsumerLoyaltyOutletsDocumentResponse,
  ConsumerLoyaltyOutletItemsDocumentInput,
  ConsumerLoyaltyOutletItemsDocumentResponse,
  ConsumerLoyaltyOutletItemsDocument,
  ConsumerLoyaltyOutletDocumentInput,
  ConsumerLoyaltyOutletDocumentResponse,
  ConsumerLoyaltyOutletDocument,
  ConsumerLoyaltyOutletRewardsDocument,
  ConsumerLoyaltyOutletRewardsDocumentInput,
  ConsumerLoyaltyOutletRewardsDocumentResponse,
} from '../docs'
import { defaultCollectionVariables } from '../defaults'

export const useConsumerLoyaltyOutlets: CollectionQueryHook<
  ConsumerLoyaltyOutletsDocumentInput,
  ConsumerLoyaltyOutletsDocumentResponse['consumerLoyaltyOutlets']['outlets']
> = (options) => {
  const variables = { ...defaultCollectionVariables }
  const { data, loading, fetchMore, refetch } = useQuery<ConsumerLoyaltyOutletsDocumentResponse>(
    ConsumerLoyaltyOutletsDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data.consumerLoyaltyOutlets.hasNext) return

    const offset = data.consumerLoyaltyOutlets.outlets.length
    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.consumerLoyaltyOutlets?.outlets,
    loading,
    loadMore,
    hasMore: data?.consumerLoyaltyOutlets?.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

type UseConsumerLoyaltyOutletItemsInput = Omit<ConsumerLoyaltyOutletItemsDocumentInput, 'offset'>

export const useConsumerLoyaltyOutletItems: CollectionQueryHook<
  UseConsumerLoyaltyOutletItemsInput,
  ConsumerLoyaltyOutletItemsDocumentResponse['consumerLoyaltyOutletItems']['items']
> = ({ outletId, limit }, options) => {
  const variables = {
    outletId,
    offset: defaultCollectionVariables.offset,
    limit: limit ?? defaultCollectionVariables.limit,
  }
  const { data, loading, fetchMore, refetch } =
    useQuery<ConsumerLoyaltyOutletItemsDocumentResponse>(ConsumerLoyaltyOutletItemsDocument, {
      ...options,
      variables,
    })

  const loadMore = async () => {
    if (loading || !data || !data.consumerLoyaltyOutletItems?.hasNext) return

    const offset = data.consumerLoyaltyOutletItems.items.length
    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.consumerLoyaltyOutletItems.items,
    loading,
    loadMore,
    hasMore: data?.consumerLoyaltyOutletItems.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useConsumerLoyaltyOutlet: QueryHook<
  ConsumerLoyaltyOutletDocumentInput,
  ConsumerLoyaltyOutletDocumentResponse['consumerLoyaltyOutlet']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<ConsumerLoyaltyOutletDocumentResponse>(
    ConsumerLoyaltyOutletDocument,
    {
      ...options,
      variables,
    }
  )

  return {
    data: data?.consumerLoyaltyOutlet,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useConsumerLoyaltyOutletRewards: QueryHook<
  ConsumerLoyaltyOutletRewardsDocumentInput,
  ConsumerLoyaltyOutletRewardsDocumentResponse['consumerLoyaltyOutletRewards']['rewards']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<ConsumerLoyaltyOutletRewardsDocumentResponse>(
    ConsumerLoyaltyOutletRewardsDocument,
    {
      ...options,
      variables,
    }
  )

  return {
    data: data?.consumerLoyaltyOutletRewards.rewards,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}
