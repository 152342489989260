import { FC } from 'react'
import { Typography, Button, styled, isFeatureFlagEnabled } from '@web-panel/shared'
import PageHeader from '@local/components/PageHeader'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { cupPositiveCo2Impact } from '@local/constants'
import { GoldenCardSuccessScreenSection } from '@local/components/PromoCampaign/SucessScreenSection'
import { useUserGoldenCupCampaign } from '@local/hooks/golden-cup-hooks'
import illustrations from '@local/assets/illustrations'
import { SuccessLoyaltyCard } from './SuccessLoyaltyCard'

const DescriptionDiv = styled('div')(() => ({
  backgroundColor: isFeatureFlagEnabled('neom') ? '#F1EFE0' : '#F4FCF0',
}))

type SuccessfulScanResultProps = {
  cupIsTransferred: boolean
  outletId?: string
}

export const SuccessfulScanResult: FC<SuccessfulScanResultProps> = ({
  cupIsTransferred,
  outletId,
}) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { goldenCupCampaign } = useUserGoldenCupCampaign()

  const isGoldCupCampaignEnabled = Boolean(goldenCupCampaign)
  const illustration = cupIsTransferred
    ? illustrations.scanResult.cupTransferred
    : illustrations.scanResult.cupAdded
  const title = formatMessage({
    id: cupIsTransferred ? 'take_cup_owner_change_title' : 'take_cup_success_title',
  })

  return (
    <div className="flex flex-col h-full">
      <div className="px-24 pb-20">
        <PageHeader closable htmlTitle={title} title={title} />
      </div>
      <div className="flex flex-row justify-center">
        <img className="mt-22 w-200 h-200" src={illustration} alt="scan error" />
      </div>
      {outletId && <SuccessLoyaltyCard outletId={outletId} />}
      {isGoldCupCampaignEnabled && <GoldenCardSuccessScreenSection />}
      <DescriptionDiv className="flex flex-1 flex-col px-24 pt-12 justify-between">
        <div>
          <Typography variant="caption" color="success.main">
            <FormattedMessage id="whats_next" />
          </Typography>
          <Typography className="mt-12" variant="h4" color="primary.main">
            <FormattedMessage id="return_the_kooky_cup" />
          </Typography>
          <div className="flex flex-row items-center mt-12">
            <img src={illustrations.kookyBox} alt="kooky box" />
            <Typography className="ml-12" variant="body2">
              <FormattedMessage id="return_the_kooky_cup_msg" />
            </Typography>
          </div>
          {!isGoldCupCampaignEnabled && (
            <>
              <Typography className="mt-12" variant="h4" color="primary.main">
                <FormattedMessage id="tutorial_eco" />
              </Typography>
              <div className="flex flex-row items-center mt-12">
                <img src={illustrations.globe} alt="kooky box" />
                {isFeatureFlagEnabled('neom') ? (
                  <div className="ml-12">
                    <Typography variant="body2">
                      Every time you return a kooky-Cup, you're saving waste that equals to:
                    </Typography>
                    <Typography className="mt-16" variant="h3" color="success.main">
                      {cupPositiveCo2Impact}g
                    </Typography>
                  </div>
                ) : (
                  <Typography className="ml-12" variant="body2">
                    <FormattedMessage id="make_the_world_a_better_place_msg" />
                    &nbsp;
                    {cupPositiveCo2Impact}g Co2
                  </Typography>
                )}
              </div>
            </>
          )}
        </div>
        <div className="h-72">
          <div className="fixed bottom-0 left-0 right-0 px-24">
            <Button fullWidth color="primary" variant="contained" onClick={() => navigate('/')}>
              <FormattedMessage id="take_cup_success_cta" />
            </Button>
          </div>
        </div>
      </DescriptionDiv>
    </div>
  )
}
