import { VFC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuthContext } from '@web-panel/api'
import { Loader } from '@local/components/Loader'
import { useRequestEmailAuthTokens } from '@web-panel/consumer-api'
import { getMemoizedEmail } from './helpers/memoized-email'

const AuthenticatePage: VFC = () => {
  const params = useParams()
  const { request: requestAuthTokens } = useRequestEmailAuthTokens()
  const { signin } = useAuthContext()
  const navigate = useNavigate()
  const { token } = params

  const handleAuthentication = async () => {
    const email = getMemoizedEmail()
    if (!email || !token) {
      navigate('/sign-in', { replace: true })
      return
    }

    try {
      const { accessToken, refreshToken } = await requestAuthTokens({
        email,
        token: Number(token),
      })
      signin({ accessToken, refreshToken })

      navigate('/', { replace: true })
    } catch (err) {
      navigate('/sign-in', { replace: true })
    }
  }

  useEffect(() => {
    handleAuthentication()
  }, [])

  return <Loader />
}

export default AuthenticatePage
