import { useLocale } from '@web-panel/shared'
import { useCampaigns, useMyIpLocation, useCampaignExceptions } from '@web-panel/consumer-api'
import _includes from 'lodash/includes'

export const useGoldenCupCampaign = () => {
  const { locale } = useLocale()
  const { data: ipLocation } = useMyIpLocation()
  const { data, loading } = useCampaigns({
    languageCode: locale,
    countryCode: ipLocation?.country ?? '',
  })

  const goldenCupCampaign = data?.find(({ code }) => code === 'GOLDEN_CUP')

  return {
    goldenCupCampaign,
    loading,
  }
}

export const useUserGoldenCupCampaign = () => {
  const { goldenCupCampaign, loading: loadingCampaign } = useGoldenCupCampaign()
  const { data: exceptions = [], loading: loadingExceptions } = useCampaignExceptions()
  const exceptedCampaignIds = exceptions.map(({ campaignId }) => campaignId)

  const campaignExcepted = goldenCupCampaign
    ? _includes(exceptedCampaignIds, goldenCupCampaign.id)
    : false

  return {
    goldenCupCampaign: campaignExcepted ? null : goldenCupCampaign,
    loading: loadingCampaign || loadingExceptions,
  }
}
