import { fromPromise, Observable } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { attemptToRefreshAccessToken, cleanTokens } from '../tokens-store'

type Callbacks = {
  onUnauthorisedAccess?: () => void
}

const errorCallbacks: Callbacks = {
  onUnauthorisedAccess: undefined,
}

let isRefreshing = false
let pendingRequests: (() => void)[] = []

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback())
  pendingRequests = []
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line consistent-return
export const errorLink = onError(({ operation, networkError, forward }) => {
  const context = operation.getContext()
  if (networkError && !context.isPublic) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { statusCode } = networkError
    if (statusCode === 401) {
      let pendingForward: Observable<string | void>

      if (!isRefreshing) {
        isRefreshing = true

        pendingForward = fromPromise(
          attemptToRefreshAccessToken()
            .then((accessToken) => {
              resolvePendingRequests()

              return accessToken
            })
            .catch((error) => {
              pendingRequests = []
              cleanTokens()
              if (errorCallbacks.onUnauthorisedAccess) errorCallbacks.onUnauthorisedAccess()
            })
            .finally(() => {
              isRefreshing = false
            })
        ).filter((value) => Boolean(value))
      } else {
        pendingForward = fromPromise(
          new Promise<void>((resolve) => {
            pendingRequests.push(() => resolve())
          })
        )
      }

      return pendingForward.flatMap(() => forward(operation))
    }
  }
})

export function setApiRequestCallback(callbackName: keyof Callbacks, callback: () => void) {
  if (errorCallbacks[callbackName])
    console.warn(`Callback ${callbackName} has already been defined`)
  errorCallbacks[callbackName] = callback
}
