import {
  PaymentType,
  BankAccount,
  CreditCard,
  bankAccountFields,
  creditCardFields,
} from './financial'
import { Currency, currencyFields } from './currency'

export enum PaymentOutStatus {
  Created = 'CREATED',
  Processing = 'PROCESSING',
  Processed = 'PROCESSED',
  Rejected = 'REJECTED',
  ApprovalRequired = 'APPROVAL_REQUIRED',
  ManualRequired = 'MANUAL_REQUIRED',
  Approved = 'APPROVED',
}

type Base = {
  id: string
  consumerId: string
  externalPaymentOutId: string
  createDate: string
  amount: number
  amountToPayout: number
  currency: Currency
  paymentOutStatus: PaymentOutStatus
  receipt: string
}

export type PaymentOut = Base &
  (
    | {
        paymentMethodType: PaymentType.BankAccount
        bankAccount: BankAccount
        creditCard: null
      }
    | {
        paymentMethodType: PaymentType.CreditCard
        creditCard: CreditCard
        bankAccount: null
      }
  )

export const paymentOutFields = `
  id
  consumerId
  externalPaymentOutId
  createDate
  amount
  amountToPayout
  currency {
    ${currencyFields}
  }
  paymentOutStatus
  receipt
  paymentMethodType
  bankAccount @type(name: "BankAccount" ){
    ${bankAccountFields}
  }
  creditCard @type(name: "CreditCard") {
    ${creditCardFields}
  }
`

export type PaymentOutStatusAggregation = {
  status: PaymentOutStatus
  count: number
}

export const paymentOutStatusAggregationFields = `
  status
  count
`
