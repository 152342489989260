import { FC } from 'react'
import { ConsumerItemHistory, ProductItemStatus } from '@web-panel/api'
import { FormattedCurrency, Typography } from '@web-panel/shared'
import { getCampaignTheme } from '@local/components/PromoCampaign/consts'

type CupRefundLabelProps = {
  data: ConsumerItemHistory
}

export const CupRefundLabel: FC<CupRefundLabelProps> = ({ data }) => {
  if (!data.refund && data.itemStatus === ProductItemStatus.InUse) return null
  if (data.itemStatus === ProductItemStatus.Transferred) return null

  const { campaignInfo } = data
  const campaignTheme = campaignInfo ? getCampaignTheme(campaignInfo.code) : null

  function getColor() {
    if (campaignTheme) return campaignTheme.text
    if (data.itemStatus === ProductItemStatus.Returned) return 'secondary.main'
    return '#CFD6D9'
  }

  const fontWeight = campaignTheme ? 600 : 400

  return (
    <Typography variant="body1" color={getColor()} sx={{ fontWeight }}>
      <FormattedCurrency
        value={data.refund?.amount ?? 0}
        currency={data.refund?.currency.code ?? ''}
      />
    </Typography>
  )
}
