import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Typography, CodeInput, Link, useNotifications } from '@web-panel/shared'
import { useAuthContext } from '@web-panel/api'
import { FormattedMessage, useIntl } from 'react-intl'
import PageHeader from '@local/components/PageHeader'

import { useSMSRequestAuthTokens, useSMSRequestSecurityCode } from '@web-panel/consumer-api'

const ConfirmPhonePage: FC = () => {
  const [securityCode, setSecurityCode] = useState<number | undefined>()
  const { state } = useLocation()
  const { request: requestSecurityCode } = useSMSRequestSecurityCode()
  const { request: requestAuthTokens } = useSMSRequestAuthTokens()
  const navigate = useNavigate()
  const { signin } = useAuthContext()
  const { pushNotification } = useNotifications()
  const { formatMessage } = useIntl()

  const { phone } = state ?? {}

  const requestCode = async () => {
    try {
      const { securityCode: code } = await requestSecurityCode({ phone })
      setSecurityCode(code)
    } catch (err) {
      navigate(-1)
      pushNotification('error', formatMessage({ id: 'login_error_phone_number' }))
    }
  }

  useEffect(() => {
    requestCode()
  }, [])

  const onSubmit = async (verificationCode: string) => {
    if (!securityCode) return

    try {
      const { accessToken, refreshToken } = await requestAuthTokens({
        phone,
        securityCode,
        code: Number(verificationCode),
      })

      signin({ accessToken, refreshToken })
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'login_error_code' }))
    }
  }

  return (
    <div>
      <PageHeader
        htmlTitle={formatMessage({ id: 'login_confirm_number' })}
        title={formatMessage({ id: 'login_confirm_number' })}
      />
      <Typography className="mt-44">
        <FormattedMessage id="login_enter_the_code" />
        {phone}
        &nbsp;
        <Link to="-1">
          (<FormattedMessage id="login_change_phone" />)
        </Link>
      </Typography>
      <div className="mt-14 flex flex-row justify-around">
        <CodeInput
          autoFocus
          length={6}
          inputMode="numeric"
          autoComplete="one-time-code"
          onCodeEnter={onSubmit}
        />
      </div>
    </div>
  )
}

export default ConfirmPhonePage
