import { gql } from '@apollo/client'
import { Donation, DonationOrganization, donationFields, DonationPaymentType } from '@web-panel/api'
import { CreateWWFAccountDocumentInput } from './payment-methods'

export type CreateDonationDocumentInput = {
  input: {
    donationOrganization: DonationOrganization
    paymentMethodType: DonationPaymentType
    amount: number
    walletId: string
    wwfAccount?: CreateWWFAccountDocumentInput['input']
  }
}
export type CreateDonationDocumentResponse = {
  createDonation: Donation
}
export const CreateDonationDocument = gql`
  mutation CreateDonation($input: any) {
    createDonation(input: $input) @rest(type: "Donation", path: "/finance-service/donations", method: "POST") {
      ${donationFields}
    }
  }
`
