import { FC, createContext, useContext } from 'react'

export type ToolbarContext = {
  leftTools: FC[]
  rightTools: FC[]
}

const Context = createContext<ToolbarContext>({
  leftTools: [],
  rightTools: [],
})

export type ToolbarContextProviderProps = Partial<ToolbarContext>

export const ToolbarContextProvider: FC<ToolbarContextProviderProps> = ({
  children,
  leftTools = [],
  rightTools = [],
}) => {
  return <Context.Provider value={{ leftTools, rightTools }}>{children}</Context.Provider>
}

export const useToolbar = () => {
  return useContext(Context)
}
