import { useState, useEffect } from 'react'

type UseLocalStorageTuple = [string, (value: string) => void, () => void]

export function useLocalStorage(key: string, defaultValue: string): UseLocalStorageTuple {
  const [storageValue, setStorageValue] = useState<string>(
    localStorage.getItem(key) ?? defaultValue
  )

  function triggerStorageEventLocally() {
    const event = new Event('storage')
    window.dispatchEvent(event)
  }

  function set(value: string) {
    // setStorageValue(value)
    localStorage.setItem(key, value)
    triggerStorageEventLocally()
  }

  function remove() {
    // setStorageValue(defaultValue)
    localStorage.removeItem(key)
    triggerStorageEventLocally()
  }

  useEffect(() => {
    const storageListener = (e: StorageEvent) => {
      setStorageValue(localStorage.getItem(key) ?? defaultValue)
    }

    window.addEventListener('storage', storageListener)

    return () => {
      window.removeEventListener('storage', storageListener)
    }
  }, [])

  return [storageValue, set, remove]
}
