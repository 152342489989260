import { FC } from 'react'
import { NavSection, NavigationSectionData } from './NavSection'
import { getNavigation } from '../../registrations'
import { DefaultNavigationProvider } from './DefaultNavigationProvider'
import { NavbarSectionList } from './NavbarSectionList'

export type NavigationData = NavigationSectionData[]

type NavigationProps = {
  onItemClick?: () => void
}

export const Navigation: FC<NavigationProps> = ({ onItemClick }) => {
  const NavigationProvider = getNavigation('NavigationProvider') ?? DefaultNavigationProvider

  return (
    <NavigationProvider>
      {(navigation) => (
        <NavbarSectionList>
          {navigation.map((section, key) => (
            <NavSection key={key} section={section} onItemClick={onItemClick} />
          ))}
        </NavbarSectionList>
      )}
    </NavigationProvider>
  )
}
