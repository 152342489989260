import { VFC, useState } from 'react'
import { Typography, ButtonBase } from '@web-panel/shared'
import { FormattedMessage } from 'react-intl'
import { HistoryListIcon, LoyaltyListIcon } from '@local/components/Icons'
import { LoyaltyList } from '../LoyaltyList'
import { ItemsHistory } from '../ItemsHistory'

const tabs = {
  loyalty: {
    Icon: LoyaltyListIcon,
    Component: LoyaltyList,
  },
  history: {
    Icon: HistoryListIcon,
    Component: ItemsHistory,
  },
} as const

type TabName = keyof typeof tabs

export const OriginaListTabs: VFC = () => {
  const [activeTabName, setActiveTabName] = useState<TabName>('loyalty')

  const { Component } = tabs[activeTabName]

  return (
    <>
      <div className="flex flex-row items-end justify-between px-24 py-22">
        <Typography sx={{ color: '#A7A7A7' }} variant="caption">
          <FormattedMessage id="your_activities" />
        </Typography>
        <div className="grid grid-cols-2 gap-20">
          {(Object.keys(tabs) as TabName[]).map((tabName) => {
            const { Icon } = tabs[tabName]

            return (
              <ButtonBase key={tabName} onClick={() => setActiveTabName(tabName)}>
                <Icon active={tabName === activeTabName} />
              </ButtonBase>
            )
          })}
        </div>
      </div>
      <Component />
    </>
  )
}
