import { FC } from 'react'
import { Avatar, AppBar, Chip, ChipProps } from '@mui/material'

import { styled } from '@mui/material/styles'

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  '& .username, & .email': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  '& .avatar-container': {
    borderRadius: '50%',
    background: theme.palette.background.default,
    width: '72px',
    height: '72px',
  },
}))

export type NavbarHeaderProps = {
  companyName: string
  logoUrl: string | null
  badge?: Pick<ChipProps, 'label' | 'color'>
}

export const NavbarHeader: FC<NavbarHeaderProps> = ({ companyName, logoUrl, badge }) => {
  return (
    <StyledAppBar
      position="relative"
      color="primary"
      className="user relative flex flex-col items-center justify-center pt-24 pb-24 mb-32 z-0 shadow-0"
    >
      <div className="flex items-center justify-center absolute bottom-0 -mb-44">
        <div className="avatar-container p-8 box-content">
          <Avatar
            alt="user photo"
            src={logoUrl ?? undefined}
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            {companyName[0].toUpperCase()}
          </Avatar>
        </div>
      </div>
      {badge && (
        <div className="absolute bottom-0 left-0 right-0 text-center -mb-44">
          <Chip className="max-w-none" size="small" {...badge} />
        </div>
      )}
    </StyledAppBar>
  )
}
