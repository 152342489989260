import { FC } from 'react'
import { AppBar, Typography, Chip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { LogoContainer, StyledContentContainer } from './components'
import { Navigation } from './Navigation'
import { NavbarToggleButton } from './NavbarToggleButton'
import { getAsset, getNavbarComponent } from '../../registrations'
import { ApplicationBuild } from '../../globals'
import { useAppEnv } from '../../hooks'

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  '& ::-webkit-scrollbar-thumb': {
    boxShadow: `inset 0 0 0 20px ${
      theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.24)' : 'rgba(255, 255, 255, 0.24)'
    }`,
  },
  '& ::-webkit-scrollbar-thumb:active': {
    boxShadow: `inset 0 0 0 20px ${
      theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.37)' : 'rgba(255, 255, 255, 0.37)'
    }`,
  },
}))

type NavbarContentProps = {
  onToggle: () => void
  onItemClick?: () => void
}

export const NavbarContent: FC<NavbarContentProps> = ({ onToggle, onItemClick }) => {
  const logo = getAsset('logo')
  const NavbarHeaderComponent = getNavbarComponent('header')
  const CustomComponent = getNavbarComponent('customComponent')
  const { appEnv, isAppEnv } = useAppEnv()

  return (
    <Root className="flex flex-auto flex-col overflow-hidden h-full">
      <AppBar
        color="primary"
        position="static"
        className="flex flex-row items-center flex-shrink h-48 md:h-64 min-h-48 md:min-h-64 px-12 shadow-0"
      >
        <div className="flex flex-1 mx-4">
          <LogoContainer className="flex items-center">
            <img src={logo} alt="logo" className="logo-icon w-1/2" />
            {isAppEnv(['development', 'staging']) && (
              <Chip className="ml-8" variant="filled" color="secondary" label={appEnv} />
            )}
          </LogoContainer>
        </div>
        <NavbarToggleButton onClick={onToggle} />
      </AppBar>
      <StyledContentContainer>
        {NavbarHeaderComponent && <NavbarHeaderComponent />}
        <Navigation onItemClick={onItemClick} />
        <div className="flex flex-col pt-24 pb-12 flex-1 justify-end">
          {CustomComponent && (
            <div className="mb-12">
              <CustomComponent />
            </div>
          )}
          <div className="px-12">
            <Typography variant="subtitle2" color="lightgray">
              Build: {ApplicationBuild}
            </Typography>
          </div>
        </div>
      </StyledContentContainer>
    </Root>
  )
}
