import { FC } from 'react'
import { InfinitScroller } from '@web-panel/shared'
import { LoyaltyCardContainerLoading } from '@local/components/LoyaltyCard/ContainerLoading'
import { DownloadAppButtonSection } from '@local/components/DownloadAppButtonSection'
import { useConsumerLoyaltyOutlets, useItemsHistory } from '@web-panel/consumer-api'
import { LoyaltyCard } from './LoyaltyCard'
import { GhostLoyaltyCard } from './GhostLoyaltyCard'

export const LoyaltyList: FC = () => {
  const {
    data: loyaltyOutlets = [],
    loadMore,
    loading: loadingLoaltyOutlets,
  } = useConsumerLoyaltyOutlets({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })
  const { data: itemsHistory = [], loading: loadingItemsHistory } = useItemsHistory({
    fetchPolicy: 'cache-and-network',
  })

  if (
    (loyaltyOutlets.length === 0 && loadingLoaltyOutlets) ||
    (itemsHistory.length === 0 && loadingItemsHistory)
  )
    return (
      <div className="grid grid-rows gap-12 pb-100">
        {new Array(5).fill(null).map((_, id) => (
          <div key={id}>
            <LoyaltyCardContainerLoading />
          </div>
        ))}
      </div>
    )

  if (itemsHistory.length === 0) return <GhostLoyaltyCard />

  return (
    <InfinitScroller onLoadMore={loadMore}>
      <div className="grid grid-rows gap-12 pb-100">
        {loyaltyOutlets.map((loyaltyOutlet, id) => (
          <div key={loyaltyOutlet.id}>
            <LoyaltyCard loyaltyOutlet={loyaltyOutlet} />
            {id === 1 && (
              <div className="mt-12">
                <DownloadAppButtonSection />
              </div>
            )}
          </div>
        ))}
      </div>
    </InfinitScroller>
  )
}
