import cupAdded from './cup-added.svg'
import cupTransferred from './cup-transferred.svg'
import alreadyScannedByAnother from './cup-already-scanned-by-another.svg'
import alreadyScannedFinished from './cup-already-scanned-finished.svg'
import alreadyScanned from './cup-already-scanned.svg'
import scanError from './scan-error.svg'
import cupNotFound from './cup-not-found.svg'
import insufficientFunds from './insufficient-funds.svg'
import dispenserError from './dispenser-error.svg'

const illustrations = {
  cupAdded,
  cupTransferred,
  alreadyScannedByAnother,
  alreadyScannedFinished,
  alreadyScanned,
  scanError,
  cupNotFound,
  insufficientFunds,
  dispenserError,
} as const

export default illustrations
