import { ApolloClient, ApolloLink, NormalizedCacheObject } from '@apollo/client'
import { RestLink } from 'apollo-link-rest'
import { headersLink } from './headers-link'
import { authHeaderLink } from './auth-header-link'
import { errorLink } from './error-link'
import cache from '../cache'

export { setApiRequestCallback } from './error-link'

let client: ApolloClient<NormalizedCacheObject>

export function configureClient(uri: string, timeout = 1000) {
  const restLink = new RestLink({ uri })
  const link = ApolloLink.from([headersLink, errorLink, authHeaderLink, restLink])

  client = new ApolloClient({
    link,
    cache,
  })
}

export function getClient(): ApolloClient<NormalizedCacheObject> {
  return client
}
