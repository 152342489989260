import { FC } from 'react'
import { Button, Typography } from '@web-panel/shared'
import { ConsumerLoyaltyOutlet, ConsumerLoyaltyOutletReward } from '@web-panel/api'
import { FormattedMessage, useIntl } from 'react-intl'
import { getAppLink } from '@local/helpers'
import rewardLockedIcon from '@local/assets/icons/reward-locked.svg'
import Lottie from 'lottie-react'
import confettiAnimation from '@local/assets/animations/confetti.json'
import defaultOutletLogo from '@local/assets/images/logo-vertical.svg'
import { getIllustrationForRewardType, getLoyaltyRewardModalTitle } from '../helpers'

type FreeCoffeeModalContentProps = {
  reward: ConsumerLoyaltyOutletReward
  outlet: ConsumerLoyaltyOutlet
  achieved: boolean
}

export const FreeCoffeeModalContent: FC<FreeCoffeeModalContentProps> = ({
  reward,
  outlet,
  achieved,
}) => {
  const { formatMessage } = useIntl()
  const illustration = getIllustrationForRewardType(reward.rewardType)
  const textColor = achieved ? 'primary' : 'text.disabled'
  const modalTitle = formatMessage({ id: getLoyaltyRewardModalTitle(reward.rewardType) })

  const buttonTitle = achieved
    ? formatMessage({ id: 'loyalty_reward_modal_achieved_web' })
    : formatMessage(
        { id: 'loyalty_reward_modal_future_web' },
        { count: reward.remainingReturnsToUnlock }
      )

  const handleOnClick = async () => {
    const appLink = await getAppLink()
    window.open(appLink)
  }

  return (
    <>
      {achieved && (
        <div className="absolute top-0 -left-72 -right-72 bottom-0">
          <Lottie animationData={confettiAnimation} loop />
        </div>
      )}
      <img src={illustration} width="120" height="140" alt="reward illustration" />
      <div className="w-full mt-32">
        <Typography variant="h2" color={textColor}>
          {modalTitle}
          {!achieved && (
            <img className="inline w-30 h-30 ml-6 mb-3" src={rewardLockedIcon} alt="lock icon" />
          )}
        </Typography>
      </div>
      <div className="w-full flex flex-row items-center mt-8">
        <div>
          <img src={outlet.logo ?? defaultOutletLogo} width="60" alt="outlet logo" />
        </div>
        <div className="flex-1 pl-12">
          <Typography variant="h4">{outlet.name}</Typography>
          <Typography variant="body2">{outlet.address}</Typography>
        </div>
      </div>
      {!achieved && (
        <>
          <hr className="mt-12 mb-12 w-full" style={{ border: '1px solid #E4E5E6' }} />
          <div className="w-full flex flex-row">
            <div className="flex-1">
              <Typography variant="body2">
                <FormattedMessage id="text_available_rewards" />
              </Typography>
            </div>
            <div>
              <Typography variant="body2" fontWeight="700">
                {reward.remainingNumberOfRewards < 10000
                  ? reward.remainingNumberOfRewards
                  : formatMessage({ id: 'text_unlimited' })}
              </Typography>
            </div>
          </div>
        </>
      )}
      <Button
        fullWidth
        disabled={!achieved}
        variant="contained"
        color="primary"
        onClick={handleOnClick}
      >
        {buttonTitle}
      </Button>
    </>
  )
}
