import {
  FC,
  forwardRef,
  useState,
  useImperativeHandle,
  useRef,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
} from 'react'
import { Dialog, DialogProps } from '@mui/material'

type ModalRef = { open: () => void }

export type ModalBodyProps = {
  onClose: () => void
}

type ModalComponentType<Props> = ForwardRefExoticComponent<
  PropsWithoutRef<Props> & RefAttributes<ModalRef>
>
export function buildModal<Props>(
  ModalBody: FC<Props & ModalBodyProps>,
  dialogProps?: Omit<DialogProps, 'open' | 'onClose'>
): ModalComponentType<Props> {
  return forwardRef<ModalRef, Props>((props, ref) => {
    const [open, setOpen] = useState(false)

    useImperativeHandle(ref, () => ({
      open: () => {
        setOpen(true)
      },
    }))

    const handleClose = () => {
      setOpen(false)
    }

    return (
      <Dialog open={open} onClose={handleClose} {...dialogProps}>
        <ModalBody {...props} onClose={handleClose} />
      </Dialog>
    )
  })
}

export function useModalRef() {
  return useRef<ModalRef>(null)
}
