import { gql } from '@apollo/client'

export const GetTokensDocument = gql`
  query GetTokens($authToken: String!) {
    tokens(authToken: $authToken) @rest(type: "AuthTokens", path: "/auth/tokens?{args}") {
      accessToken
      refreshToken
    }
  }
`

export const RefreshTokensDocument = gql`
  mutation RefreshTokens($input: any) {
    refreshTokens: publish(input: $input)
      @rest(type: "AuthTokens", path: "/auth/refresh", method: "POST") {
      accessToken
      refreshToken
    }
  }
`

export const RequestAuthEmailDocument = gql`
  mutation RequestAuthEmail($input: any) {
    requestAuthEmail(input: $input)
      @rest(type: "AuthEmailRequestResponse", path: "/auth/email", method: "POST") {
      authToken
    }
  }
`
