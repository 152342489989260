import { FC } from 'react'
import { Button } from '@web-panel/shared'
import { useNavigate } from 'react-router-dom'
import QrCodeIcon from '@local/assets/icons/qr-code-icon.svg'

const BottomTabs: FC = () => {
  const navigate = useNavigate()

  return (
    <>
      <Button
        className="fixed bottom-0 mb-20 left-0 right-0 ml-auto mr-auto p-0 w-72 h-72 min-w-0 rounded-full"
        color="primary"
        variant="contained"
        onClick={() => navigate('/scan')}
      >
        <img src={QrCodeIcon} alt="qr-code-icon" width="32px" />
      </Button>
    </>
  )
}

export default BottomTabs
