import { format } from 'date-fns'
import { enGB, de } from 'date-fns/locale'
import { useLocale } from '../contexts/Locale'

const formatters = {
  date: 'dd.MM.yyyy',
  datetime: 'dd.MM.yyyy HH:mm',
  'human-datetime': 'dd MMM yyyy, HH:mm',
  'human-date': 'dd MMMM yyyy',
  time: 'HH:mm',
  'month-year': 'MM/yyyy',
  'human-month-year': 'MMMM yyyy',
}

const dateFnslocales = {
  en: enGB,
  de,
}

type DateFormat = keyof typeof formatters
type FormatFunction = (formatType: DateFormat, value: Date | string) => string

export const useFormatDate = (): FormatFunction => {
  const { locale } = useLocale()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const dateFnsLocale = dateFnslocales[locale]

  return (formatType: DateFormat, value: Date | string) => {
    const formatter = formatters[formatType]

    return format(new Date(value), formatter, { locale: dateFnsLocale })
  }
}
