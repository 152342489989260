import { FC, useRef, ChangeEventHandler } from 'react'
import { Avatar, Icon } from '@mui/material'

export type FileLoadState = 'empty' | 'valid' | 'error'

type FileLoadInputProps = {
  state?: FileLoadState
  accept?: string
  onLoad?: (files: FileList) => void
}

export const FileLoadInput: FC<FileLoadInputProps> = ({ state, accept, onLoad }) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const iconBgColor = () => {
    if (state === 'valid') return '#22d3ee'
    if (state === 'error') return '#f44336'

    return 'default'
  }

  const handleLoad: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { files } = e.currentTarget
    if (!files || files.length === 0) return
    if (onLoad) onLoad(files)
  }

  return (
    <>
      <input
        ref={fileInputRef}
        className="hidden"
        type="file"
        onChange={handleLoad}
        accept={accept}
      />
      <Avatar
        className="w-96 h-96 cursor-pointer"
        color="primary"
        sx={{ bgcolor: iconBgColor() }}
        onClick={() => fileInputRef.current?.click()}
      >
        <Icon className="text-7xl">upload_file</Icon>
      </Avatar>
    </>
  )
}
