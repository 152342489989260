export const sourceShipmentSequenceFields = `
  id
  name
  enabled
  automatized
  type
  createDate
`

export enum SourceShipmentType {
  Cycle = 'CYCLE',
  Series = 'SERIES',
}

export type SourceShipmentSequence = {
  id: string
  name: string
  enabled: boolean
  automatized: boolean
  type: SourceShipmentType
  createDate: string | null
}

export type GroupLabel = {
  id: string
  status: GroupLabelStatus
  externalId: number
  code: string
  unifiedLabelUrl: string
  sourceShipmentSequence: SourceShipmentSequence | null
  updateStatusDate: string
}

export enum GroupLabelStatus {
  Idle = 'IDLE',
  Active = 'ACTIVE',
  Delivered = 'DELIVERED',
  Interrupted = 'INTERRUPTED',
  Deactivated = 'DEACTIVATED',
}

export const groupLabelFields = `
  id
  status
  externalId
  code
  unifiedLabelUrl
  sourceShipmentSequence {
    ${sourceShipmentSequenceFields}
  }
  updateStatusDate
`
