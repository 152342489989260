import { FC } from 'react'
import { LoyaltyCardOutletInfo } from '@local/components/LoyaltyCard/OutletInfo'
import { LoyaltyCardContainer } from '@local/components/LoyaltyCard/Container'
import { LoyaltyCardItemsContainer } from '@local/components/LoyaltyCard/CardItemsContainer'

import { useConsumerLoyaltyOutlet, useConsumerLoyaltyOutletRewards } from '@web-panel/consumer-api'

type SuccessLoyaltyCardProps = {
  outletId: string
}

export const SuccessLoyaltyCard: FC<SuccessLoyaltyCardProps> = ({ outletId }) => {
  const { data } = useConsumerLoyaltyOutlet({ outletId }, { fetchPolicy: 'network-only' })
  const { data: rewards = [], loading: loadingRewards } = useConsumerLoyaltyOutletRewards(
    { outletId },
    { fetchPolicy: 'network-only' }
  )
  if (!data || loadingRewards) return null

  const { outlet, items } = data

  return (
    <LoyaltyCardContainer>
      <LoyaltyCardOutletInfo
        logo={outlet.logo}
        title={outlet.name}
        subtitle={outlet.address}
        itemsCount={outlet.itemsCount}
      />
      <LoyaltyCardItemsContainer items={items} nextReward={rewards[0]} outlet={outlet} />
    </LoyaltyCardContainer>
  )
}
