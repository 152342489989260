import { FC, useMemo } from 'react'
import { formatCurrency, Typography, isFeatureFlagEnabled } from '@web-panel/shared'
import { ConsumerLoyaltyOutletItem, ConsumerLoyaltyOutletItemStatus } from '@web-panel/api'
import illustrations from '@local/assets/illustrations'
import { useIntl } from 'react-intl'

type LoaltyCardItemProps = {
  id?: number
  item: ConsumerLoyaltyOutletItem
}

export const LoaltyCardItem: FC<LoaltyCardItemProps> = ({ id, item }) => {
  const { formatMessage, formatNumber } = useIntl()

  const illustration = useMemo(() => {
    switch (item.status) {
      case ConsumerLoyaltyOutletItemStatus.Reusing:
      case ConsumerLoyaltyOutletItemStatus.InUse:
        return illustrations.cupUsed
      case ConsumerLoyaltyOutletItemStatus.Lost:
        return illustrations.cupLost
      case ConsumerLoyaltyOutletItemStatus.Returned: {
        if (item.campaign?.code === 'LUCKY_CUP') return illustrations.cupReturnedGolden
        if (item.campaign?.code === 'GOLDEN_CUP') return illustrations.cupReturnedGolden
        return illustrations.cupReturned
      }
      case ConsumerLoyaltyOutletItemStatus.BoxCheckedIn:
        return illustrations.cupProcessing
      case ConsumerLoyaltyOutletItemStatus.Transferred:
        return illustrations.cupTransferred
      default:
        return illustrations.cupLost
    }
  }, [item])

  const label = useMemo(() => {
    switch (item.status) {
      case ConsumerLoyaltyOutletItemStatus.Returned:
        if (isFeatureFlagEnabled('neom')) return formatMessage({ id: 'history_status_returned' })
        if (!item.refund || !item.refund.currency)
          return formatNumber(item.refund?.amount ?? 0, { minimumFractionDigits: 2 })

        return `+${formatCurrency(item.refund.amount ?? 0, item.refund.currency.code ?? '', {
          minimumFractionDigits: 2,
        })}`

      case ConsumerLoyaltyOutletItemStatus.InUse:
        return formatMessage({ id: 'history_labelUsing' })
      case ConsumerLoyaltyOutletItemStatus.Reusing:
        return formatMessage({ id: 'history_status_reusing' })
      case ConsumerLoyaltyOutletItemStatus.Lost:
        return formatMessage({ id: 'history_labelLost' })
      case ConsumerLoyaltyOutletItemStatus.Transferred:
        return formatMessage({ id: 'history_status_transferred' })
      case ConsumerLoyaltyOutletItemStatus.BoxCheckedIn:
        return formatMessage({ id: 'history_info_processing_title' })
      default:
        return ''
    }
  }, [item])

  const labelColor = useMemo(() => {
    switch (item.status) {
      case ConsumerLoyaltyOutletItemStatus.Returned:
        return 'success.main'
      case ConsumerLoyaltyOutletItemStatus.InUse:
      case ConsumerLoyaltyOutletItemStatus.Reusing:
      case ConsumerLoyaltyOutletItemStatus.Transferred:
        if (isFeatureFlagEnabled('neom')) return '#003865'
        return 'primary.main'
      case ConsumerLoyaltyOutletItemStatus.Lost:
        return 'error.main'
      case ConsumerLoyaltyOutletItemStatus.BoxCheckedIn:
        return '#F7B420'
      default:
        return 'text.commentary'
    }
  }, [item])

  return (
    <div className="flex flex-col items-center">
      <img className="mb-5" src={illustration} width={60} height={60} alt="cup" />
      <div className="w-full truncate text-center">
        <Typography
          className="whitespace-nowrap"
          variant="caption"
          sx={{ fontWeight: 600 }}
          color={labelColor}
        >
          {label}
        </Typography>
      </div>
      {item && (
        <Typography variant="caption" color="text.commentary">
          {item.itemQrCode}
        </Typography>
      )}
    </div>
  )
}
