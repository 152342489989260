import { FC } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { QrCodeScannerUI } from '@local/components/QrCodeScannerUI'

const cupOnlyRegex = /^https:\/\/.*\/cup\/([A-Za-z0-9]{6})$/
const dispenserAndCupRegex = /^https:\/\/.*\/(?:cup|dispenser)\/([A-Za-z0-9]{6})$/

const NeomScanPage: FC = () => {
  const navigate = useNavigate()
  const { state } = useLocation()

  const isOnlyCupScan = !!state?.onlyCupScan
  const title = !isOnlyCupScan ? 'Scan QR code on a dispenser or a cup' : 'Scan QR code on the cup'
  const codeMatcher = !isOnlyCupScan ? dispenserAndCupRegex : cupOnlyRegex

  const onSubmit = async (code: string) => {
    const isDispenserCode = code.slice(0, 2).toUpperCase() === 'DI'
    if (isDispenserCode) {
      navigate(`/dispensers/${code}`, { replace: true })
    } else {
      navigate(`/scan/cup/${code}`, { replace: true })
    }
  }

  return <QrCodeScannerUI title={title} codeMatcher={codeMatcher} onSubmit={onSubmit} />
}

export default NeomScanPage
