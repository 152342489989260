import { FC, useState, useLayoutEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { Loader } from '@local/components/Loader'

import { UseTriggerDispenserCode, useTriggerDispenser } from '@web-panel/consumer-api'

const DispenserVerifyPage: FC = () => {
  const [resultCode, setResultCode] = useState<UseTriggerDispenserCode | null>(null)
  const { request: triggerDispenser } = useTriggerDispenser()
  const { qrCode } = useParams()

  const handleTriggerDispenser = async () => {
    if (!qrCode) return

    const { code } = await triggerDispenser({ qrCode })
    setResultCode(code)
  }

  useLayoutEffect(() => {
    handleTriggerDispenser()
  }, [])

  if (!resultCode) return <Loader />
  if (resultCode === UseTriggerDispenserCode.Successful)
    return <Navigate to="/dispensers/next-step" replace />

  return <Navigate to="/dispensers/error" replace state={{ code: resultCode }} />
}

export default DispenserVerifyPage
