export const dark = {
  50: '#e5e6e8',
  100: '#bec1c5',
  200: '#92979f',
  300: '#666d78',
  400: '#464e5b',
  500: '#252f3e',
  600: '#212a38',
  700: '#1b2330',
  800: '#161d28',
  900: '#0d121b',
  A100: '#5d8eff',
  A200: '#2a6aff',
  A400: '#004af6',
  A700: '#0042dd',
  contrastDefaultColor: 'light',
}

export const skyBlue = {
  50: '#e4fafd',
  100: '#bdf2fa',
  200: '#91e9f7',
  300: '#64e0f3',
  400: '#43daf1',
  500: '#22d3ee',
  600: '#1eceec',
  700: '#19c8e9',
  800: '#14c2e7',
  900: '#0cb7e2',
  A100: '#ffffff',
  A200: '#daf7ff',
  A400: '#a7ecff',
  A700: '#8de6ff',
  contrastDefaultColor: 'dark',
}

export const accent = {
  50: '#E3F2FD',
  100: '#BBDEFB',
  200: '#90CAF9',
  300: '#64B5F6',
  400: '#42A5F5',
  500: '#2196F3',
  600: '#1E88E5',
  700: '#1976D2',
  800: '#1565C0',
  900: '#0D47A1',
  A100: '#82B1FF',
  A200: '#448AFF',
  A400: '#2979FF',
  A700: '#2962FF',
  contrastText: '#ffffff',
}
