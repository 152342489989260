import _ from 'lodash'
import illustrations from '@local/assets/illustrations'

type CampaignCode = 'GOLDEN_CUP' | 'LUCKY_CUP'

export const campaignCodes: CampaignCode[] = ['GOLDEN_CUP', 'LUCKY_CUP']

type CampaignTheme = {
  text: string
  background: string
  cupIllustration: string
}

export const campaignThemes: Record<CampaignCode, CampaignTheme> = {
  GOLDEN_CUP: {
    text: '#f9d836',
    background: '#FDF5DF',
    cupIllustration: illustrations.goldenCup,
  },
  LUCKY_CUP: {
    text: '#1FCF86',
    background: '#DCF2E6',
    cupIllustration: illustrations.luckyCup,
  },
}

export function getCampaignTheme(campaignCode: string): CampaignTheme | null {
  if (!_.includes(campaignCodes, campaignCode)) return null

  return campaignThemes[campaignCode as CampaignCode]
}
