export type FormatOptions = {
  minimumIntegerDigits?: number
  minimumFractionDigits?: number
  maximumFractionDigits?: number
  minimumSignificantDigits?: number
  maximumSignificantDigits?: number
}

export function formatCurrency(amount: number, currency: string, options: FormatOptions = {}) {
  if (!currency) return amount.toFixed(2)

  const formatter = new Intl.NumberFormat('de-DE', {
    ...options,
    style: 'currency',
    currency: currency === 'CH' ? 'CHF' : currency,
    currencyDisplay: 'code',
  })

  return formatter.format(amount)
}
