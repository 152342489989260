import { FC } from 'react'
import { styled } from '@mui/material/styles'

const MarkerCircle = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  width: 10,
  height: 10,
  margin: 'auto',
  backgroundColor: theme.palette.error.dark,
  borderRadius: 5,
  ':before': {
    position: 'absolute',
    content: '" "',
    height: 25,
    width: 2,
    transform: 'rotate(45deg)',
    backgroundColor: theme.palette.error.dark,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
  },
  ':after': {
    position: 'absolute',
    content: '" "',
    height: 25,
    width: 2,
    transform: 'rotate(-45deg)',
    backgroundColor: theme.palette.error.dark,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
  },
}))

const MarkerContainer = styled('div')(() => ({
  position: 'absolute',
  width: 20,
  height: 20,
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
  transform: 'translate(10px, 10px)',
}))

const Marker: FC = () => {
  return (
    <MarkerContainer>
      <MarkerCircle />
    </MarkerContainer>
  )
}

export default Marker
