import { gql } from '@apollo/client'
import { ConsumerWallet, consumerWalletFields } from '@web-panel/api'

export type ConsumerWalletsDocumentInput = undefined
export type ConsumerWalletsDocumentResponse = {
  consumerWallets: ConsumerWallet[]
}

export const ConsumerWalletsDocument = gql`
  query ConsumerWallets {
    consumerWallets @rest(type: "ConsumerWallet", path: "/finance-service/wallets") {
      ${consumerWalletFields}
    }
  }
`
