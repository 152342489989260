import { useQuery } from '@apollo/client'
import { QueryHook } from '@web-panel/api'
import { AccountDocument, AccountDocumentInput, AccountDocumentResponse } from '../docs'

export const useAccount: QueryHook<AccountDocumentInput, AccountDocumentResponse['account']> = (
  options
) => {
  const { data, loading, refetch } = useQuery<AccountDocumentResponse>(AccountDocument, options)

  return {
    data: data?.account,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}
