import { FC } from 'react'
import { ConsumerLoyaltyOutlet } from '@web-panel/api'
import { LoyaltyCardContainer } from '@local/components/LoyaltyCard/Container'
import { LoyaltyCardOutletInfo } from '@local/components/LoyaltyCard/OutletInfo'
import { LoyaltyCardContainerLoading } from '@local/components/LoyaltyCard/ContainerLoading'
import { LoyaltyCardItemsContainer } from '@local/components/LoyaltyCard/CardItemsContainer'

import {
  useConsumerLoyaltyOutletItems,
  useConsumerLoyaltyOutletRewards,
} from '@web-panel/consumer-api'

type LoyaltyCardProps = {
  loyaltyOutlet: ConsumerLoyaltyOutlet
}
export const LoyaltyCard: FC<LoyaltyCardProps> = ({ loyaltyOutlet }) => {
  const { data: items = [], loading: loadingItems } = useConsumerLoyaltyOutletItems(
    {
      outletId: loyaltyOutlet.id,
      limit: 5,
    },
    { fetchPolicy: 'cache-and-network' }
  )
  const { data: rewards = [], loading: loadingRewards } = useConsumerLoyaltyOutletRewards(
    { outletId: loyaltyOutlet.id },
    { fetchPolicy: 'cache-and-network' }
  )

  if (items.length === 0 && loadingItems && loadingRewards) return <LoyaltyCardContainerLoading />

  return (
    <>
      <LoyaltyCardContainer>
        <LoyaltyCardOutletInfo
          logo={loyaltyOutlet.logo}
          title={loyaltyOutlet.name}
          subtitle={loyaltyOutlet.address}
          itemsCount={loyaltyOutlet.itemsCount}
        />
        <LoyaltyCardItemsContainer
          items={items}
          nextReward={rewards[0] ?? null}
          outlet={loyaltyOutlet}
        />
      </LoyaltyCardContainer>
    </>
  )
}
