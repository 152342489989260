import { Cookies } from 'typescript-cookies'
import { refreshTokens } from './requests'
import { ApplicationEnv } from './globals'

const deployedVersion = ApplicationEnv === 'production' || ApplicationEnv === 'staging'

const cookie = new Cookies({ expires: 7, secure: deployedVersion })

export const accessTokenKey = 'access-token'
export const refreshTokenKey = 'refresh-token'
export const lastSignInKey = 'last-sign-in-at'

export type TokenPair = {
  accessToken: string
  refreshToken: string
}

export function cleanTokens() {
  localStorage.removeItem(accessTokenKey)
  cookie.remove(refreshTokenKey)
}

export function storeTokens({ accessToken, refreshToken }: TokenPair): void {
  localStorage.setItem(accessTokenKey, accessToken)
  cookie.set(refreshTokenKey, refreshToken)
}

export function getRefreshToken(): string | undefined {
  return cookie.get(refreshTokenKey)
}

export async function attemptToRefreshAccessToken(): Promise<string> {
  const refreshToken = getRefreshToken()
  if (!refreshToken) throw new Error('Failed attempt to refresh tokens')

  try {
    const tokens = await refreshTokens({ refreshToken })
    storeTokens(tokens)

    return tokens.accessToken
  } catch (err) {
    cleanTokens()

    throw new Error('Failed attempt to refresh tokens')
  }
}

export async function getAccessToken(): Promise<string> {
  const token = localStorage.getItem(accessTokenKey)
  if (token) return token

  return attemptToRefreshAccessToken()
}

export async function getAccessTokenExists() {
  const accessToken = localStorage.getItem(accessTokenKey)
  return !!accessToken
}

export function storeLastSignIn(): Date {
  const date = new Date()
  localStorage.setItem(lastSignInKey, date.toUTCString())
  return date
}

export function getLastSignIn(): Date | null {
  const date = localStorage.getItem(lastSignInKey)
  if (!date) return null

  return new Date(date)
}
