import { FC, useMemo } from 'react'
import { Typography, ButtonBase } from '@web-panel/shared'
import { FormattedMessage } from 'react-intl'
import { getAppLink } from '@local/helpers'
import classNames from 'classnames'
import _sortBy from 'lodash/sortBy'
import illustrations from '@local/assets/illustrations'
import { useConsumerWallets } from '@web-panel/consumer-api'
import { WalletAmount } from './WalletAmount'

export const WalletsStatus: FC = () => {
  const { data: wallets = [] } = useConsumerWallets({ fetchPolicy: 'cache-and-network' })

  const handleOnClick = async () => {
    const link = await getAppLink('/wallet')
    window.open(link, '_blank')
  }

  const walletsToDisplay = useMemo(() => {
    if (wallets.length <= 2) return wallets

    const walletsSortByAmount = _sortBy(wallets, (wallet) => -wallet.amount)
    return walletsSortByAmount.slice(0, 2)
  }, [wallets])

  return (
    <ButtonBase onClick={handleOnClick}>
      <div className="flex flex-row items-center">
        <div className="flex flex-col items-end">
          <div className="mb-2">
            <Typography variant="subtitle2">
              <FormattedMessage
                id={walletsToDisplay.length > 1 ? 'wallets_title' : 'wallet_title'}
              />
            </Typography>
          </div>
          <div className="flex flex-row">
            {walletsToDisplay.map((wallet, id) => (
              <div key={id} className={classNames({ 'ml-16': id > 0 })}>
                <WalletAmount wallet={wallet} showFlagIcon={walletsToDisplay.length > 1} />
              </div>
            ))}
          </div>
        </div>
        {walletsToDisplay.length === 1 && (
          <img className="ml-4" src={illustrations.coins} alt="coins illustration" />
        )}
      </div>
    </ButtonBase>
  )
}
