import { FC, useState, useLayoutEffect } from 'react'
import { Loader } from '@local/components/Loader'
import { Navigate, useParams } from 'react-router-dom'

import { UseTakeItemResult, useTakeItem } from '@web-panel/consumer-api'

export const ScanComponent: FC = () => {
  const [requestResult, setRequestResult] = useState<UseTakeItemResult | null>(null)
  const { qrCode } = useParams()
  const { request: requestTakeItem, loading } = useTakeItem()

  const takeItem = async () => {
    if (!qrCode) return

    const result = await requestTakeItem({ qr: qrCode })
    setRequestResult(result)
  }

  useLayoutEffect(() => {
    takeItem()
  }, [])

  if (loading || !requestResult) return <Loader />

  return (
    <Navigate
      to="/scan/result"
      replace
      state={{
        code: requestResult.code,
        campaign: requestResult.data?.campaignInfo,
        transferred: requestResult.data?.transferred ?? false,
        outletId: requestResult.data?.outletId ?? undefined,
      }}
    />
  )
}
