import { FC, ReactElement } from 'react'
import { Hidden } from '@mui/material'
import classNames from 'classnames'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import PageIcon from './PageIcon'
import ProgressBar from './ProgressBar'
import BackButton from './BackButton'
import Title from './Title'
import SearchContainer from './SearchContainer'
import ActionButtonsContainer, { ActionButtonsContainerProps } from './ActionButtonsContainer'
import FilterToggleButton from './FilterToggleButton'
import { PageTitle } from '../../PageTitle'

const Root = styled('div')(({ theme }) => ({
  background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
  position: 'relative',
  display: 'flex',
  color: theme.palette.primary.contrastText,
  backgroundSize: 'cover',
}))

export type PageHeaderProps = {
  icon?: string
  title: string
  hideTitleSm?: boolean
  image?: ReactElement
  actionButtons?: ActionButtonsContainerProps['actionButtons']
  searchComponent?: ReactElement
  backButton?: boolean
  onClickBackButton?: () => void
  backButtonLabel?: string
  smSidebarToggle: boolean
  onOpenSidebar: () => void
  additionalContent?: FC
  loading?: boolean
}

const PageHeader: FC<PageHeaderProps> = ({
  icon,
  image,
  smSidebarToggle,
  onOpenSidebar,
  title,
  hideTitleSm,
  backButton = false,
  onClickBackButton,
  backButtonLabel,
  actionButtons,
  searchComponent,
  additionalContent,
  loading = false,
}) => {
  const navigate = useNavigate()
  const AdditionaContent = additionalContent

  const handleClickBackButton = () => {
    if (onClickBackButton) {
      onClickBackButton()
    } else {
      navigate(-1)
    }
  }

  return (
    <>
      <PageTitle title={title} />
      <Root className="min-h-128 flex">
        <div className="flex-1 flex flex-col w-full flex-auto items-center px-24">
          <div className="flex-1 flex flex-col max-w-screen-xl w-full py-16 lg:py-30">
            <ProgressBar show={loading} />
            <div className="flex flex-1 flex-col justify-center">
              {backButton && (
                <BackButton backButtonLabel={backButtonLabel} onClick={handleClickBackButton} />
              )}
              <div className="flex items-center justify-between">
                <div className="flex flex-col">
                  <div className="flex items-center">
                    <PageIcon icon={icon} image={image} />
                    <Title show={!searchComponent && !hideTitleSm} title={title} />
                  </div>
                </div>
                <div
                  className={classNames('flex items-center justify-between', {
                    'flex-1': !!searchComponent,
                  })}
                >
                  <SearchContainer searchComponent={searchComponent} />
                  <Hidden lgDown>
                    <ActionButtonsContainer className="ml-16" actionButtons={actionButtons} />
                  </Hidden>
                  {smSidebarToggle && (
                    <Hidden lgUp>
                      <FilterToggleButton className="ml-16" onClick={onOpenSidebar} />
                    </Hidden>
                  )}
                </div>
              </div>
            </div>
            <Hidden lgUp>
              <div className="flex flex-row justify-end">
                <div className="shrink">
                  <ActionButtonsContainer actionButtons={actionButtons} />
                </div>
              </div>
            </Hidden>
            {AdditionaContent && <AdditionaContent />}
          </div>
        </div>
      </Root>
    </>
  )
}

export default PageHeader
