import { VFC } from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@web-panel/shared'

export const MessageIcon: VFC<SvgIconProps> = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} sx={{ stroke: theme.palette.primary.main }}>
      <g clipPath="url(#clip0_6423_145637)">
        <path
          d="M2.1553 22.9881C1.97629 22.9977 1.79767 22.9631 1.63507 22.8874C1.47248 22.8118 1.33086 22.6973 1.22261 22.5541C1.11436 22.411 1.04277 22.2434 1.01411 22.066C0.985451 21.8887 1.00059 21.707 1.05821 21.5369L3.00006 15.6991C2.4266 14.355 2.1389 12.906 2.1553 11.4444C2.16107 8.67992 3.25798 6.03005 5.20656 4.07323C7.15514 2.11642 9.79712 1.01161 12.5557 1H13.1262C15.7037 1.12778 18.1417 2.21127 19.9665 4.03991C21.7913 5.86855 22.8725 8.31173 23 10.8947V11.4993C22.9798 14.258 21.8735 16.8971 19.9217 18.8427C17.9699 20.7883 15.3306 21.883 12.5777 21.8887C11.1223 21.8877 9.68334 21.5805 8.35386 20.9872L2.50637 22.9881C2.38988 23.004 2.27179 23.004 2.1553 22.9881ZM12.5557 3.19881C11.1555 3.2026 9.77936 3.56442 8.55764 4.25002C7.33592 4.93562 6.309 5.92232 5.57409 7.11672C4.83919 8.31113 4.42061 9.67371 4.35798 11.0755C4.29535 12.4773 4.59073 13.8719 5.21618 15.1274C5.28261 15.2571 5.32264 15.3987 5.33394 15.544C5.34524 15.6893 5.32759 15.8355 5.28201 15.9739L3.8887 20.1517L8.05764 18.7554C8.19578 18.7097 8.34161 18.692 8.48663 18.7034C8.63166 18.7147 8.77299 18.7548 8.9024 18.8214C10.036 19.3922 11.287 19.6896 12.5557 19.6899C13.6381 19.6928 14.7104 19.4817 15.7112 19.0686C16.7121 18.6555 17.6217 18.0486 18.3881 17.2826C19.1545 16.5167 19.7625 15.6067 20.1774 14.6049C20.5923 13.603 20.8058 12.529 20.8058 11.4444V10.8947C20.6845 8.88211 19.8276 6.98487 18.3989 5.56529C16.9701 4.14571 15.0696 3.30325 13.0604 3.19881H12.5557Z"
          stroke="none"
        />
        <path
          d="M9.75 9.00448C9.9851 8.33614 10.4492 7.77258 11.06 7.41361C11.6708 7.05463 12.3889 6.92341 13.0872 7.04319C13.7855 7.16296 14.4188 7.526 14.8751 8.068C15.3313 8.61001 15.5811 9.296 15.58 10.0045C15.58 12.0045 12.58 13.0045 12.58 13.0045"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6602 16.0044H12.6702"
          stroke="fill"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6423_145637">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
