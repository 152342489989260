import { getClient } from '../client'
import { GetTokensDocument, RefreshTokensDocument } from '../docs'

type TokensResponse = {
  accessToken: string
  refreshToken: string
}

type RefreshTokensInput = {
  refreshToken: string
}

export async function refreshTokens(input: RefreshTokensInput): Promise<TokensResponse> {
  const client = getClient()
  const { data } = await client.mutate<{ refreshTokens: TokensResponse }>({
    mutation: RefreshTokensDocument,
    variables: {
      input,
    },
    context: {
      isPublic: true,
    },
    fetchPolicy: 'network-only',
  })

  if (!data || !data.refreshTokens) throw new Error('Could not refresh tokens')

  return data.refreshTokens
}

type GetTokensInput = {
  authToken: string
}

export async function getTokens(input: GetTokensInput): Promise<TokensResponse> {
  const client = getClient()
  const { data } = await client.query<{ tokens: TokensResponse }>({
    query: GetTokensDocument,
    variables: {
      authToken: input.authToken,
    },
    context: {
      isPublic: true,
    },
    fetchPolicy: 'network-only',
  })

  return data.tokens
}
