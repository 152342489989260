import { FC } from 'react'
import { useAuthContext } from '@web-panel/api'
import { ScanComponent } from './ScanComponent'
import { WelcomeComponent } from './WelcomeComponent'

const CupWildScanPage: FC = () => {
  const { authenticated } = useAuthContext()

  if (!authenticated) {
    return <WelcomeComponent />
  }

  return <ScanComponent />
}

export default CupWildScanPage
