import { FC } from 'react'
import Helmet from 'react-helmet'
import { getApplicationTitleBrand } from '../../helpers/globals'
import { getApplicationName } from './registrations'

export const PageTitleProvider: FC = ({ children }) => {
  const applicationTitle = `${getApplicationTitleBrand()} | ${getApplicationName()}`

  return (
    <>
      <Helmet>
        <title>{applicationTitle}</title>
      </Helmet>
      {children}
    </>
  )
}
