import { FC } from 'react'
import {
  DialogContent,
  Typography,
  CodeInput,
  buildModal,
  ModalBodyProps,
  countries,
} from '@web-panel/shared'
import { FormattedMessage } from 'react-intl'

export const defaultCountry = countries.find(({ code }) => code === 'AT') ?? countries[0]

type ManualCodeEnterModalProps = ModalBodyProps & {
  onCodeEnter: (code: string) => void
}

const ManualCodeEnterModal: FC<ManualCodeEnterModalProps> = ({ onClose, onCodeEnter }) => {
  return (
    <DialogContent>
      <Typography className="mt-24" variant="h2">
        <FormattedMessage id="manual_code_insert_title" />
      </Typography>
      <Typography className="mt-48" variant="body2">
        <FormattedMessage id="manual_code_insert_hint" />
      </Typography>
      <div className="mt-48 mb-72">
        <CodeInput length={6} onCodeEnter={onCodeEnter} />
      </div>
    </DialogContent>
  )
}

export default buildModal(ManualCodeEnterModal, {
  maxWidth: 'sm',
  classes: { container: 'items-end', paper: 'w-full m-0 rounded-b-none' },
})
