import { FC, useMemo } from 'react'
import { ConsumerItemHistory, ProductItemStatus } from '@web-panel/api'
import {
  Typography,
  styled,
  useFormatDate,
  isFeatureFlagDisabled,
  isFeatureFlagEnabled,
} from '@web-panel/shared'
import { useIntl, FormattedMessage } from 'react-intl'
import { getCampaignTheme } from '@local/components/PromoCampaign/consts'
import { CupRefundLabel } from './CupRefundLabel'
import { CupCO2Label } from './CupCO2Label'

const Container = styled('div')(() => ({}))

type ListItemProps = {
  data: ConsumerItemHistory
}

export const ListItem: FC<ListItemProps> = ({ data }) => {
  const { formatMessage } = useIntl()
  const { campaignInfo } = data
  const formatDate = useFormatDate()
  const accentLabelColor = useMemo(() => {
    switch (data.itemStatus) {
      case ProductItemStatus.Returned:
        return 'success.main'
      case ProductItemStatus.Lost:
        return 'error.main'
      case ProductItemStatus.BoxCheckedIn:
        return 'info.main'
      case ProductItemStatus.Transferred:
        return '#979797'
      case ProductItemStatus.Reusing:
        if (isFeatureFlagEnabled('neom')) return '#003865'
        return 'primary'
      default:
        if (isFeatureFlagEnabled('neom')) return '#003865'
        return 'primary.main'
    }
  }, [data.statusName])

  const statusTranslation = useMemo(() => {
    switch (data.itemStatus) {
      case ProductItemStatus.InUse:
        return formatMessage({ id: 'history_status_inuse' })
      case ProductItemStatus.Lost:
        return formatMessage({ id: 'history_status_lost' })
      case ProductItemStatus.Returned:
        return formatMessage({ id: 'history_status_returned' })
      case ProductItemStatus.BoxCheckedIn:
        return formatMessage({ id: 'history_info_processing_title' })
      case ProductItemStatus.Transferred:
        return formatMessage({ id: 'history_status_transferred' })
      case ProductItemStatus.Reusing:
        return formatMessage({ id: 'history_status_reusing' })
      default:
        return null
    }
  }, [data.itemStatus])

  const takenFromLabel = useMemo(() => {
    switch (data.itemStatus) {
      case ProductItemStatus.Reusing:
        return formatMessage({ id: 'history_kooky_user' })
      default:
        return data.takenFrom
    }
  }, [data.itemStatus, data.takenFrom])

  const backgroundColor = useMemo(() => {
    if (campaignInfo) return getCampaignTheme(campaignInfo.code)
    if (data.itemStatus === ProductItemStatus.Transferred) return '#E6E8E9'

    return 'background.paper'
  }, [campaignInfo, data.itemStatus])

  const campaignTheme = campaignInfo ? getCampaignTheme(campaignInfo.code) : null

  return (
    <Container className="flex flex-col px-16" sx={{ backgroundColor }}>
      <div className="flex flex-row pt-16 pb-20 border-b-1">
        <div className="flex flex-col">
          <Typography variant="body1">{takenFromLabel}</Typography>
          <Typography variant="caption" color="gray">
            {formatDate('human-datetime', data.takenWhen)}
          </Typography>
          <Typography className="mt-4" variant="caption" color="gray">
            #{data.qr}
            {campaignTheme && campaignInfo && (
              <>
                &nbsp;-&nbsp;
                <span style={{ color: campaignTheme.text, fontWeight: 600 }}>
                  <FormattedMessage id={`promo_campaign_name.${campaignInfo.code}`} />
                </span>
              </>
            )}
          </Typography>
        </div>
        <div className="flex flex-1 flex-col items-end">
          <Typography variant="subtitle1" color={accentLabelColor}>
            {statusTranslation}
          </Typography>
          {isFeatureFlagDisabled('neom') && (
            <div className="mt-2">
              <CupRefundLabel data={data} />
            </div>
          )}
          <CupCO2Label data={data} />
        </div>
      </div>
    </Container>
  )
}
