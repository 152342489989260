import { FC, Fragment } from 'react'
import {
  DialogContent,
  Typography,
  buildModal,
  ModalBodyProps,
  countries,
  isFeatureFlagEnabled,
} from '@web-panel/shared'
import { FormattedMessage } from 'react-intl'

export const defaultCountry = countries.find(({ code }) => code === 'AT') ?? countries[0]

const descriptionItems = isFeatureFlagEnabled('neom')
  ? [
      { title: 'activity_bottomsheet_using_title', message: 'activity_bottomsheet_using_body' },
      {
        title: 'activity_bottomsheet_returned_title',
        message: 'activity_bottomsheet_returned_body',
      },
      { title: 'activity_bottomsheet_lost_title', message: 'activity_bottomsheet_lost_body' },
      { title: 'history_info_processing_title', message: 'history_info_processing' },
    ]
  : [
      { title: 'activity_bottomsheet_using_title', message: 'activity_bottomsheet_using_body' },
      {
        title: 'activity_bottomsheet_returned_title',
        message: 'activity_bottomsheet_returned_body',
      },
      { title: 'activity_bottomsheet_lost_title', message: 'activity_bottomsheet_lost_body' },
      { title: 'history_info_processing_title', message: 'history_info_processing' },
      { title: 'history_status_transferred', message: 'activity_bottomsheet_transferred_body' },
      { title: 'history_status_reusing', message: 'activity_bottomsheet_reusing_body' },
    ]

const InfoDescriptionModal: FC<ModalBodyProps> = () => {
  return (
    <DialogContent>
      <Typography className="mt-6" variant="h2">
        <FormattedMessage id="activity_bottomsheet_title" />
      </Typography>

      <div className="pb-12">
        {descriptionItems.map(({ title, message }, id) => (
          <Fragment key={id}>
            <Typography className="mt-16" variant="body1" fontWeight={700}>
              <FormattedMessage id={title} />
            </Typography>
            <Typography variant="body1">
              <FormattedMessage id={message} />
            </Typography>
          </Fragment>
        ))}
      </div>
    </DialogContent>
  )
}

export default buildModal(InfoDescriptionModal, {
  maxWidth: 'sm',
  classes: { container: 'items-end', paper: 'w-full m-0 rounded-b-none' },
})
