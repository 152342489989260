import { FC } from 'react'

const AuthLayout: FC = ({ children }) => {
  return (
    <>
      <div className="flex flex-row justify-center w-full h-full">
        <div className="flex p-24 max-w-sm w-full">
          <div className="flex flex-col justify-center">{children}</div>
        </div>
      </div>
    </>
  )
}

export default AuthLayout
