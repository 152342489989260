import { RouteConfig } from '../contexts/Router/helpers'

let registeredRoutes: RouteConfig = []
let useAfterAuthRoutes = true

export function registerRoutes(routes: RouteConfig) {
  registeredRoutes = routes
}

export function getRegisteredRoutes(): RouteConfig {
  return registeredRoutes
}

export function isAfterAuthRoutesEnabled(): boolean {
  return useAfterAuthRoutes
}

export function registerAfterAuthRoutesEnabled(enabled: boolean) {
  useAfterAuthRoutes = enabled
}
