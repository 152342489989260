export enum UserRole {
  // OC Panel users
  Administrator = 'ADMINISTRATOR',
  OperationsManager = 'OPERATIONS_MANAGER',
  Driver = 'DRIVER',
  // Parttner Panel users
  Partner = 'PARTNER',
  // Consumer app users
  Consumer = 'CONSUMER',
  // Technical
  Box = 'BOX',
  PartnerEmployee = 'PARTNER_EMPLOYEE',
}

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
  Deleted = 'DELETED',
}

// TODO: Split User type to different user types

export type Consumer = {
  id: string
  userNumber: string
  firstName: string | null
  lastName: string | null
  email: string | null
  phone: string
  role: UserRole.Consumer
  status: UserStatus
  createDate: string
}

export const consumerFields = `
  id @export(as: "id")
  userNumber
  firstName
  lastName
  email
  phone
  role
  status
  createDate
`

export type User = {
  id: string
  userNumber: string
  firstName: string | null
  lastName: string | null
  email: string | null
  phone: string | null
  role: UserRole
  status: UserStatus
}

export const userFields = `
  id
  userNumber
  firstName
  lastName
  email
  phone
  role
  status
`
