import cupAdded from './cup-added.svg'
import cupTransferred from './cup-transferred.svg'
import alreadyScannedByAnother from './cup-already-scanned-by-another.svg'
import alreadyScannedFinished from './cup-already-scanned-finished.svg'
import alreadyScanned from './cup-already-scanned.svg'
import scanError from './scan-error.svg'
import cupNotFound from './cup-not-found.svg'

const illustrations = {
  cupAdded,
  cupTransferred,
  alreadyScannedByAnother,
  alreadyScannedFinished,
  alreadyScanned,
  scanError,
  cupNotFound,
} as const

export default illustrations
