import { PaymentType } from './financial'
import { OrderInvoiceRecipient } from './invoice'

type WithoutNullableKeys<Type> = {
  [Key in keyof Type]-?: WithoutNullableKeys<NonNullable<Type[Key]>>
}

export enum PartnerStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Deleted = 'DELETED',
}

export enum DashboardType {
  B2B2C = 'B2B2C',
  B2B = 'B2B',
}

export type PartnerInvoiceAddress = {
  countryCode: string
  city: string | null
  street: string | null
  houseNumber: string | null
  postalCode: string | null
  addressAddition: string | null
}

export type InputPartnerInvoiceAddress = WithoutNullableKeys<PartnerInvoiceAddress>

const partnerInvoiceAddressFields = `
  countryCode
  city
  street
  houseNumber
  postalCode
  addressAddition
`

export type PartnerFinance = {
  paymentType: PaymentType
  paymentTerm: number
}

export const partnerFinanceFields = `
  paymentType
  paymentTerm
`

export type PartnerFeature = {
  id: string
  name: string
  description: string
}

export const partnerFeatureFields = `
  id
  name
  description
`

export type Partner = {
  id: string
  partnerNumber: string
  name: string
  firstName: string
  lastName: string
  phone: string | null
  email: string
  operationsPhone: string | null
  operationsEmail: string | null
  invoicesEmail: string | null
  additionalInfo: string | null
  registrationId: string | null
  logo: string | null
  pinLogo: string | null
  partnerStatus: PartnerStatus
  invoiceAddress: PartnerInvoiceAddress
  invoiceRecipient: OrderInvoiceRecipient
  finance: PartnerFinance
  authorized: boolean
  authorizedToOrder: boolean
  tradeRegisterExcerpt: string | null
  test: boolean
  kookyOperated: boolean
  loyaltyEnabled: boolean
  createDate: string
  features: PartnerFeature[]
  deliverySignatureRequired: true
  partnerDashboards: string[]
}

export const partnerFields = `
  id
  partnerNumber
  name
  firstName
  lastName
  phone
  email
  operationsPhone
  operationsEmail
  invoicesEmail
  additionalInfo
  registrationId
  logo
  pinLogo
  partnerStatus
  invoiceAddress {
    ${partnerInvoiceAddressFields}
  }
  invoiceRecipient
  finance {
    ${partnerFinanceFields}
  }
  authorized
  authorizedToOrder
  tradeRegisterExcerpt
  test
  kookyOperated
  loyaltyEnabled
  createDate
  features {
    ${partnerFeatureFields}
  }
  deliverySignatureRequired
  partnerDashboards
`

export type PartnerSupplement = {
  id: string
  field: string
  value: string
}

export const partnerSupplementFields = `
  id
  field
  value
`
