import { Currency, currencyFields } from './currency'

export type ConsumerWallet = {
  id: string
  amount: number
  minimalAmount: number // Only for neom
  availableAmount: number // Only for neom
  currency: Currency
  updateDate: string
  campaignIds: string[]
}

export const consumerWalletFields = `
  id
  amount
  minimalAmount
  availableAmount
  currency {
    ${currencyFields}
  }
  updateDate
  campaignIds
`
