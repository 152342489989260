type AssetName = 'logo'

type Assets = Record<AssetName, string>

let assets: Assets = {
  logo: '',
}

export function registerAsset(name: AssetName, content: string) {
  assets[name] = content
}

export function clearAssetRegistrations() {
  assets = {
    logo: '',
  }
}

export function getAsset(name: AssetName) {
  return assets[name]
}
