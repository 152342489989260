import { gql } from '@apollo/client'
import {
  Campaign,
  campaignFields,
  CampaignException,
  campaignExceptionFields,
} from '@web-panel/api'

export type CampaignsDocumentInput = {
  languageCode: string
  countryCode: string
}

export type CampaignsDocumentResponse = {
  campaignsCollection: {
    campaigns: Campaign[]
  }
}

export const CampaignsDocument = gql`
  query Campaigns($languageCode: String!, $countryCode: String!) {
    campaignsCollection(languageCode: $languageCode, countryCode: $countryCode) @rest(type: "CampaignsCollection", path: "/operations-service/public/campaigns?{args}") {
      campaigns @type(name: "Campaign") {
        ${campaignFields}
      }
    }
  }
`

export type CampaignExceptionsDocumentInput = undefined
export type CampaignExceptionsDocumentResponse = {
  campaignExceptions: {
    userCampaignExceptions: CampaignException[]
  }
}

export const CampaignExceptionsDocument = gql`
  query CampaignExceptions {
    campaignExceptions @rest(type: "CampaignExceptions", path: "/operations-service/users/campaigns/exceptions") {
      userCampaignExceptions @type(name: "CampaignException") {
        ${campaignExceptionFields}
      }
    }
  }
`

export type UpdateCampaignExceptionsDocumentInput = {
  campaignId: string
}
export type UpdateCampaignExceptionsDocumentResponse = void

export const UpdateCampaignExceptionsDocument = gql`
  mutation UpdateCampaignExceptions($campaignId: String!) {
    updateCampaignExceptions(campaignId: $campaignId, input: {})
      @rest(
        type: "UpdateCampaignExceptionsResponse"
        path: "/operations-service/users/campaigns/{args.campaignId}/exceptions"
        method: "POST"
      ) {
      NoResponse
    }
  }
`
