import { FC } from 'react'
import { styled, Typography } from '@web-panel/shared'
import { useUserGoldenCupCampaign } from '@local/hooks/golden-cup-hooks'
import { FormattedNumber } from 'react-intl'
import illustrations from '@local/assets/illustrations'

const Card = styled('div')(() => ({
  borderRadius: '8px',
  backgroundColor: '#FDF5DF',
  borderColor: '#FAB621',
  borderWidth: '1px',
  padding: '8px',
}))

type GoldenCupActivityCardProps = {
  onClick: () => void
}

export const GoldenCupActivityCard: FC<GoldenCupActivityCardProps> = ({ onClick }) => {
  const { goldenCupCampaign: campaign } = useUserGoldenCupCampaign()
  if (!campaign) return null

  return (
    <Card className="flex flex-row items-center" onClick={onClick}>
      <div className="mr-5">
        <img src={illustrations.goldenCupSmall} width="20px" alt="golden cup" />
      </div>
      <div className="flex flex-col">
        <Typography variant="caption">{campaign.currencyCode}</Typography>
        <Typography variant="body2" sx={{ color: '#FAB621' }}>
          <FormattedNumber
            value={campaign.amount}
            minimumFractionDigits={0}
            maximumFractionDigits={2}
          />
        </Typography>
      </div>
    </Card>
  )
}
