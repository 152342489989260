import { VFC } from 'react'
import { formatCurrency, FormatOptions } from './helpers'

type FormattedCurrencyProps = {
  value: number
  currency: string
} & FormatOptions

export const FormattedCurrency: VFC<FormattedCurrencyProps> = ({ value, currency, ...options }) => {
  return <>{formatCurrency(value, currency, options)}</>
}
