export type AnalyticsValueMetric = {
  value: number
  date: string
}

export const analyticsValueMetricFields = `
  value
  date
`

export type AnalyticsCountMetric = {
  count: number
  date: string
}

export const analyticsCountMetricFields = `
  count
  date
`

export type SerialValueMetricsData = {
  holderId: string
  metrics: AnalyticsValueMetric[]
}

export const serialValueMetricsDataFields = `
  holderId
  metrics {
    ${analyticsValueMetricFields}
  }
`

export type SerialCountMetricsData = {
  holderId: string
  metrics: AnalyticsCountMetric[]
}

export const serialCountMetricsDataFields = `
  holderId
  metrics {
    ${analyticsCountMetricFields}
  }
`

export type TotalCountMetricsData = {
  holderId: string
  count: number
}

export const totalCountMetricsDataFields = `
  holderId
  count
`

export type TotalItemsMetricsCountData = {
  holderId: string
  returnedCount: number
  deliveredCount: number
}

export const totalItemsMetricsCountDataFields = `
  holderId
  returnedCount
  deliveredCount
`

export type TotalValueMetricsData = {
  holderId: string
  value: number
}

export const totalValueMetricsDataFields = `
  holderId
  value
`

export enum AnalyticAggregationType {
  Cumulative = 'CUMULATIVE',
  Individual = 'INDIVIDUAL',
}

export enum AnalyticItemSeriesType {
  Lost = 'lost',
  Scanned = 'scanned',
  Returned = 'returned',
}

export enum AnalyticTotalCountType {
  Delivered = 'delivered',
  Scanned = 'scanned',
  Lost = 'lost',
  Returned = 'returned',
}

export type AnalyticItemsNotReutnedSummary = {
  outletId: string
  notReturned: number
  notReturnedFrom30To60Days: number
  notReturnedFrom60To90Days: number
  notReturnedFrom90To120Days: number
  notReturnedIn120Days: number
}

export const analyticItemsNotReutnedSummaryFields = `
  outletId
  notReturned
  notReturnedFrom30To60Days
  notReturnedFrom60To90Days
  notReturnedFrom90To120Days
  notReturnedIn120Days
`
