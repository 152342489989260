import { getAppEnv } from '@web-panel/shared'

export const cupNegativeCo2Impact = 120
export const cupPositiveCo2Impact = 17.33

export const termsLink = 'https://kooky2go.com/terms'
export const privacyPolicyLink = 'https://kooky2go.com/privacy'

export const storeLinks = {
  ios: 'https://apps.apple.com/us/app/kooky/id1572827930',
  android: 'https://play.google.com/store/apps/details?id=com.kooky2go.app&hl=en_US&gl=US',
}

export const appLink = 'https://kooky2go.page.link'
export const webLink =
  getAppEnv() === 'production'
    ? 'https://consumer.kooky2go.com/'
    : 'https://consumer.staging.kookyrail.com/'
export const ibi = getAppEnv() === 'production' ? 'app.kooky2go' : 'app.kooky2go.stg'
export const apn = getAppEnv() === 'production' ? 'com.kooky2go.app' : 'com.kooky2go.app.stg'
export const isi = '1572827930'
