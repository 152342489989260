import { FC } from 'react'
import { defaultColor, activeColor, defaultSize } from './constants'
import { IconProps } from './types'

export const CupIcon: FC<IconProps> = ({ active = false, size = defaultSize }) => {
  const color = active ? activeColor : defaultColor

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0043 4.34366e-06C18.815 -0.00158047 19.5605 0.430611 19.9639 1.12568L20.0315 1.25104L21.7285 4.6521C21.8642 4.92124 21.8507 5.24141 21.6931 5.49827C21.5529 5.72659 21.3166 5.87593 21.0538 5.90694L21.0063 5.90946L18.5382 22.0854C18.379 23.1115 17.5458 23.8856 16.5354 23.9883L16.4082 23.9976L16.2794 23.9998H7.56509C6.48345 24.0123 5.55105 23.2585 5.32762 22.2121L5.30425 22.0852L2.81462 5.90946L2.76746 5.90694C2.57061 5.88368 2.38846 5.79386 2.25056 5.65409L2.18544 5.58011L2.12851 5.49827C1.98837 5.26995 1.96221 4.99161 2.05316 4.74397L2.09276 4.65271L3.79014 1.251C4.15227 0.525627 4.87289 0.0530488 5.675 0.00419639L5.81734 4.34366e-06H18.0043ZM17.1051 19.8257H6.71507L7.01849 21.817C7.05396 22.0508 7.24112 22.2285 7.47179 22.2566L7.54219 22.2605L16.2744 22.2605C16.5109 22.2631 16.7164 22.107 16.7811 21.8849L16.7963 21.8163L17.1051 19.8257ZM19.2414 5.91294H4.57946L6.44586 18.0866H17.3674L19.2414 5.91294ZM18.0058 1.73908H5.8158C5.64225 1.73789 5.48123 1.82289 5.38412 1.96237L5.34657 2.0254L4.27477 4.17384H19.5461L18.4754 2.02606C18.3973 1.87107 18.2482 1.76655 18.0793 1.74376L18.0058 1.73908Z"
        fill={color}
      />
    </svg>
  )
}
