import { FC } from 'react'
import Hidden from '@mui/material/Hidden'
import { StyledNavBar, StyledNavBarMobile } from './components'
import { NavbarContent } from './NavbarContent'
import { useNavbar } from '../../contexts/Navbar'

export const Navbar: FC = () => {
  const { isOpen, isOpenMobile, closeNavbar, closeMobileNavbar } = useNavbar()

  return (
    <>
      <Hidden lgDown>
        <StyledNavBar
          className="flex-col flex-auto sticky top-0 overflow-hidden h-screen flex-shrink-0 z-20 shadow-5"
          open={isOpen}
        >
          <NavbarContent onToggle={closeNavbar} />
        </StyledNavBar>
      </Hidden>

      <Hidden lgUp>
        <StyledNavBarMobile
          classes={{
            paper: 'flex-col flex-auto h-full',
          }}
          anchor="left"
          variant="temporary"
          open={isOpenMobile}
          onClose={closeMobileNavbar}
          onOpen={() => {}}
          disableSwipeToOpen
          ModalProps={{
            keepMounted: true,
          }}
        >
          <NavbarContent onToggle={closeMobileNavbar} onItemClick={closeMobileNavbar} />
        </StyledNavBarMobile>
      </Hidden>
    </>
  )
}
