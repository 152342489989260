import { useMutation } from '@apollo/client'
import { MutationHook } from '@web-panel/api'
import {
  TriggerDispenserDocument,
  TriggerDispenserDocumentInput,
  TriggerDispenserDocumentResponse,
} from '../docs'

export enum UseTriggerDispenserCode {
  Successful = 200,
  InsufficientFunds = 4052,
  NotFound = 4010,
}

type UseTriggerDispenserResponse = {
  code: UseTriggerDispenserCode
}

export const useTriggerDispenser: MutationHook<
  TriggerDispenserDocumentInput,
  UseTriggerDispenserResponse
> = (options) => {
  const [execute, { loading }] = useMutation<TriggerDispenserDocumentResponse>(
    TriggerDispenserDocument,
    options
  )

  async function request(variables: TriggerDispenserDocumentInput) {
    try {
      await execute({ variables })

      return {
        code: UseTriggerDispenserCode.Successful,
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const { errorCode } = err?.networkError?.result ?? {
        errorCode: UseTriggerDispenserCode.NotFound,
      }

      return {
        code: errorCode,
      }
    }
  }

  return {
    request,
    loading,
  }
}
