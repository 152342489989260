import { FC } from 'react'
import { Typography, ButtonBase, useModalRef } from '@web-panel/shared'
import { MessageIcon } from '@local/components/Icons'
import { SupportChatModal } from '@local/components/SupportChatModal'

export const SupportBlock: FC = () => {
  const modalRef = useModalRef()

  return (
    <>
      <SupportChatModal ref={modalRef} />
      <ButtonBase className="flex flex-row items-center" onClick={() => modalRef.current?.open()}>
        <MessageIcon className="mr-6" />
        <Typography variant="h5">Support</Typography>
      </ButtonBase>
    </>
  )
}
