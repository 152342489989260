import { FC, ReactElement } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthState } from '../AuthState'
import {
  setCurrentRouteAsAfterAuth,
  getAfterAuthRoute,
  clearAfterAuthRoute,
} from '../../helpers/routes'
import { isAfterAuthRoutesEnabled } from '../../registrations/routes'

export type AccessStrategy = 'authenticated' | 'public-only' | 'authenticated-and-public'

type ProtectedRouteElementProps = {
  routeKey?: string
  accessStrategy: AccessStrategy
  children: ReactElement
}

const ProtectedRouteElement: FC<ProtectedRouteElementProps> = ({ accessStrategy, children }) => {
  const { authenticated, redirect } = useAuthState()
  const afterAuthRoutesEnabled = isAfterAuthRoutesEnabled()

  if (accessStrategy === 'authenticated' && !authenticated) {
    if (afterAuthRoutesEnabled) setCurrentRouteAsAfterAuth()
    return <Navigate to={redirect.onRestrictedAuth} />
  }
  if (accessStrategy === 'authenticated' && authenticated) {
    if (afterAuthRoutesEnabled) clearAfterAuthRoute()
    sessionStorage.removeItem('initialRoute')
  }
  if (accessStrategy === 'public-only' && authenticated) {
    let redirectRoute: string

    if (afterAuthRoutesEnabled) {
      redirectRoute = getAfterAuthRoute() ?? redirect.onRestrictedNonAuth
    } else {
      redirectRoute = redirect.onRestrictedNonAuth
    }
    return <Navigate to={redirectRoute} />
  }

  return children
}

export default ProtectedRouteElement
