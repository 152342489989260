import { FC, MouseEventHandler } from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

type LinkProps = RouterLinkProps & {
  stopPropogation?: boolean
}

export const Link: FC<LinkProps> = ({ children, stopPropogation, onClick, ...props }) => {
  const handleOnClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (stopPropogation) e.stopPropagation()
    if (onClick) onClick(e)
  }

  return (
    <RouterLink {...props} onClick={handleOnClick}>
      {children}
    </RouterLink>
  )
}
