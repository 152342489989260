import { enabledFeatureFlags } from '../globals'

export function getFeatureFlags(): string[] {
  return enabledFeatureFlags
}

export function isFeatureFlagEnabled(flag: string): boolean {
  return getFeatureFlags().includes(flag)
}

export function isFeatureFlagDisabled(flag: string): boolean {
  return !isFeatureFlagEnabled(flag)
}
