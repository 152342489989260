import { OrderInvoiceType, OrderInvoiceStatus } from './order'

export enum OrderInvoiceFrequency {
  PerOrder = 'PER_ORDER',
  PerMonth = 'PER_MONTH',
  PerMonthPerOutlet = 'PER_MONTH_PER_OUTLET',
}

export enum OrderInvoiceRecipient {
  Partner = 'PARTNER',
  Outlet = 'OUTLET',
}

export type Invoice = {
  id: string
  externalId: string
  partnerId: string | null
  outletId: string | null
  type: OrderInvoiceType
  frequency: OrderInvoiceFrequency
  status: OrderInvoiceStatus
  totalCost: number
  currencyCode: string
  dateFrom: string
  dateTo: string
  createDate: string
  invoiceDate: string
  invoicePdf: string
}

export const invoiceFields = `
  id
  externalId
  partnerId
  outletId
  type
  frequency
  status
  totalCost
  currencyCode
  dateFrom
  dateTo
  createDate
  invoiceDate
  invoicePdf
`
