import { InMemoryCache } from '@apollo/client'
import buildCachePolicy from './cache-policy-builder'

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        partnersCollection: buildCachePolicy(['search', 'partnerId'], 'partners', 'Partner'),
        outletsCollection: buildCachePolicy(['search', 'partnerId'], 'outlets', 'Outlet'),
        userOutletsCollection: buildCachePolicy(['userId'], 'outlets', 'Outlet'),
        orderCollection: buildCachePolicy(
          ['outletId', 'partnerId', 'status', 'search'],
          'orders',
          'Order'
        ),
        productItemsCollection: buildCachePolicy(
          ['search', 'status', 'size'],
          'items',
          'ProductItem'
        ),
        batchItems: buildCachePolicy(['id'], 'items', 'ProductItem'),
        batchesCollection: buildCachePolicy(
          [
            'status',
            'batchNumber',
            'search',
            'startHolderUpdateDateTime',
            'endHolderUpdateDateTime',
            'sortOrder',
            'sortBy',
          ],
          'batches',
          'Batch'
        ),
        productItemBatchHistory: buildCachePolicy(['id'], 'batches', 'Batch'),
        orderBatches: buildCachePolicy(['id'], 'batches', 'Batch'),
        partnerOutlets: buildCachePolicy(['id'], 'outlets', 'Outlet'),
        usersCollection: buildCachePolicy(['role', 'search', 'status'], 'users', 'User'),
        consumersCollection: buildCachePolicy(['search', 'status'], 'users', 'Consumer'),
        boxesCollection: buildCachePolicy(
          [
            'search',
            'status',
            'minBattery',
            'maxBattery',
            'minItems',
            'maxItems',
            'startHeartbeatDateTime',
            'endHeartbeatDateTime',
            'startLastSignalDateTime',
            'endLastSignalDateTime',
            'fwVersion',
            'cityCode',
            'operationsInterval',
          ],
          'boxes',
          'Box'
        ),
        partnerEmployeesCollection: buildCachePolicy(
          ['outletId', 'partnerId'],
          'employees',
          'PartnerEmployee'
        ),
        consumerProductItemsCollection: buildCachePolicy(
          ['consumerId'],
          'consumerItems',
          'ConsumerProductItem'
        ),
        paymentOuts: buildCachePolicy(['status', 'search'], 'payments', 'PaymentOut'),
        consumerPaymentOuts: buildCachePolicy(['consumerId', 'status'], 'payments', 'PaymentOut'),
        productItemHistory: buildCachePolicy(['itemId'], 'itemActivities', 'ProductItemActivity'),
        boxActivity: buildCachePolicy(['boxId'], 'activityHistory', 'BoxActivity'),
        boxCountByStatuses: {
          keyArgs: [
            'search',
            'status',
            'minBattery',
            'maxBattery',
            'minItems',
            'maxItems',
            'startHeartbeatDateTime',
            'endHeartbeatDateTime',
            'startLastSignalDateTime',
            'endLastSignalDateTime',
            'fwVersion',
            'cityCode',
            'operationsInterval',
          ],
        },
        boxTasks: buildCachePolicy(['boxId'], 'tasks', 'BoxTask'),
        itemsHistory: buildCachePolicy([], 'cups', 'ItemsHistoryItem'),
        donationsCollection: buildCachePolicy(['status', 'organization'], 'donations', 'Donation'),
        consumerDonationsCollection: buildCachePolicy(
          ['status', 'organization', 'consumerId'],
          'donations',
          'Donation'
        ),
        invoicesCollection: buildCachePolicy(['params'], 'invoices', 'Invoice'),
        consumerLoyaltyOutlets: buildCachePolicy([], 'outlets', 'ConsumerLoyaltyOultet'),
        consumerLoyaltyOutletItems: buildCachePolicy(
          ['outletId'],
          'items',
          'ConsumerLoyaltyOutletItem'
        ),
        loyaltyCampaigns: buildCachePolicy(
          ['partnerId', 'status'],
          'rewardCampaigns',
          'CollectionLoyaltyCampaign'
        ),
        paidPlanRequestsCollection: buildCachePolicy(
          ['filters'],
          'paidPlanPartners',
          'PaidPlanRequest'
        ),
        boxRentals: buildCachePolicy(['filters'], 'inventories', 'BoxRental'),
        oneTimeContractSettingsCollection: buildCachePolicy(
          ['partnerId', 'outletId'],
          'contractSettings',
          'OneTimeContractSetting'
        ),
        containersCollection: buildCachePolicy(['filters'], 'containers', 'Container'),
        orderAttachedContainers: buildCachePolicy(['id'], 'containers', 'Container'),
        containerBatches: buildCachePolicy(['containerId', 'filters'], 'batches', 'Batch'),
        containerActivityHistory: buildCachePolicy(
          ['containerId'],
          'containerActivities',
          'ContainerActivity'
        ),
        groupLabelsCollection: buildCachePolicy(['search', 'status'], 'labels', 'GroupLabel'),
        labelsCollection: buildCachePolicy(
          ['search', 'partnerAddressId', 'status', 'groupLabelId'],
          'labels',
          'Label'
        ),
        addressesCollection: buildCachePolicy(['search'], 'partnerAddresses', 'Address'),
      },
    },
    ItemsHistoryItem: {
      keyFields: ['itemId', 'date'],
    },
    GeoCity: {
      keyFields: ['code'],
    },
    GeoCountry: {
      keyFields: ['code'],
    },
    ConsumerWWFAccount: {
      keyFields: ['wwfAccountId'],
    },
    CountryProductItemModelConfigurations: {
      keyFields: ['countryCode'],
    },
    CityProductItemModelConfigurations: {
      keyFields: ['cityCode'],
    },
    PartnerProductItemModelConfigurations: {
      keyFields: ['partnerId'],
    },
    OutletProductItemModelConfigurations: {
      keyFields: ['outletId'],
    },
    ConsumerLoyaltyOutletItem: {
      keyFields: ['id'],
    },
    ConsumerInfo: {
      keyFields: ['userId'],
    },
    BoxRentalConfiguration: {
      keyFields: ['id', 'countryCode'],
    },
    PartnerBoxRentalConfiguration: {
      keyFields: ['id', 'countryCode'],
    },
  },
})

export default cache
