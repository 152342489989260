import { Country, City, countryFields, cityFields } from './geo'

export type WorkingHours = {
  dayOfWeek: number
  openTime: string
  closeTime: string
}

export const workingHoursFields = `
  dayOfWeek
  openTime
  closeTime
`

export type Location = {
  id: string
  country: Country
  city: City
  timeZoneOffset: number
  cityCode: string
  name: string
  street: string
  houseNumber: string
  postalCode: string
  latitude: number
  longitude: number
  googlePlaceId: string | null
  photoLink: string | null
  photoPath: string | null
  workingHours: WorkingHours[]
}

export const locationFields = `
  id
  country {
    ${countryFields}
  },
  city {
    ${cityFields}
  },
  timeZoneOffset
  cityCode
  name
  street
  houseNumber
  postalCode
  latitude
  longitude
  googlePlaceId
  photoLink
  photoPath
  workingHours {
    ${workingHoursFields}
  }
`

export type EditableLocation = Location & {
  editable: boolean
}

export const editableLocationFields = `
  ${locationFields}
  editable
`

export type LocationCity = {
  code: string
  name: string
  latitude: number
  longitude: number
  country: string
}

export const locationCityFields = `
  code
  name
  latitude
  longitude
  country
`

export type LocationCountry = {
  code: string
  name: string
  enabledForLocation: boolean
  enabledForBilling: boolean
  cities: LocationCity[]
}

export const locationCountryFields = `
  code
  name
  enabledForLocation
  enabledForBilling
  cities {
    ${locationCityFields}
  }
`
