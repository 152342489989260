import { gql } from '@apollo/client'
import {
  ConsumerItemHistoryCount,
  ConsumerItemHistory,
  consumerItemHistoryCountFields,
  consumerItemHistoryFields,
  ConsumerItemEnvironmentalInfo,
  consumerItemEnvironmentalInfoFields,
  Campaign,
  campaignFields,
} from '@web-panel/api'

export type UserItemsCountDocumentInput = undefined

export type UserItemsCountDocumentResponse = {
  userItemsCount: {
    inUse: number
    returned: number
    losted: number
  }
}

export const UserItemsCountDocument = gql`
  query UserItemsCount {
    userItemsCount
      @rest(type: "UserItemsCount", path: "/operations-service/users/items/history/count") {
      inUse
      returned
      losted
    }
  }
`

export type TakeItemDocumentInput = {
  qr: string
  latitude?: number
  longitude?: number
}

export type TakeItemDocumentResponse = {
  takeItem: {
    campaignInfo: Campaign | null
    transferred: boolean
    outletId: string | null
  }
}

export const TakeItemDocument = gql`
  mutation TakeItem($qr: String!, $latitude: Float, $longitude: Float) {
    takeItem(qr: $qr, latitude: $latitude, longitude: $longitude, input: "empty")
      @rest(
        type: "TakeItemResponse"
        path: "/operations-service/users/items/take?qr={args.qr}"
        method: "PUT"
      ) {
      campaignInfo {
        ${campaignFields}
      }
      transferred
      outletId
    }
  }
`

export type ItemsHistoryDocumentInput = {
  limit: number
  offset?: number
}

export type ItemsHistoryDocumentResponse = {
  itemsHistory: {
    count: ConsumerItemHistoryCount
    cups: ConsumerItemHistory[]
  }
}

export const ItemsHistoryDocument = gql`
  query ItemsHistory($limit: Int!, $offset: Int) {
    itemsHistory(limit: $limit, offset: $offset) @rest(type: "ItemsHistory", path: "/operations-service/users/items/history?{args}") {
      count {
        ${consumerItemHistoryCountFields}
      }
      cups @type(name: "ItemsHistoryItem") {
        ${consumerItemHistoryFields}
      }
    }
  }
`

export type ItemsEnvironmentalInfoDocumentInput = undefined
export type ItemsEnvironmentalInfoDocumentResponse = {
  info: {
    itemEnvironmentalInfos: ConsumerItemEnvironmentalInfo[]
  }
}

export const ItemsEnvironmentalInfoDocument = gql`
  query ItemsEnvironmentalInfo {
    info @rest(type: "ItemsEnvironmentalInfo", path: "/operations-service/users/items/environmental/info") {
      itemEnvironmentalInfos {
        ${consumerItemEnvironmentalInfoFields}
      }
    }
  }
`
