import { FC } from 'react'
import { Typography, Button } from '@web-panel/shared'
import Lottie from 'lottie-react'

type ResultMessageProps = {
  animationData: unknown
  title: string
  message: string
  primaryButtonTitle?: string
  primaryButtonOnClick?: () => void
  secondaryButtonTitle?: string
  secondaryButtonOnClick?: () => void
}

export const ResultMessage: FC<ResultMessageProps> = ({
  animationData,
  title,
  message,
  primaryButtonTitle,
  primaryButtonOnClick,
  secondaryButtonTitle,
  secondaryButtonOnClick,
}) => {
  return (
    <div className="flex flex-col items-center">
      <div className="w-160">
        <Lottie animationData={animationData} loop={false} />
      </div>
      <Typography className="mt-24" variant="h2" color="primary">
        {title}
      </Typography>
      <Typography className="mt-12" variant="body1" align="center">
        {message}
      </Typography>
      {primaryButtonTitle && primaryButtonOnClick && (
        <div className="mt-12 w-full">
          <Button fullWidth variant="outlined" color="primary" onClick={primaryButtonOnClick}>
            {primaryButtonTitle}
          </Button>
        </div>
      )}
      {secondaryButtonTitle && secondaryButtonOnClick && (
        <Typography
          className="my-8"
          variant="body2"
          color="primary"
          sx={{ fontWeight: 600 }}
          align="center"
          onClick={secondaryButtonOnClick}
        >
          {secondaryButtonTitle}
        </Typography>
      )}
    </div>
  )
}
