import scanResult from './scan-result'
import cashbackLoyaltyReward from './cashback-loyalty-reward.svg'
import coins from './coins.svg'
import connectCup from './connect-cup.svg'
import cupTransferred from './cup-transferred.svg'
import globe from './globe.svg'
import goldenCupSmall from './golden-cup-small.svg'
import goldenCup from './golden-cup.svg'
import kookyBox from './kooky-box.svg'
import luckyCup from './lucky-cup.svg'
import physicalLoyaltyReward from './physical-loyalty-reward.svg'
import wwfLoyaltyReward from './wwf-loyalty-reward.svg'
import voucherLoyaltyReward from './voucher-loyalty-reward.svg'
import cupUsed from './cup-used.svg'
import cupReturned from './cup-returned.svg'
import cupLost from './cup-lost.svg'
import cupGhost from './cup-ghost.svg'
import cupProcessing from './cup-processing.svg'
import cupReturnedGolden from './cup-returned-golden.svg'
import cupReturnedLucky from './cup-returned-lucky.svg'

const illustrations = {
  scanResult,
  cashbackLoyaltyReward,
  coins,
  connectCup,
  cupTransferred,
  globe,
  goldenCupSmall,
  goldenCup,
  kookyBox,
  luckyCup,
  physicalLoyaltyReward,
  wwfLoyaltyReward,
  voucherLoyaltyReward,
  cupUsed,
  cupReturned,
  cupLost,
  cupGhost,
  cupProcessing,
  cupReturnedGolden,
  cupReturnedLucky,
} as const

export default illustrations
