import { FC } from 'react'
import { Navigate } from 'react-router-dom'

import { usePaymentMethods } from '@web-panel/consumer-api'

export const DonationIndexPage: FC = () => {
  const { data: paymentMethods, loading: loadingPaymentMethods } = usePaymentMethods()

  if (loadingPaymentMethods || !paymentMethods) return null

  const nextPage = paymentMethods.wwfAccount ? '/donation/amount' : '/donation/contact-information'

  return <Navigate to={nextPage} replace />
}
