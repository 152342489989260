import { FC, ReactElement } from 'react'
import { styled } from '@mui/material/styles'

const HeaderBackground = styled('div')(({ theme }) => ({
  zIndex: -1,
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  height: '7.2rem',
  background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
  backgroundSize: 'cover',
  pointerEvents: 'none',
}))

export type PageContentProps = {
  sidebar?: ReactElement
  noHeader?: boolean
}

export const PageContent: FC<PageContentProps> = ({ sidebar, noHeader = false, children }) => {
  return (
    <div className="relative px-24">
      <div className="flex flex-col items-center w-full flex-1">
        {!noHeader && <HeaderBackground />}
        <div className="md:flex md:flex-row max-w-screen-xl w-full">
          {sidebar && <div>{sidebar}</div>}
          <div className="pb-24 w-full overflow-auto">{children}</div>
        </div>
      </div>
    </div>
  )
}
