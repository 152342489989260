import { FC, useState, useEffect } from 'react'
import { useIntervalWhen } from 'rooks'
import { Loader } from '@local/components/Loader'
import { useChatCredentials } from '@web-panel/consumer-api'

const chatElementId = 'support-chat'

const AuthenticatedHubspotLoader: FC = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const { data } = useChatCredentials()
  useIntervalWhen(
    () => {
      if (!window.HubSpotConversations || !data) return
      window.hsConversationsSettings = {
        ...window.hsConversationsSettings,
        inlineEmbedSelector: `#${chatElementId}`,
        identificationEmail: data.email,
        identificationToken: data.token,
      }

      setIsLoaded(true)
    },
    50,
    !isLoaded
  )

  if (!isLoaded) return <Loader />

  return <>{children}</>
}

const PublicHubspotLoader: FC = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  useIntervalWhen(
    () => {
      if (!window?.HubSpotConversations?.widget) return
      window.hsConversationsSettings = {
        ...window.hsConversationsSettings,
        inlineEmbedSelector: `#${chatElementId}`,
      }
      setIsLoaded(true)
    },
    50,
    !isLoaded
  )

  if (!isLoaded) return <Loader />

  return <>{children}</>
}

const ChatComponent: FC = () => {
  useEffect(() => {
    window.HubSpotConversations?.widget.load()

    return () => {
      window.HubSpotConversations?.widget.remove()
    }
  }, [])

  return <div id={chatElementId} className="flex-1" />
}

type SupportChatProps = {
  authenticated: boolean
}

export const SupportChat: FC<SupportChatProps> = ({ authenticated }) => {
  if (authenticated) {
    return (
      <AuthenticatedHubspotLoader>
        <ChatComponent />
      </AuthenticatedHubspotLoader>
    )
  }

  return (
    <PublicHubspotLoader>
      <ChatComponent />
    </PublicHubspotLoader>
  )
}
