import { ProductItemType } from './product-item'

export type ConsumerItemEnvironmentalInfo = {
  type: ProductItemType
  co2EmissionsSavedValue: number
  co2EmissionsProducedValue: number
  co2EmissionsUnit: string
}

export const consumerItemEnvironmentalInfoFields = `
  type
  co2EmissionsSavedValue
  co2EmissionsProducedValue
  co2EmissionsUnit
`
