import { appLink, webLink, ibi, apn, isi, storeLinks } from '@local/constants'
import { getRefreshToken, getAccessToken } from '@web-panel/api'
import { detectBrowser } from './detect-browser'

async function addTokensToURL(url: URL) {
  try {
    const accessToken = await getAccessToken()
    const refreshToken = getRefreshToken()
    if (accessToken) url.searchParams.set('accessToken', accessToken)
    if (refreshToken) url.searchParams.set('refreshToken', refreshToken)
  } catch (err) {
    // Do nothing
  }
}

export async function getAppLink(path?: string, includeTokens = true): Promise<string> {
  const webUrl = new URL(webLink)
  if (path) webUrl.pathname = path ?? '/'
  if (includeTokens) await addTokensToURL(webUrl)

  const url = new URL(appLink)
  url.searchParams.set('link', webUrl.toString())
  url.searchParams.set('ibi', ibi)
  url.searchParams.set('apn', apn)
  url.searchParams.set('isi', isi)
  const browser = detectBrowser()
  if (browser === 'Safari') url.searchParams.set('efr', '1') // some magic number

  return url.toString()
}

export enum OS {
  ios = 'ios',
  android = 'android',
}

export function getOS(): OS {
  return navigator.userAgent.match(/iPhone|iPad|iPod/i) ? OS.ios : OS.android
}

export function getStoreLink() {
  const os = getOS()
  return storeLinks[os]
}
