import { BrowserQRCodeReader } from '@zxing/browser'
import { ScannerVisuals } from './ScannerVisuals'

type OnDetectCallack = (scan: string[]) => void

type ScannerConfig = {
  qrWidth: number
  qrHeight: number
  aspectRatio: number
  fps: number
  contentMatcher?: RegExp
  borderColor: string
  borderDetectedColor: string
}

const defaultConfig: ScannerConfig = {
  qrWidth: 250,
  qrHeight: 250,
  aspectRatio: 1,
  fps: 20,
  borderColor: 'white',
  borderDetectedColor: 'red',
}

export class Scanner {
  private containerElement: HTMLDivElement

  private videoElement: HTMLVideoElement

  private qrCodeReader: BrowserQRCodeReader

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private timeout: any = null

  private canvas: HTMLCanvasElement

  private canvasContext: CanvasRenderingContext2D

  private onDetectScan: OnDetectCallack

  private config: ScannerConfig = defaultConfig

  private visuals: ScannerVisuals

  private stream: MediaStream | null = null

  private stopped = false

  constructor(containerElement: HTMLDivElement, onDetectScan: OnDetectCallack)

  constructor(
    containerElement: HTMLDivElement,
    onDetectScan: OnDetectCallack,
    config: Partial<ScannerConfig>
  )

  constructor(
    containerElement: HTMLDivElement,
    onDetectScan: OnDetectCallack,
    config?: Partial<ScannerConfig>
  ) {
    this.containerElement = containerElement
    this.onDetectScan = onDetectScan
    this.qrCodeReader = new BrowserQRCodeReader()
    this.config = {
      ...this.config,
      ...(config ?? {}),
    }
    this.visuals = new ScannerVisuals(this.containerElement, {
      width: this.config.qrWidth,
      height: this.config.qrHeight,
      borderWidth: 3,
      borderColor: this.config.borderColor,
      borderDetectColor: this.config.borderDetectedColor,
    })

    this.videoElement = document.createElement('video')
    this.canvas = document.createElement('canvas')
    this.canvasContext = this.canvas.getContext('2d')!

    this.setStream()
  }

  private async setStream() {
    this.setVideoElement()

    try {
      this.stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: { facingMode: 'environment', aspectRatio: this.config.aspectRatio },
      })

      this.videoElement.srcObject = this.stream

      this.containerElement.append(this.videoElement)
      await this.videoElement.play()
      this.setCanvases()
      this.visuals.set()

      this.scheduleScan()
    } catch (err) {
      //
    }
  }

  private handleDetect(text: string | null) {
    if (this.stopped || !text) {
      this.visuals.setInactive()
      return
    }
    const matcher = this.config.contentMatcher

    if (!matcher) {
      this.visuals.setActive()
      this.onDetectScan([text])
      return
    }

    const match = text.match(matcher)
    if (!match) {
      this.visuals.setInactive()
      return
    }

    this.visuals.setActive()
    this.onDetectScan(match)
  }

  stop() {
    this.stopped = true
    this.videoElement.pause()
    clearTimeout(this.timeout)
    if (!this.stream) return
    const tracks = this.stream.getVideoTracks()
    tracks.forEach((track) => track.stop())
  }

  private setVideoElement() {
    this.videoElement.style.width = `${this.width()}px`
    this.videoElement.muted = true
    this.videoElement.setAttribute('muted', 'true')
    this.videoElement.playsInline = true
  }

  private setCanvases() {
    this.canvas.width = this.config.qrWidth
    this.canvas.height = this.config.qrHeight
  }

  private async drawImage() {
    const { videoWidth, videoHeight } = this.videoElement
    const widthRatio = videoWidth / this.width()
    const heightRatio = videoHeight / this.height()
    const videoOffsetX = this.scanAreaOffsetX() * widthRatio
    const videoOffsetY = this.scanAreaOffsetY() * heightRatio
    const videoScanWidth = this.config.qrWidth * widthRatio
    const videoScanHeight = this.config.qrHeight * heightRatio

    this.canvasContext.drawImage(
      this.videoElement,
      videoOffsetX,
      videoOffsetY,
      videoScanWidth,
      videoScanHeight,
      0,
      0,
      this.config.qrWidth,
      this.config.qrHeight
    )
  }

  private async invertImage() {
    const imageData = this.canvasContext.getImageData(
      0,
      0,
      this.config.qrWidth,
      this.config.qrHeight
    )

    for (let i = 0; i < imageData.data.length; i += 4) {
      imageData.data[i] = 255 - imageData.data[i]
      imageData.data[i + 1] = 255 - imageData.data[i + 1]
      imageData.data[i + 2] = 255 - imageData.data[i + 2]
      imageData.data[i + 3] = 255
    }
    this.canvasContext.putImageData(imageData, 0, 0)
  }

  private scheduleScan() {
    this.timeout = setTimeout(() => {
      this.scan()
    }, this.fpsTimeout())
  }

  private async scan() {
    this.drawImage()
    let result: string | null = null

    result = await this.attemptScan()

    if (!result) {
      this.invertImage()
      result = await this.attemptScan()
    }

    this.handleDetect(result)
    this.scheduleScan()
  }

  private async attemptScan(): Promise<string | null> {
    try {
      const result = await this.qrCodeReader.decodeFromCanvas(this.canvas)
      return result.getText()
    } catch (err) {
      return null
    }
  }

  private fpsTimeout() {
    return 1000 / this.config.fps
  }

  private width() {
    return this.containerElement.clientWidth
  }

  private height() {
    return this.config.aspectRatio * this.width()
  }

  private scanAreaOffsetX() {
    return (this.width() - this.config.qrWidth) / 2
  }

  private scanAreaOffsetY() {
    return (this.height() - this.config.qrHeight) / 2
  }
}
