import { FC } from 'react'
import { DialogContent, buildModal, ModalBodyProps } from '@web-panel/shared'
import {
  ConsumerLoyaltyOutletReward,
  RewardTemplateType,
  ConsumerLoyaltyOutlet,
} from '@web-panel/api'
import { CommonModalContent } from './CommonModalContent'
import { FreeCoffeeModalContent } from './FreeCoffeeModalContent'

type LoyaltyModalBodyProps = ModalBodyProps & {
  reward: ConsumerLoyaltyOutletReward
  outlet: ConsumerLoyaltyOutlet
  achieved: boolean
}

const LoyaltyModalBody: FC<LoyaltyModalBodyProps> = ({ reward, outlet, achieved }) => {
  if (reward.rewardType === RewardTemplateType.Physical)
    return (
      <DialogContent className="relative flex flex-col items-center pt-52 overflow-x-hidden">
        <FreeCoffeeModalContent reward={reward} outlet={outlet} achieved={achieved} />
      </DialogContent>
    )

  return (
    <DialogContent className="relative flex flex-col items-center pt-52 overflow-x-hidden">
      <CommonModalContent reward={reward} achieved={achieved} />
    </DialogContent>
  )
}

export const LoyaltyModal = buildModal(LoyaltyModalBody, {
  maxWidth: 'sm',
  classes: { container: 'items-end', paper: 'w-full m-0 rounded-b-none' },
})
