export enum CampaignTextType {
  Main = 'MAIN',
  ItemScanWin = 'ITEM_SCAN_WIN',
  ItemScanNoLuck = 'ITEM_SCAN_NO_LUCK',
}

export type CampaignText = {
  type: CampaignTextType
  title: string
  subTitle: string
  text: string
}

export const campaignTextFields = `
  type
  title
  subTitle
  text
`

export type Campaign = {
  id: string
  currencyCode: string
  amount: number
  endDate: string
  termsAndConditionsUrl: string
  campaignTexts: CampaignText[]
  priority: number
  code: string
}

export const campaignFields = `
  id
  currencyCode
  amount
  endDate
  termsAndConditionsUrl
  campaignTexts {
    ${campaignTextFields}
  }
  priority
  code
`

export type CampaignException = {
  campaignId: string
}

export const campaignExceptionFields = `
  campaignId
`
