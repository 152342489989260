declare const APPLICATION_BUILD_VERSION: string
declare const APPLICATION_ENV: string
declare const DATADOG_APPLICATION_ID: string | undefined
declare const DATADOG_CLIENT_TOKEN: string | undefined
declare const APPLICATION_TITLE_BRAND: string

export const ApplicationBuild = APPLICATION_BUILD_VERSION
export const ApplicationEnv = APPLICATION_ENV
export const datadogApplicationId = DATADOG_APPLICATION_ID
export const datadogClientToken = DATADOG_CLIENT_TOKEN
export const enabledFeatureFlags = (process.env.REACT_APP_FEATURE_FLAGS ?? '').split(',')
export const appLogo = process.env.REACT_APP_APPLICATION_LOGO ?? 'default'
export const applicationTitleBrand = APPLICATION_TITLE_BRAND
