import { FC } from 'react'
import { SnackbarProvider } from 'notistack'

export const NotificationProvider: FC = ({ children }) => {
  return (
    <SnackbarProvider
      autoHideDuration={3000}
      maxSnack={5}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{
        containerRoot: 'top-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99',
      }}
    >
      {children}
    </SnackbarProvider>
  )
}
