import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { QrCodeScannerUI } from '@local/components/QrCodeScannerUI'
import { useIntl } from 'react-intl'

const codeMatcher = /^https:\/\/.*\/cup\/([A-Za-z0-9]{6})$/

const OriginalScanPage: FC = () => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  const onSubmit = async (code: string) => {
    navigate(`/scan/cup/${code}`, { replace: true })
  }

  return (
    <QrCodeScannerUI
      title={formatMessage({ id: 'scan_title' })}
      codeMatcher={codeMatcher}
      onSubmit={onSubmit}
    />
  )
}

export default OriginalScanPage
