import { Currency, currencyFields } from './currency'

export type Country = {
  code: string
  name: string
}

export const countryFields = `
  code
  name
`

export type City = {
  code: string
  name: string
}

export const cityFields = `
  code
  name
`

export type GeoCountry = Country & {
  currency: Currency
  enabledForLocation: boolean
  enabledForBilling: boolean
}

export const geoCountryFields = `
  ${countryFields}
  currency {
    ${currencyFields}
  }
  enabledForLocation
  enabledForBilling
`

export enum GeoCityStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
  Coming = 'COMING',
}

export type GeoCity = City & {
  latitude: number
  longitude: number
  country: GeoCountry
  status: GeoCityStatus
  icon: string | null
}

export const geoCityFields = `
  ${cityFields}
  latitude
  longitude
  country {
    ${geoCountryFields}
  }
  status
  icon
`
