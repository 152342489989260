import { FC, Fragment } from 'react'
import { InfinitScroller } from '@web-panel/shared'
import { DownloadAppButtonSection } from '@local/components/DownloadAppButtonSection'
import { useItemsHistory } from '@web-panel/consumer-api'
import { ListItem } from './ListItem'

export const ItemsHistory: FC = () => {
  const { data: itemsHistory = [], loadMore } = useItemsHistory({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  return (
    <InfinitScroller onLoadMore={loadMore}>
      <div className="pb-100">
        {itemsHistory.map((item, id) => (
          <Fragment key={id}>
            {id === 2 && <DownloadAppButtonSection />}
            <ListItem data={item} />
          </Fragment>
        ))}
      </div>
    </InfinitScroller>
  )
}
