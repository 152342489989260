import { useQuery } from '@apollo/client'
import { QueryHook } from '@web-panel/api'
import {
  ConsumerWalletsDocument,
  ConsumerWalletsDocumentInput,
  ConsumerWalletsDocumentResponse,
} from '../docs'

export const useConsumerWallets: QueryHook<
  ConsumerWalletsDocumentInput,
  ConsumerWalletsDocumentResponse['consumerWallets']
> = (options) => {
  const { data, loading, refetch } = useQuery<ConsumerWalletsDocumentResponse>(
    ConsumerWalletsDocument,
    options
  )

  return {
    data: data?.consumerWallets,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}
