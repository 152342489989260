import { Currency, currencyFields } from './currency'
import { Country, countryFields } from './geo'

export enum ContractSettingConfigOwnerType {
  Partner = 'PARTNER',
  Outlet = 'OUTLET',
}

export enum ContractSettingConfigFrequency {
  Monthly = 'MONTHLY',
  PerOrder = 'PER_ORDER',
  OneTime = 'ONE_TIME',
}

export enum ContractSettingConfigTriggerUnit {
  Outlet = 'OUTLET',
  Device = 'DEVICE',
  Item = 'ITEM',
  Partner = 'PARTNER',
  Box = 'BOX',
  Dispenser = 'DISPENSER',
  Consumable = 'CONSUMABLE',
  Order = 'ORDER',
}

export enum ContractSettingConfigurationType {
  Predefined = 'PREDEFINED',
  Custom = 'CUSTOM',
}

export type ContractSettingConfig = {
  id: string
  ownerType: ContractSettingConfigOwnerType
  frequency: ContractSettingConfigFrequency
  triggerUnit: ContractSettingConfigTriggerUnit
  configurationType: ContractSettingConfigurationType
  name: string
  description: string
  features: ContractSettingFeatures[]
}

export const contractSettingConfigFields = `
  id
  ownerType
  frequency
  triggerUnit
  configurationType
  name
  description
  features
`

export enum ContractPackageCode {
  Custom = 'CUSTOM',
  Small = 'S',
  Medium = 'M',
  Large = 'L',
  Default = 'DEFAULT',
}

export type ContractPackageGroup = {
  businessCategoryId: string
  contractPackageCode: ContractPackageCode
}

export const contractPackageGroupFields = `
  businessCategoryId
  contractPackageCode
`

export enum ContractSettingType {
  RegularFee = 'REGULAR_FEE',
  ExceedingAmountFee = 'EXCEEDING_AMOUNT_FEE',
  LostFee = 'LOST_FEE',
}

type Category = {
  id: string
  code: string
  description: string
}

export type ContractPackageBusinessCategory = Category & {
  parentCategory: Category
}

export type ContractSettingFeatures = {
  id: string
  name: string
  description: string
}

const categoryFields = `
  id
  code
  description
`

export const contractPackageBusinessCategoryFields = `
  ${categoryFields}
  parentCategory {
    ${categoryFields}
  }
`

export type ContractPackageSetting = {
  id: string
  price: number
  currency: Currency
  countryCode: Country
  amount: number
  name: string
  description: string
  settingType: ContractSettingType
  contractPackageCode: ContractPackageCode
  contractSettingConfig: ContractSettingConfig
  category: ContractPackageBusinessCategory
}

export const contractPackageSettingFields = `
  id
  price
  currency {
    ${currencyFields}
  }
  countryCode {
    ${countryFields}
  }
  amount
  name
  description
  settingType
  contractPackageCode
  contractSettingConfig {
    ${contractSettingConfigFields}
  }
  category {
    ${contractPackageBusinessCategoryFields}
  }
`

export type ContractPartnerSetting = {
  id: string
  partnerId: string
  outletId: string
  price: number
  currency: Currency
  countryCode: Country
  amount: number
  name: string
  description: string
  settingType: ContractSettingType
  contractSettingConfig: ContractSettingConfig
}

export const contractPartnerSettingFields = `
  id
  partnerId
  outletId
  price
  currency {
    ${currencyFields}
  }
  countryCode {
    ${countryFields}
  }
  amount
  name
  description
  settingType
  contractSettingConfig {
    ${contractSettingConfigFields}
  }
`

export const featureContractPartnerSettingFields = `
  price
  currencyCode {
    ${currencyFields}
  }
  countryCode {
    ${countryFields}
  }
  amount
  name
  description
  settingType
  contractSettingConfig {
    ${contractSettingConfigFields}
  }
`

export type ContractPartnerSettingConfigInput = {
  price: number
  currencyCode: string
  countryCode: string
  amount: number
  name: string
  description: string
  settingType: ContractSettingType
  contractSettingConfigId: string
}

export enum BusinessCategoryType {
  Bar = 'BAR',
  CoffeeShop = 'COFFEE_SHOP',
  Restaurant = 'RESTAURANT',
  Cantine = 'CANTINE',
  Hotel = 'HOTEL',
  Takeaway = 'TAKEAWAY',
  Bakery = 'BAKERY',
  Office = 'OFFICE',
  B2B = 'B2B',
  B2B2C = 'B2B2C',
}

export enum BusinessCategoryGroupType {
  Main = 'MAIN',
  All = 'ALL',
  Sub = 'SUB',
}

export type BusinessCategory = {
  id: string
  code: string
  description: string
  category: BusinessCategoryType
  parentCategory: string | null
}

export const businessCategoryFields = `
  id
  code
  description
  category
  parentCategory
`

export enum OneTimeContractSettingStatus {
  Billed = 'BILLED',
  NotBilled = 'NOT_BILLED',
}

export enum OneTimeContractSettingType {
  RegularFee = 'REGULAR_FEE',
  ExceedingAmountFee = 'EXCEEDING_AMOUNT_FEE',
  LostFee = 'LOST_FEE',
  ExpressDeliveryFee = 'EXPRESS_DELIVERY_FEE',
}

export type OneTimeContractSetting = {
  id: string
  partnerId: string
  outletId: string
  status: OneTimeContractSettingStatus
  validationDate: string
  price: number
  amount: number
  currency: Currency
  countryCode: Country
  name: string
  description: string
  settingType: OneTimeContractSettingType
  contractSettingConfig: ContractSettingConfig
}

export const oneTimeContractSettingFields = `
  id
  partnerId
  outletId
  status
  validationDate
  price
  amount
  currency {
    ${currencyFields}
  }
  countryCode {
    ${countryFields}
  }
  name
  description
  settingType
  contractSettingConfig {
    ${contractSettingConfigFields}
  }
`
