import { FC } from 'react'
import { styled } from '@mui/material/styles'

type BannerColor = 'primary' | 'error' | 'secondary' | 'info'
const BannerContainer = styled('div')<{ color: BannerColor }>(({ theme, color }) => ({
  backgroundColor: theme.palette[color].main,
  color: theme.palette[color].contrastText,
}))

type BannerProps = {
  color: BannerColor
}

export const Banner: FC<BannerProps> = ({ color, children }) => {
  return (
    <BannerContainer color={color}>
      <div className="flex flex-col flex-auto min-h-0 relative z-10">
        <div className="flex flex-col w-full flex-auto items-center">
          <div className="flex flex-col flex-auto w-full px-24 py-10">{children}</div>
        </div>
      </div>
    </BannerContainer>
  )
}
