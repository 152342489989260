import { FC } from 'react'
import { useIntersectionObserverRef } from 'rooks'

type InfinitScrollerProps = {
  onLoadMore: () => void
}

export const InfinitScroller: FC<InfinitScrollerProps> = ({ onLoadMore, children }) => {
  const [markerRef] = useIntersectionObserverRef((entries) => {
    const targetEntry = entries[0]
    if (targetEntry && targetEntry.isIntersecting) onLoadMore()
  })

  return (
    <>
      {children}
      <div className="w-full h-0.5" ref={markerRef} />
    </>
  )
}
