import { FC } from 'react'
import { useIntl } from 'react-intl'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useNotifications } from '@web-panel/shared'
import PageHeader from '@local/components/PageHeader'
import { PromoCampaingWelcomeCard } from '@local/components/PromoCampaign/WelcomeCard'
import { useRequestEmailSecurityCode } from '@web-panel/consumer-api'
import SignInForm, { FormFields, defaultValues, validationSchema } from './Form'
import { setMemoizedEmail } from './helpers/memoized-email'

const SignInPage: FC = () => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { request: requestSecurityCode } = useRequestEmailSecurityCode()
  const { pushNotification } = useNotifications()

  const onSubmit = async ({ email }: FormFields) => {
    try {
      await requestSecurityCode({ email })
      setMemoizedEmail(email)
      navigate('check-inbox', { state: { email } })
    } catch (err) {
      pushNotification('error', 'We can not log you in at the moment. Please try again later.')
    }
  }

  return (
    <div>
      <PageHeader
        htmlTitle={formatMessage({ id: 'web_login_title' })}
        title={formatMessage({ id: 'web_login_title' })}
      />
      <div className="mt-7">
        <Formik<FormFields>
          initialValues={defaultValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <SignInForm />
        </Formik>
        <PromoCampaingWelcomeCard className="mt-44" />
      </div>
    </div>
  )
}

export default SignInPage
