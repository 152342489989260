import { useSnackbar } from 'notistack'
import { v4 as uuid } from 'uuid'
import Notification from '../components/Notification'
import { NotificationVariant } from '../components/Notification/types'

type NotificationOptions = {
  persist?: boolean
  onClick?: () => void
}

const variantDurations: Record<NotificationVariant, number> = {
  error: 5000,
  success: 3000,
  warning: 3000,
  info: 5000,
}

export const useNotifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const closeNotification = (id: string) => {
    return closeSnackbar(id)
  }

  const pushNotification = (
    variant: NotificationVariant,
    message: string,
    options: NotificationOptions = {}
  ) => {
    const item = {
      id: uuid(),
      message,
      variant,
    }

    enqueueSnackbar(message, {
      key: item.id,
      persist: options.persist ?? false,
      autoHideDuration: variantDurations[variant],
      content: () => (
        <Notification onClick={options.onClick} item={item} onClose={closeNotification} />
      ),
    })

    return item.id
  }

  return {
    pushNotification,
    closeNotification,
  }
}
