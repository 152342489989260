import { getAppLogo } from '@web-panel/shared'
import defaultLogo from '@local/assets/images/logo.svg'
import neomLogo from '@local/assets/images/logo-neom.svg'

const logoSetting = getAppLogo()

const mapping = {
  default: {
    asset: defaultLogo,
    size: 19,
  },
  neom: {
    asset: neomLogo,
    size: 16,
  },
} as const

type LogoName = keyof typeof mapping

const appLogoName: LogoName = Object.keys(mapping).includes(logoSetting)
  ? (logoSetting as LogoName)
  : 'default'
export const appLogo = mapping[appLogoName].asset
export const appLogoSize = mapping[appLogoName].size
