import { ThemeOptions } from '@mui/material/styles'

let registeredTheme: ThemeOptions = {}

export function registerTheme(theme: ThemeOptions) {
  registeredTheme = theme
}

export function getRegisteredTheme(): ThemeOptions {
  return registeredTheme
}
