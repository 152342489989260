import { FC } from 'react'
import { Typography } from '@web-panel/shared'
import { FormattedMessage } from 'react-intl'
import { useConsumerWallets } from '@web-panel/consumer-api'

export const WalletStatus: FC = () => {
  const { data: wallets = [] } = useConsumerWallets({ fetchPolicy: 'cache-and-network' })
  const wallet = wallets[0]

  if (!wallet) return null

  const labelsColor = wallet.availableAmount > 0 ? 'primary' : 'error'

  return (
    <div className="flex flex-row items-center">
      <div className="text-right">
        <Typography variant="subtitle2" color={labelsColor}>
          <FormattedMessage id="available_cups" />
        </Typography>
        <div>
          <Typography color={labelsColor} className="inline" variant="body2" fontWeight="700">
            {wallet.availableAmount}/{Math.abs(wallet.minimalAmount)}
          </Typography>
        </div>
      </div>
    </div>
  )
}
