import { gql } from '@apollo/client'

type SecurityCode = {
  securityCode: number
  success: boolean
}

const securityCodeFields = `
  securityCode
  success
`

type AuthTokens = {
  id: string
  accessToken: string
  refreshToken: string
  token: string
}

const authTokensFields = `
  id
  accessToken
  refreshToken
  token
`

export type RequestSMSSecurityCodeDocumentInput = {
  input: {
    phone: string
  }
}
export type RequestSMSSecurityCodeDocumentResponse = {
  requestSecurityCode: SecurityCode
}
export const RequestSMSSecurityCodeDocument = gql`
  mutation RequestSecurityCode($input: any) {
    requestSecurityCode(input: $input)
      @rest(type: "SecurityCode", path: "/auth/verification/phone/start", method: "POST") {
      ${securityCodeFields}
    }
  }
`

export type RequestSMSAuthTokensDocumentInput = {
  input: {
    phone: string
    code: number
    securityCode: number
  }
}
export type RequestSMSAuthTokensDocumentResponse = {
  requestAuthTokens: AuthTokens
}
export const RequestSMSAuthTokensDocument = gql`
  mutation RequestAccessTokens($input: any) {
    requestAuthTokens(input: $input)
      @rest(type: "AccessTokens", path: "/api/Authentication/verification/code", method: "POST") {
      ${authTokensFields}
    }
  }
`

export type RequestEmailSecurityCodeDocumentInput = {
  input: {
    email: string
  }
}
export type RequestEmailSecurityCodeDocumentResponse = void
export const RequestEmailSecurityCodeDocument = gql`
  mutation RequestEmailSecurityCode($input: any) {
    requestEmailSecurityCode(input: $input)
      @rest(type: "NoCache", path: "/auth/verification/email/start", method: "POST") {
      NoCache
    }
  }
`

export type RequestEmailAuthTokensDocumentInput = {
  input: {
    email: string
    token: number
  }
}
export type RequestEmailAuthTokensDocumentResponse = {
  requestEmailAuthTokens: AuthTokens
}
export const RequestEmailAuthTokensDocument = gql`
  mutation RequestEmailAuthTokens($input: any) {
    requestEmailAuthTokens(input: $input)
      @rest(type: "AccessTokens", path: "/auth/verification/email/token", method: "POST") {
      ${authTokensFields}
    }
  }
`
