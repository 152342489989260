import { FC } from 'react'
import { useIntl } from 'react-intl'
import { NavItem, NavigationItemData } from './NavItem'
import { NavbarSectionTitle } from './NavbarSectionTitle'

export type NavigationSectionData = {
  translationKey?: string
  routes: NavigationItemData[]
}

type NavSectionProps = {
  showSectionTitle?: boolean
  section: NavigationSectionData
  onItemClick?: () => void
}

export const NavSection: FC<NavSectionProps> = ({ section, onItemClick }) => {
  const { formatMessage } = useIntl()
  const { translationKey, routes } = section
  const title = translationKey && formatMessage({ id: translationKey })

  return (
    <>
      {title ? <NavbarSectionTitle title={title} /> : <div className="mt-32" />}
      {routes.map((route, key) => (
        <NavItem key={key} item={route} onClick={onItemClick} />
      ))}
    </>
  )
}
