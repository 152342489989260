import { FC } from 'react'
import { Typography, TextField, Button, Autocomplete } from '@web-panel/shared'
import { useCountries } from '@web-panel/api'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import {
  usePaymentMethods,
  useCreateWWFAccount,
  useDeleteWWFAccount,
} from '@web-panel/consumer-api'

type FormFields = {
  firstName: string
  lastName: string
  address: string
  postalCode: string
  city: string
  countryCode: string
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  address: Yup.string().required(),
  postalCode: Yup.string().required(),
  city: Yup.string().required(),
  countryCode: Yup.string().required(),
})

export const DonationsContactInformationPage: FC = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { data: paymentMethods, loading: loadingPaymentMethods } = usePaymentMethods()
  const { data: countries = [], loading: loadingCountries } = useCountries()
  const { request: createAccount, loading: loadingCreateAccount } = useCreateWWFAccount()
  const { request: deleteAccount, loading: loadingDeleteAccount } = useDeleteWWFAccount()

  if (!paymentMethods || loadingPaymentMethods || loadingCountries) return null

  const { wwfAccount } = paymentMethods

  const navigateNext = () => navigate('/donation/amount', { replace: true })

  const onSubmit = async (values: FormFields) => {
    try {
      if (wwfAccount) {
        await deleteAccount({ id: wwfAccount.wwfAccountId })
      }
      await createAccount({
        input: {
          firstName: values.firstName,
          lastName: values.lastName,
          address: values.address,
          postalCode: values.postalCode,
          city: values.city,
          countryCode: values.countryCode,
        },
      })
      navigateNext()
    } catch (err) {
      //
    }
  }

  const initialValues: FormFields = {
    firstName: wwfAccount?.firstName ?? '',
    lastName: wwfAccount?.lastName ?? '',
    address: wwfAccount?.address ?? '',
    postalCode: wwfAccount?.postalCode ?? '',
    city: wwfAccount?.city ?? '',
    countryCode: wwfAccount?.country.code ?? '',
  }

  const getCountryName = (countryCode: string) => {
    return countries.find((country) => country.code === countryCode)?.name ?? ''
  }

  return (
    <Formik<FormFields>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, handleChange, setFieldValue, dirty, isValid, handleSubmit }) => (
        <div>
          <Typography variant="h4" color="primary">
            <FormattedMessage id="contact_information" />
          </Typography>
          <div className="grid grid-cols-2 gap-12 mt-32">
            <TextField
              label={formatMessage({ id: 'bank_transfer_first_name' })}
              value={values.firstName}
              error={!!errors.firstName}
              onChange={handleChange('firstName')}
            />
            <TextField
              label={formatMessage({ id: 'bank_transfer_last_name' })}
              value={values.lastName}
              error={!!errors.lastName}
              onChange={handleChange('lastName')}
            />
          </div>
          <div className="mt-36">
            <TextField
              fullWidth
              label={formatMessage({ id: 'bank_transfer_address' })}
              value={values.address}
              error={!!errors.address}
              onChange={handleChange('address')}
            />
          </div>
          <div className="grid grid-cols-2 gap-12 mt-32">
            <TextField
              label={formatMessage({ id: 'bank_transfer_zip' })}
              value={values.postalCode}
              error={!!errors.postalCode}
              onChange={handleChange('postalCode')}
            />
            <TextField
              label={formatMessage({ id: 'bank_transfer_city' })}
              value={values.city}
              error={!!errors.city}
              onChange={handleChange('city')}
            />
          </div>
          <div className="mt-32">
            <Autocomplete
              disablePortal
              fullWidth
              options={countries.map(({ code }) => code)}
              value={values.countryCode}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors.countryCode}
                  label={formatMessage({ id: 'bank_transfer_country' })}
                />
              )}
              getOptionLabel={getCountryName}
              onChange={(_data, val) => setFieldValue('countryCode', val)}
            />
          </div>
          <Button
            loading={loadingCreateAccount || loadingDeleteAccount}
            className="mt-40"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!isValid}
            onClick={() => (dirty ? handleSubmit() : navigateNext())}
          >
            <FormattedMessage id="bank_transfer_next" />
          </Button>
        </div>
      )}
    </Formik>
  )
}
