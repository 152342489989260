import { FC } from 'react'
import {
  DialogContent,
  DialogActions,
  Button,
  Typography,
  buildModal,
  ModalBodyProps,
} from '@web-panel/shared'
import { useAuthContext } from '@web-panel/api'
import { FormattedMessage } from 'react-intl'
import { getAppLink } from '@local/helpers'

const SettingsModal: FC<ModalBodyProps> = () => {
  const { signout } = useAuthContext()

  const onDownloadApp = async () => {
    const link = await getAppLink()
    window.open(link, '_blank')
  }

  return (
    <>
      <DialogContent>
        <Typography variant="body1">
          <FormattedMessage id="settings_modal_text" />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={onDownloadApp}>
          <FormattedMessage id="settings_modal_download_button" />
        </Button>
        <Button size="small" onClick={signout}>
          <FormattedMessage id="settings_modal_logout_button" />
        </Button>
      </DialogActions>
    </>
  )
}

export default buildModal(SettingsModal, { fullWidth: true })
