import { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { CollectionQueryHook, QueryHook, MutationHook } from '@web-panel/api'
import { defaultCollectionVariables } from '../defaults'
import {
  UserItemsCountDocument,
  UserItemsCountDocumentInput,
  UserItemsCountDocumentResponse,
  TakeItemDocument,
  TakeItemDocumentInput,
  TakeItemDocumentResponse,
  ItemsHistoryDocument,
  ItemsHistoryDocumentResponse,
  ItemsEnvironmentalInfoDocument,
  ItemsEnvironmentalInfoDocumentInput,
  ItemsEnvironmentalInfoDocumentResponse,
} from '../docs'

export const useUserItemsCount: QueryHook<
  UserItemsCountDocumentInput,
  UserItemsCountDocumentResponse['userItemsCount']
> = (options) => {
  const { data, loading, refetch } = useQuery<UserItemsCountDocumentResponse>(
    UserItemsCountDocument,
    options
  )

  return {
    data: data?.userItemsCount,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export enum UseTakeItemResultCode {
  Successful = 200,
  AlreadyTaken = 51001,
  AlreadyTakenLost = 51002,
  TakenByAnotherUser = 51003,
  CanNotBeTaken = 51004,
  NotFound = 5007,
}

export type UseTakeItemResult = {
  code: UseTakeItemResultCode
  data: TakeItemDocumentResponse['takeItem'] | null
}

export const useTakeItem: MutationHook<TakeItemDocumentInput, UseTakeItemResult> = () => {
  const [execute, { loading }] = useMutation<TakeItemDocumentResponse>(TakeItemDocument, {
    errorPolicy: 'all',
  })

  async function request(variables: TakeItemDocumentInput): Promise<UseTakeItemResult> {
    try {
      const { data } = await execute({ variables })

      return {
        code: UseTakeItemResultCode.Successful,
        data: data?.takeItem ?? null,
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const { errorCode } = err?.networkError?.result ?? {
        errorCode: UseTakeItemResultCode.CanNotBeTaken,
      }

      return {
        code: errorCode as UseTakeItemResultCode,
        data: null,
      }
    }
  }

  return {
    request,
    loading,
  }
}

export const useItemsHistory: CollectionQueryHook<
  undefined,
  ItemsHistoryDocumentResponse['itemsHistory']['cups']
> = (options) => {
  const [offset, setOffset] = useState(0)
  const variables = {
    ...defaultCollectionVariables,
  }

  const { data, loading, fetchMore, refetch } = useQuery<ItemsHistoryDocumentResponse>(
    ItemsHistoryDocument,
    { ...options, variables }
  )

  const hasMore = (data?.itemsHistory.cups.length ?? 0) >= offset + defaultCollectionVariables.limit

  const loadMore = async () => {
    if (loading || !data || !hasMore) return

    await fetchMore({
      variables: {
        ...variables,
        offset: offset + defaultCollectionVariables.limit,
      },
    })

    setOffset(offset + defaultCollectionVariables.limit)
  }

  return {
    data: data?.itemsHistory.cups,
    loading,
    loadMore,
    hasMore,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useItemsEnvironmentalInfo: QueryHook<
  ItemsEnvironmentalInfoDocumentInput,
  ItemsEnvironmentalInfoDocumentResponse['info']['itemEnvironmentalInfos']
> = (options) => {
  const { data, loading, refetch } = useQuery<ItemsEnvironmentalInfoDocumentResponse>(
    ItemsEnvironmentalInfoDocument,
    options
  )

  return {
    data: data?.info.itemEnvironmentalInfos,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}
