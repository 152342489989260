import { VFC } from 'react'
import { Typography, TextField, Button, FormControlLabel, Checkbox } from '@web-panel/shared'
import { useFormikContext } from 'formik'
import { FormattedMessage } from 'react-intl'
import * as Yup from 'yup'
import { termsLink, privacyPolicyLink } from '@local/constants'

export type FormFields = {
  email: string
  accepted: boolean
}

export const defaultValues: FormFields = {
  email: '',
  accepted: false,
}

export const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  accepted: Yup.boolean().oneOf([true]),
})

const SignInForm: VFC = () => {
  const { values, handleChange, handleSubmit, isValid, dirty, isSubmitting } =
    useFormikContext<FormFields>()

  const aggrementMessage = (
    <>
      <FormattedMessage id="login_agree_to_kooky" />
      <a target="_blank" href={termsLink} rel="noreferrer">
        <FormattedMessage id="login_tos" />
      </a>
      <FormattedMessage id="login_and" />
      <a target="_blank" href={privacyPolicyLink} rel="noreferrer">
        <FormattedMessage id="login_pp" />
      </a>
    </>
  )

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="body1">We need your email to send you a magic login link.</Typography>
      <div className="flex flex-row mt-16">
        <TextField
          fullWidth
          type="email"
          autoComplete="email"
          label="Email address"
          value={values.email}
          onChange={handleChange('email')}
        />
      </div>
      <FormControlLabel
        className="mt-8"
        label={aggrementMessage}
        control={<Checkbox checked={values.accepted} onChange={handleChange('accepted')} />}
      />
      <Button
        loading={isSubmitting}
        disabled={!isValid || !dirty}
        fullWidth
        className="mt-24"
        variant="contained"
        onClick={() => handleSubmit()}
      >
        <FormattedMessage id="login_cta" />
      </Button>
    </form>
  )
}

export default SignInForm
