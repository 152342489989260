import { FC } from 'react'

type NavbarComponent = 'header' | 'customComponent'

type Components = Record<NavbarComponent, FC | null>

let components: Components = {
  header: null,
  customComponent: null,
}

export function registerNavbarComponent(name: NavbarComponent, component: FC) {
  components[name] = component
}

export function clearNavbarComponentRegistrations() {
  components = {
    header: null,
    customComponent: null,
  }
}

export function getNavbarComponent(name: NavbarComponent) {
  return components[name]
}
