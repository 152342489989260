import { useMutation } from '@apollo/client'
import { MutationHook } from '@web-panel/api'
import {
  CreateDonationDocument,
  CreateDonationDocumentInput,
  CreateDonationDocumentResponse,
} from '../docs'

export const useCreateDonation: MutationHook<
  CreateDonationDocumentInput,
  CreateDonationDocumentResponse
> = () => {
  const [execute, { loading }] = useMutation(CreateDonationDocument)

  async function request(variables: CreateDonationDocumentInput) {
    const { data } = await execute({
      variables,
      context: {
        apiVersion: '2.0',
      },
    })
    if (!data) throw new Error('Could not create donation')

    return data.createDonation
  }

  return {
    request,
    loading,
  }
}
