import { FC } from 'react'
import { styled, Typography, useFormatDate } from '@web-panel/shared'
import { useGoldenCupCampaign } from '@local/hooks/golden-cup-hooks'
import { useIntl } from 'react-intl'
import illustrations from '@local/assets/illustrations'

const Card = styled('div')<{ background: string }>(({ theme, background }) => ({
  borderRadius: '12px',
  background,
  padding: '1.2rem',
}))

type GoldenCupCardProps = {
  className?: string
}

type CampaignCardProps = GoldenCupCardProps & {
  background: string
  title: string
  description: string
  illustration: string
  termsLink?: string
  termsLinkTitle?: string
}

export const CampaignCard: FC<CampaignCardProps> = ({
  className,
  background,
  title,
  description,
  illustration,
  termsLink,
  termsLinkTitle,
}) => {
  return (
    <Card className={className} background={background}>
      <div className="flex flex-row items-center">
        <img className="w-72" src={illustration} alt="golden cup" />
        <div className="flex flex-col">
          <Typography
            className="mb-4"
            variant="body1"
            color="primary.main"
            sx={{ fontWeight: 600 }}
          >
            {title}
          </Typography>
          <Typography className="mb-12" variant="caption" color="secondary.main">
            {description}
          </Typography>
          {termsLink && termsLinkTitle && (
            <a target="participation-terms" href={termsLink}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                {termsLinkTitle}
              </Typography>
            </a>
          )}
        </div>
      </div>
    </Card>
  )
}

export const PromoCampaingWelcomeCard: FC<GoldenCupCardProps> = ({ className }) => {
  const formatDate = useFormatDate()
  const { formatMessage } = useIntl()
  const { goldenCupCampaign: campaign } = useGoldenCupCampaign()

  if (!campaign) return null

  return (
    <CampaignCard
      className={className}
      background="#FDF5DF"
      title={formatMessage(
        { id: 'golden_cup_auth_card_title' },
        { amount: campaign.amount, currency: campaign.currencyCode }
      )}
      description={formatMessage(
        { id: 'golden_cup_auth_card_description' },
        { date: formatDate('human-date', campaign.endDate) }
      )}
      illustration={illustrations.goldenCup}
      termsLink={campaign.termsAndConditionsUrl}
      termsLinkTitle={formatMessage({ id: 'golden_cup_auth_card_participation_terms_link' })}
    />
  )
}
