import { FC, MouseEventHandler } from 'react'
import { useNavigate, useLocation, matchPath } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { NavbarSectionItem } from './NavbarSectionItem'

export type NavigationItemData = {
  icon: string
  translationKey: string
  badge?: FC
  path: string
}

type NavItemProps = {
  item: NavigationItemData
  onClick?: () => void
}

export const NavItem: FC<NavItemProps> = ({ item, onClick }) => {
  const navigate = useNavigate()
  const currentLocation = useLocation()
  const { formatMessage } = useIntl()

  const handleClick: MouseEventHandler = (e) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(`${window.location.protocol}//${window.location.host}${item.path}`)
    } else navigate(item.path)
    if (onClick) onClick()
  }

  const active = !!matchPath({ path: item.path, end: true }, currentLocation.pathname)

  return (
    <NavbarSectionItem
      active={active}
      icon={item.icon}
      title={formatMessage({ id: item.translationKey })}
      badge={item.badge}
      onClick={handleClick}
    />
  )
}
