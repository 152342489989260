export type ScannerVisualsConfig = {
  width: number
  height: number
  borderWidth: number
  borderColor: string
  borderDetectColor: string
}

export class ScannerVisuals {
  private containerElement: HTMLDivElement

  private config: ScannerVisualsConfig

  private top = 0

  private left = 0

  private scanBox: HTMLDivElement

  constructor(containerElement: HTMLDivElement, config: ScannerVisualsConfig) {
    this.containerElement = containerElement
    this.config = config
    this.scanBox = document.createElement('div')
  }

  set() {
    this.top = (this.containerElement.clientHeight - this.config.width) / 2
    this.left = (this.containerElement.clientWidth - this.config.height) / 2
    const right = this.left + this.config.width
    const bottom = this.top + this.config.height

    this.setShade(0, 0, this.containerElement.clientWidth, this.top)
    this.setShade(this.top, 0, this.left, this.containerElement.clientHeight - this.top)
    this.setShade(
      this.top,
      right,
      this.containerElement.clientWidth - right,
      this.containerElement.clientHeight - this.top
    )
    this.setShade(
      bottom,
      this.left,
      this.containerElement.clientWidth - this.left * 2,
      this.containerElement.clientHeight - bottom
    )
    this.setScanArea()
  }

  private setScanArea() {
    this.scanBox.style.position = 'absolute'
    this.scanBox.style.width = `${this.config.width}px`
    this.scanBox.style.height = `${this.config.height}px`
    this.scanBox.style.left = `${this.left}px`
    this.scanBox.style.top = `${this.top}px`
    this.setInactive()
    this.containerElement.append(this.scanBox)
  }

  private setShade(top: number, left: number, width: number, height: number) {
    const shade = document.createElement('div')
    shade.style.position = 'absolute'
    shade.style.backgroundColor = '#000000'
    shade.style.left = `${left}px`
    shade.style.top = `${top}px`
    shade.style.width = `${width}px`
    shade.style.height = `${height}px`
    shade.style.opacity = '50%'
    this.containerElement.append(shade)
  }

  setInactive() {
    this.scanBox.style.border = `${this.config.borderWidth}px solid ${this.config.borderColor}`
  }

  setActive() {
    this.scanBox.style.border = `${this.config.borderWidth}px solid ${this.config.borderDetectColor}`
  }
}
