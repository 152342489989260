import { Country, countryFields } from './geo'

export type BankAccount = {
  id: string
  bankAccountId: string
  country: Country
  iban: string
  bic: string
  city: string
  postalCode: string
  address: string
  firstName: string
  lastName: string
}

export const bankAccountFields = `
  id
  bankAccountId
  country {
    ${countryFields}
  }
  iban
  bic
  city
  postalCode
  address
  firstName
  lastName
`

export type CreditCard = {
  id: string
  creditCardId: string
  cardNumber: string
}

export const creditCardFields = `
  id
  creditCardId
  cardNumber
`

export enum PaymentType {
  BankAccount = 'BANK_ACCOUNT',
  CreditCard = 'CREDIT_CARD',
}
