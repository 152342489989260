import { FC, useEffect } from 'react'
import { useAuthContext } from '@web-panel/api'
import { useLocale } from '@web-panel/shared'
import { useLocation } from 'react-router-dom'
import { useSessionstorage } from 'rooks'

export const DonationsLayout: FC = ({ children }) => {
  const { search } = useLocation()
  const { authenticated, signin } = useAuthContext()
  const { setLocale } = useLocale()
  const { set: setWalletId } = useSessionstorage('walletId', '')

  const searchParams = new URLSearchParams(search)
  const accessToken = searchParams.get('accessToken')
  const lang = searchParams.get('lang')
  const walletId = searchParams.get('walletId')

  useEffect(() => {
    if (accessToken) signin({ accessToken, refreshToken: '' })
    if (lang) setLocale(lang)
    if (walletId) setWalletId(walletId)
  }, [])

  if (!authenticated) return null

  return (
    <div className="relative w-full">
      <div className="flex flex-row justify-center w-full">
        <div className="max-w-sm w-full p-24">{children}</div>
      </div>
    </div>
  )
}
