import { FC } from 'react'
import { Hidden, SwipeableDrawer } from '@mui/material'
import { styled } from '@mui/material/styles'
import { motion } from 'framer-motion'

const SidebarContainer = styled('div')(({ theme }) => ({
  width: '24rem',
  minWidth: '20rem',
  marginTop: '7.2rem',
  marginLeft: '-2.4rem',
  paddingTop: '1.6rem',
  paddingBottom: '1.6rem',
  paddingLeft: '1.2rem',
  paddingRight: '1.2rem',
  borderColor: 'rgba(0, 0, 0, 0.12)',
}))

type SidebarProps = {
  Content?: FC
  isDrawerOpen: boolean
  onCloseDrawer: () => void
}

const Sidebar: FC<SidebarProps> = ({ Content, isDrawerOpen, onCloseDrawer }) => {
  if (!Content) return null

  return (
    <>
      <Hidden lgDown>
        <SidebarContainer>
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.4 } }}
          >
            <Content />
          </motion.div>
        </SidebarContainer>
      </Hidden>
      <Hidden lgUp>
        <SwipeableDrawer
          variant="temporary"
          open={isDrawerOpen}
          onClose={onCloseDrawer}
          onOpen={() => {}}
          disableSwipeToOpen
          classes={{
            root: 'FusePageCarded-sidebarWrapper',
            paper: 'FusePageCarded-sidebar FusePageCarded-leftSidebar px-16 py-24 min-w-200',
          }}
          ModalProps={{
            keepMounted: true,
          }}
          BackdropProps={{
            classes: {
              root: 'FusePageCarded-backdrop',
            },
          }}
          style={{ position: 'absolute' }}
        >
          <Content />
        </SwipeableDrawer>
      </Hidden>
    </>
  )
}

export default Sidebar
