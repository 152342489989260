import { FC } from 'react'
import {
  ConsumerLoyaltyOutletReward,
  RewardTemplateType,
  ConsumerLoyaltyOutlet,
} from '@web-panel/api'
import { Typography, ButtonBase, useModalRef, formatCurrency } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import rewardLockedIcon from '@local/assets/icons/reward-locked.svg'
import rewardOpenIcon from '@local/assets/icons/reward-open.svg'
import classNames from 'classnames'
import { LoyaltyModal } from './LoaltyModal'
import { getIllustrationForRewardType, getLoyaltyRewardLabelKey } from './helpers'

type LoyaltyGiftItemProps = {
  reward: ConsumerLoyaltyOutletReward
  outlet: ConsumerLoyaltyOutlet
  achieved: boolean
}

export const LoyaltyGiftItem: FC<LoyaltyGiftItemProps> = ({ reward, outlet, achieved }) => {
  const modalRef = useModalRef()
  const { formatMessage } = useIntl()

  const illustration = getIllustrationForRewardType(reward.rewardType)
  const illustrationStyles =
    reward.rewardType === RewardTemplateType.Physical
      ? { width: '3.6rem', height: '6rem' }
      : { width: '5.2rem', height: '6rem' }
  const line1 =
    reward.rewardType === RewardTemplateType.Physical
      ? 'Coffee'
      : formatCurrency(reward.amount, reward.currency.name, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
  const line2 =
    reward.rewardType === RewardTemplateType.Physical
      ? 'for free'
      : formatMessage({ id: getLoyaltyRewardLabelKey(reward.rewardType) })

  return (
    <>
      <ButtonBase className="m-0 p-0" onClick={() => modalRef.current?.open()}>
        <div
          className={classNames(
            'w-full',
            'relative',
            'flex',
            'flex-col',
            'items-center',
            'rounded-8',
            'pb-12',
            { 'border-1': !achieved }
          )}
          style={{ backgroundColor: achieved ? '#E9FFF4' : 'transparent' }}
        >
          <img className="mt-7" src={illustration} style={illustrationStyles} alt="gift" />
          <Typography variant="caption" color="primary" fontWeight="600">
            {line1}
          </Typography>
          <Typography variant="caption" color="text.commentary">
            {line2}
          </Typography>
          <div className="w-24 h-24 absolute -bottom-12 mx-auto left-0 right-0">
            <img src={achieved ? rewardOpenIcon : rewardLockedIcon} alt="reward icon" />
          </div>
        </div>
      </ButtonBase>
      <LoyaltyModal ref={modalRef} reward={reward} outlet={outlet} achieved={achieved} />
    </>
  )
}
