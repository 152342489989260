import { FC } from 'react'

function getEmbeddedVideoUrl(embedId: string) {
  return `https://www.youtube.com/embed/${embedId}`
}

function getPreviewVideoUrl(embedId: string) {
  return `https://img.youtube.com/vi/${embedId}/hqdefault.jpg`
}

function getVideoUrl(embedId: string) {
  return `https://www.youtube.com/watch?v=${embedId}`
}

type YoutubeProps = {
  title?: string
  embedId: string
  previewOnly?: boolean
  previewPlayButton?: string
}

export const Youtube: FC<YoutubeProps> = ({
  embedId,
  title,
  previewOnly = false,
  previewPlayButton,
}) => {
  const onOpenVideo = () => window.open(getVideoUrl(embedId), '_blank')

  if (previewOnly) {
    return (
      <div className="relative w-full cursor-pointer" onClick={onOpenVideo} aria-hidden="true">
        <img className="w-full cursor-pointer" src={getPreviewVideoUrl(embedId)} alt={title} />
        {previewPlayButton && (
          <img
            className="absolute left-0 top-0 right-0 bottom-0 m-auto"
            width="30%"
            src={previewPlayButton}
            alt="play button"
          />
        )}
      </div>
    )
  }

  return (
    <iframe
      className="w-full"
      src={getEmbeddedVideoUrl(embedId)}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={title}
    />
  )
}
