import { FC } from 'react'
import { Avatar, Typography } from '@web-panel/shared'
import { CupIcon } from '@local/components/Icons'
import kookyLogoVertical from '@local/assets/images/logo-vertical.svg'

type LoyaltyCardOutletInfoProps = {
  logo: string | null
  title: string
  subtitle: string
  itemsCount: number
}

export const LoyaltyCardOutletInfo: FC<LoyaltyCardOutletInfoProps> = ({
  logo,
  title,
  subtitle,
  itemsCount,
}) => {
  return (
    <div className="flex flex-row">
      <div className="pt-3">
        <Avatar variant="rounded" src={logo ?? kookyLogoVertical} />
      </div>
      <div className="flex flex-col pl-8 flex-1">
        <Typography variant="h4" color="primary">
          {title}
        </Typography>
        <Typography variant="caption" color="text.commentary">
          {subtitle}
        </Typography>
      </div>
      <div className="flex flex-row">
        <div>
          <Typography variant="h4" color="primary">
            {itemsCount}
          </Typography>
        </div>
        <div className="pt-2 ml-2">
          <CupIcon active size={16} />
        </div>
      </div>
    </div>
  )
}
