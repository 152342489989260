import { forwardRef } from 'react'
import { Button as MuiButton, ButtonProps as MuiButtonProps, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'

const CoreButton = styled(MuiButton)(() => ({
  display: 'relative',
}))

export type ButtonProps = MuiButtonProps & {
  loading?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, loading, disabled, onClick, ...props }, ref) => {
    const compDisabled = !!disabled || !!loading

    return (
      <CoreButton ref={ref} {...props} disabled={compDisabled} onClick={onClick}>
        {children}
        {loading && (
          <CircularProgress
            color="primary"
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </CoreButton>
    )
  }
)
