import USFlag from 'country-flag-icons/3x2/US.svg'
import DEFlag from 'country-flag-icons/3x2/DE.svg'
import de from './de.json'
import en from './en.json'

type TranslationMessages = Record<string, string>

export const locales = {
  de,
  en,
} as unknown as Record<string, TranslationMessages>

export const availableLocales = [
  {
    id: 'de',
    icon: DEFlag,
    titleKey: 'locales.de',
  },
  {
    id: 'en',
    icon: USFlag,
    titleKey: 'locales.en',
  },
]
