const afterAuthKeyName = 'afterAuthRoute'

export function setCurrentRouteAsAfterAuth() {
  setAfterAuthRoute(window.location.pathname)
}

export function setAfterAuthRoute(route: string) {
  sessionStorage.setItem(afterAuthKeyName, route)
}

export function getAfterAuthRoute(): string | null {
  return sessionStorage.getItem(afterAuthKeyName)
}

export function clearAfterAuthRoute() {
  sessionStorage.removeItem(afterAuthKeyName)
}
