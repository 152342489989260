import { FC, useMemo } from 'react'
import { ConsumerItemHistoryCount, ProductItemType } from '@web-panel/api'
import { useModalRef } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import illustrations from '@local/assets/illustrations/original'
import { useUserItemsCount, useItemsEnvironmentalInfo } from '@web-panel/consumer-api'
import { Widget } from '../Widget'
import InfoDescriptionModal from '../../../Modals/InfoDescriptionModal'

const emptyCount: ConsumerItemHistoryCount = { inUse: 0, returned: 0, losted: 0 }

export const SummaryWidgets: FC = () => {
  const { formatMessage } = useIntl()
  const { data: environmentalInfo = [] } = useItemsEnvironmentalInfo()
  const { data: itemsCount } = useUserItemsCount({ fetchPolicy: 'cache-and-network' })
  const descriptionModal = useModalRef()

  const { co2Saved, co2Produced, unit } = useMemo(() => {
    const cupInfo = environmentalInfo.find(({ type }) => type === ProductItemType.Cup)
    if (!cupInfo)
      return {
        co2Saved: 0,
        co2Produced: 0,
        unit: 'g',
      }

    return {
      co2Produced: cupInfo.co2EmissionsProducedValue * (itemsCount?.losted ?? 0),
      co2Saved: cupInfo.co2EmissionsSavedValue * (itemsCount?.returned ?? 0),
      unit: cupInfo.co2EmissionsUnit,
    }
  }, [itemsCount, environmentalInfo])

  const { inUse, returned, losted } = itemsCount ?? emptyCount

  return (
    <div className="flex flex-row space-x-12 w-full">
      <Widget
        title={formatMessage({ id: 'history_labelUsing' })}
        value={inUse}
        valueColor="primary"
        illustration={illustrations.cupUsed}
        illustrationAlign="right"
        onClick={() => descriptionModal.current?.open()}
      />
      <Widget
        title={formatMessage({ id: 'history_labelReturned' })}
        value={returned}
        valueColor="success"
        illustration={illustrations.cupReturned}
        illustrationAlign="right"
        co2Value={co2Saved}
        co2Unit={unit}
        co2Label="Co2"
        co2Sign="-"
        onClick={() => descriptionModal.current?.open()}
      />
      <Widget
        title={formatMessage({ id: 'history_labelLost' })}
        value={losted}
        valueColor="error"
        illustration={illustrations.cupLost}
        illustrationAlign="right"
        co2Value={co2Produced}
        co2Unit={unit}
        co2Label="Co2"
        co2Sign="+"
        onClick={() => descriptionModal.current?.open()}
      />
      <InfoDescriptionModal ref={descriptionModal} />
    </div>
  )
}
