import { FC, createContext, useContext } from 'react'

type RedirectionRules = {
  onRestrictedAuth: string
  onRestrictedNonAuth: string
}

type AuthStateContext = {
  authenticated: boolean
  redirect: RedirectionRules
}

const Context = createContext<AuthStateContext>({
  authenticated: false,
  redirect: {
    onRestrictedAuth: '',
    onRestrictedNonAuth: '',
  },
})

export type AuthStateContextProviderProps = {
  authenticated: boolean
  redirect: RedirectionRules
}

export const AuthStateContextProvider: FC<AuthStateContextProviderProps> = ({
  authenticated,
  redirect,
  children,
}) => {
  return (
    <Context.Provider
      value={{
        authenticated,
        redirect,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useAuthState = () => {
  return useContext(Context)
}
