import { FC } from 'react'

const DefaultLayout: FC = ({ children }) => {
  return (
    <div className="relative w-full h-full">
      <div className="flex flex-col max-w-sm w-full h-full">{children}</div>
    </div>
  )
}

export default DefaultLayout
