import { FC, useMemo, ReactElement } from 'react'
import {
  ConsumerLoyaltyOutletItem,
  ConsumerLoyaltyOutletReward,
  ConsumerLoyaltyOutlet,
} from '@web-panel/api'
import classNames from 'classnames'
import { LoaltyCardItem } from './CardItem'
import { GhostCardItem } from './GhostCardItem'
import { LoyaltyGiftItem } from './GiftItem'

const elementsToDisplay = 5

type LoyaltyCardItemsContainerProps = {
  outlet: ConsumerLoyaltyOutlet
  items: ConsumerLoyaltyOutletItem[]
  nextReward: ConsumerLoyaltyOutletReward | null
}

function getItemsWithRewards(
  outlet: ConsumerLoyaltyOutlet,
  items: ConsumerLoyaltyOutletItem[],
  sliceTo: number
): ReactElement[] {
  const elements: ReactElement[] = []
  const reversedItems = [...items].reverse()
  for (let i = 0; i < reversedItems.length; i += 1) {
    const item = reversedItems[i]
    elements.push(<LoaltyCardItem key={item.id} item={item} />)

    if (item.rewards && item.rewards.length > 0) {
      elements.push(
        <LoyaltyGiftItem
          key={`reward-${item.id}`}
          reward={item.rewards[0]}
          outlet={outlet}
          achieved
        />
      )
    }
  }

  return elements.slice(-sliceTo, elements.length)
}

function getGhostCards(count: number, idStartsFrom: number) {
  return new Array(count)
    .fill(null)
    .map((_el, id) => <GhostCardItem key={`ghost-${id}`} id={idStartsFrom + id} />)
}

export const LoyaltyCardItemsContainer: FC<LoyaltyCardItemsContainerProps> = ({
  outlet,
  items,
  nextReward,
}) => {
  const outletItemsCount = outlet.itemsCount

  const elements = useMemo(() => {
    if (!nextReward) {
      const els = getItemsWithRewards(outlet, items, elementsToDisplay)
      const ghostCardsAmount = Math.max(elementsToDisplay - els.length, 2)

      const ghostCards = getGhostCards(ghostCardsAmount, outletItemsCount + 1)

      return [...els, ...ghostCards].slice(
        els.length + ghostCards.length - elementsToDisplay,
        els.length + ghostCards.length
      )
    }

    const minGhostCardsAmount = Math.min(nextReward.remainingReturnsToUnlock, 2)
    const els = getItemsWithRewards(outlet, items, elementsToDisplay - minGhostCardsAmount - 1)
    const ghostCards = getGhostCards(elementsToDisplay - els.length - 1, outletItemsCount + 1)
    const reward = (
      <LoyaltyGiftItem key="next-reward" reward={nextReward} outlet={outlet} achieved={false} />
    )

    return [...els, ...ghostCards, reward]
  }, [items, nextReward])

  return (
    <div className="mt-12">
      <div className={classNames('grid', `grid-cols-${elementsToDisplay}`)}>{elements}</div>
    </div>
  )
}
