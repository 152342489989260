import { FC } from 'react'
import { Button, Typography, FormattedCurrency, formatCurrency } from '@web-panel/shared'
import { ConsumerLoyaltyOutletReward } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { getAppLink } from '@local/helpers'
import rewardLockedIcon from '@local/assets/icons/reward-locked.svg'
import Lottie from 'lottie-react'
import confettiAnimation from '@local/assets/animations/confetti.json'
import {
  getIllustrationForRewardType,
  getLoyaltyRewardModalTitle,
  getLoyaltyRewardModalDescription,
} from '../helpers'

type CommonModalContentProps = {
  reward: ConsumerLoyaltyOutletReward
  achieved: boolean
}

export const CommonModalContent: FC<CommonModalContentProps> = ({ reward, achieved }) => {
  const { formatMessage } = useIntl()
  const illustration = getIllustrationForRewardType(reward.rewardType)
  const textColor = achieved ? 'primary' : 'text.disabled'
  const modalTitle = formatMessage({ id: getLoyaltyRewardModalTitle(reward.rewardType) })

  const descriptionAmount = formatCurrency(reward.amount, reward.currency.code, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })

  const modalDescription = formatMessage(
    { id: getLoyaltyRewardModalDescription(reward.rewardType) },
    { amount: descriptionAmount }
  )

  const buttonTitle = achieved
    ? formatMessage({ id: 'loyalty_reward_modal_achieved_web' })
    : formatMessage(
        { id: 'loyalty_reward_modal_future_web' },
        { count: reward.remainingReturnsToUnlock }
      )

  const handleOnClick = async () => {
    const appLink = await getAppLink()
    window.open(appLink)
  }

  return (
    <>
      {achieved && (
        <div className="absolute top-0 -left-72 -right-72 bottom-0">
          <Lottie animationData={confettiAnimation} loop />
        </div>
      )}
      <img src={illustration} width="120" height="140" alt="reward illustration" />
      <Typography className="mt-9" variant="h3" color="primary" fontWeight={600}>
        <FormattedCurrency
          value={reward.amount}
          currency={reward.currency.code}
          minimumFractionDigits={0}
          maximumFractionDigits={2}
        />
      </Typography>
      <div className="w-full mt-56">
        <Typography variant="h2" color={textColor}>
          {modalTitle}
          {!achieved && (
            <img className="inline w-30 h-30 ml-6 mb-3" src={rewardLockedIcon} alt="lock icon" />
          )}
        </Typography>
        <Typography className="mt-10" variant="body2">
          {modalDescription}
        </Typography>
      </div>
      <Button
        fullWidth
        disabled={!achieved}
        variant="contained"
        color="primary"
        onClick={handleOnClick}
      >
        {buttonTitle}
      </Button>
    </>
  )
}
