import { Location, locationFields } from './location'

export enum OutletType {
  Bar = 'BAR',
  CoffeeShop = 'COFFEE_SHOP',
  Restaurant = 'RESTAURANT',
  Cantine = 'CANTINE',
  Hotel = 'HOTEL',
  Takeaway = 'TAKEAWAY',
  Bakery = 'BAKERY',
  Office = 'OFFICE',
}

export enum OutletStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Deleted = 'DELETED',
}

export enum OutletCycleType {
  Standard = 'STANDARD',
  AutomatedAnonymous = 'AUTOMATED_ANONYMOUS',
  AutomatedConsumer = 'AUTOMATED_CONSUMER',
  Anonymouse = 'ANONYMOUS',
}

export type OutletLegalAddress = {
  name: string
  countryCode: string
  city: string
  street: string
  houseNumber: string
  postalCode: string
  legalAdditionalAddressLines: string[]
}

export type Outlet = {
  id: string
  partnerId: string
  outletNumber: string
  firstName: string
  lastName: string
  phone: string
  email: string
  operationsPhone: string | null
  operationsEmail: string | null
  logo: string | null
  type: OutletType
  status: OutletStatus
  location: Location
  hasBox: boolean
  legalName: string | null
  legalCountryCode: string | null
  legalStreet: string | null
  legalHouseNumber: string | null
  legalCity: string | null
  legalPostalCode: string | null
  legalAdditionalAddressLines: string[] | null
  featured: boolean
  batchDefaultSize: number
  hiddenOnMap: boolean
  cycleType: OutletCycleType
  orderReturnTypes: string[]
}

export const outletFields = `
  id
  outletNumber
  partnerId
  firstName
  lastName
  phone
  email
  operationsPhone
  operationsEmail
  logo
  type
  status
  hasBox
  location @type(name: "Location") {
    ${locationFields}
  }
  legalName
  legalCountryCode
  legalStreet
  legalHouseNumber
  legalCity
  legalPostalCode
  legalAdditionalAddressLines
  featured
  batchDefaultSize
  hiddenOnMap
  cycleType
  orderReturnTypes
`

export type OutletSupplement = {
  id: string
  field: string
  value: string
}

export const outletSupplementFields = `
  id
  field
  value
`
