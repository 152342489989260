import React from 'react'
import ReactDOM from 'react-dom'
import {
  App,
  SystemMonitoring,
  registerRoutes,
  registerAfterAuthRoutesEnabled,
  registerTheme,
  registerApplicationName,
  isFeatureFlagDisabled,
} from '@web-panel/shared'
import { configureClient, AuthContextProvider, AuthContextConsumer } from '@web-panel/api'
import routes from './routes'
import { locales, availableLocales } from './locales'
import { themeOverrides } from './theme-overrides'

import '@web-panel/shared/src/assets/styles/app.css'
import '@local/assets/styles/index.css'

configureClient(process.env.REACT_APP_API_HOST ?? '')

registerRoutes(routes)
registerTheme(themeOverrides)
registerApplicationName('Consumer')
registerAfterAuthRoutesEnabled(isFeatureFlagDisabled('neom'))

ReactDOM.render(
  <SystemMonitoring serviceName="consumer">
    <AuthContextProvider>
      <AuthContextConsumer>
        {({ authenticated }) => (
          <App
            auth={{
              authenticated,
              redirect: {
                onRestrictedAuth: '/sign-in',
                onRestrictedNonAuth: '/',
              },
            }}
            locale={{
              locales,
              availableLocales,
              defaultLocale: 'en',
            }}
            toolbar={{
              rightTools: [],
            }}
          />
        )}
      </AuthContextConsumer>
    </AuthContextProvider>
  </SystemMonitoring>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
