import { FC, useState } from 'react'
import {
  DialogTitle,
  DialogContent,
  TextField,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Icon,
  InfinitScroller,
  buildModal,
  ModalBodyProps,
  getCountryByCode,
  Country,
  useCountries,
} from '@web-panel/shared'
import getFlag from 'country-flag-icons/unicode'

export const defaultCountry = getCountryByCode('CH')

type CountryPickModalProps = ModalBodyProps & {
  onPick: (country: Country) => void
}

const CountryPickModal: FC<CountryPickModalProps> = ({ onClose, onPick }) => {
  const [filterText, setFilterText] = useState('')
  const { data: countries, loadMore } = useCountries(filterText)

  const handlePick = (country: Country) => {
    onPick(country)
    onClose()
  }

  return (
    <>
      <DialogTitle className="flex flex-row justify-end">
        <Icon
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          close
        </Icon>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        <InfinitScroller onLoadMore={loadMore}>
          <List>
            {countries.map((country) => (
              <ListItemButton key={country.code} onClick={() => handlePick(country)}>
                <ListItemIcon>{getFlag(country.code)}</ListItemIcon>
                <ListItemText>
                  <span className="mr-4">{country.phone}</span>
                  {country.native}
                </ListItemText>
              </ListItemButton>
            ))}
          </List>
        </InfinitScroller>
      </DialogContent>
    </>
  )
}

export default buildModal(CountryPickModal, {
  maxWidth: 'sm',
  classes: { paper: 'h-full w-full' },
})
