import { FC } from 'react'
import { useIntl } from 'react-intl'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import PageHeader from '@local/components/PageHeader'
import { getCountryByCodeOrNull } from '@web-panel/shared'
import { PromoCampaingWelcomeCard } from '@local/components/PromoCampaign/WelcomeCard'
import { useMyIpLocation } from '@web-panel/consumer-api'
import SignInForm, { FormFields, defaultValues, validationSchema } from './Form'

const SignInPage: FC = () => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { data, loading } = useMyIpLocation()

  const initialValues = data
    ? { ...defaultValues, country: getCountryByCodeOrNull(data.country) ?? defaultValues.country }
    : defaultValues

  if (loading) return null

  const onSubmit = async (values: FormFields) => {
    const phone = [values.country.phone, values.phoneNumber].join('')
    navigate('confirm-phone', { state: { phone } })
  }

  return (
    <div>
      <PageHeader
        htmlTitle={formatMessage({ id: 'web_login_title' })}
        title={formatMessage({ id: 'web_login_title' })}
      />
      <div className="mt-7">
        <Formik<FormFields>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formProps) => <SignInForm {...formProps} />}
        </Formik>
        <PromoCampaingWelcomeCard className="mt-44" />
      </div>
    </div>
  )
}

export default SignInPage
