import { FC } from 'react'
import { Typography, Button } from '@web-panel/shared'
import PageHeader from '@local/components/PageHeader'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import illustrations from '@local/assets/illustrations'
import { SupportBlock } from '@local/components/SupportBlock'

export const NotFoundScanResult: FC = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  return (
    <div className="flex flex-col h-full">
      <div className="px-24 pb-20">
        <PageHeader
          closable
          htmlTitle={formatMessage({ id: 'take_cup_error_title' })}
          title={formatMessage({ id: 'take_cup_error_title' })}
          status={<SupportBlock />}
        />
      </div>
      <div className="flex flex-col flex-1 mx-24">
        <div className="flex flex-1 justify-center">
          <img
            className="mt-22"
            width="200rem"
            src={illustrations.scanResult.cupNotFound}
            alt="scan error"
          />
        </div>
        <div className="flex flex-col justify-end items-center">
          <Typography>
            <FormattedMessage id="take_cup_error_message" />
          </Typography>
          <div className="h-72">
            <div className="fixed left-0 right-0 bottom-0 px-24">
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => navigate('/scan')}
              >
                <FormattedMessage id="take_cup_error_cta" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
