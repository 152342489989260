import { FC } from 'react'
import { isFeatureFlagEnabled } from '../../helpers'

type FeatureFlagProps = {
  name: string
  enabled?: boolean
}

export const FeatureFlag: FC<FeatureFlagProps> = ({ name, enabled = true, children }) => {
  if (isFeatureFlagEnabled(name) !== enabled) return null

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
