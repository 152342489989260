import { VFC } from 'react'
import PageHeader from '@local/components/PageHeader'
import { Typography, Button, useModalRef } from '@web-panel/shared'
import illustrations from '@local/assets/illustrations/neom'
import { useNavigate } from 'react-router-dom'
import { SupportChatModal } from '@local/components/SupportChatModal'

export const InsufficientFundsError: VFC = () => {
  const navigate = useNavigate()
  const supportChatModalRef = useModalRef()

  return (
    <div className="px-24 pb-20 flex flex-col h-full">
      <div>
        <PageHeader hideLogo htmlTitle="You run out of cups" title="You run out of cups" />
        <Typography className="mt-7" variant="body1">
          You need to return at least one cup you’re using if you want to get new one.
        </Typography>
      </div>
      <div className="flex-1 flex flex-col items-center">
        <div className="flex-1 flex flex-col justify-center">
          <img
            width="319"
            height="319"
            src={illustrations.scanResult.insufficientFunds}
            alt="error"
          />
        </div>
        <div className="w-full">
          <Button
            className="mb-0"
            fullWidth
            variant="contained"
            onClick={() => navigate('/', { replace: true })}
          >
            Got it
          </Button>
          <Button
            className="mt-16"
            fullWidth
            variant="outlined"
            onClick={() => supportChatModalRef.current?.open()}
          >
            Contact support
          </Button>
        </div>
      </div>
      <SupportChatModal ref={supportChatModalRef} />
    </div>
  )
}
