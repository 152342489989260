import { FC, useState } from 'react'
import { Typography, TextField, Checkbox, FormControlLabel, Button } from '@web-panel/shared'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FormattedMessage, useIntl } from 'react-intl'
import successAnimation from '@local/assets/animations/success.json'
import errorAnimation from '@local/assets/animations/error.json'
import { getAppLink } from '@local/helpers'
import { useUpdateWWFAccount, usePaymentMethods } from '@web-panel/consumer-api'
import { ResultMessage } from './Components/ResultMessage'

const wwfTermsUrl = 'https://www.wwf.ch/de/ueber-uns/datenschutzerklaerung'

type FormFields = {
  email: string
  dataProcessConfirmed: boolean
  newslettersOptIn: boolean
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  dataProcessConfirmed: Yup.boolean().oneOf([true]),
  newslettersOptIn: Yup.boolean(),
})

const initialValues: FormFields = {
  email: '',
  dataProcessConfirmed: false,
  newslettersOptIn: false,
}

export const TaxExemptionPage: FC = () => {
  const [resultState, setResultState] = useState<'success' | 'error' | null>(null)
  const { formatMessage } = useIntl()
  const { request: updateWWFAccount, loading: loadingUpdateWWFAccount } = useUpdateWWFAccount()
  const { data: paymentMethods } = usePaymentMethods()

  if (!paymentMethods) return null
  const { wwfAccount } = paymentMethods

  if (resultState === 'success' || wwfAccount?.dataProcessConfirmed)
    return (
      <ResultMessage
        animationData={successAnimation}
        title={formatMessage({ id: 'request_payout_success_title' })}
        message={formatMessage({ id: 'request_sent_succesfully_tax_exemption' })}
        primaryButtonTitle={formatMessage({ id: 'return_to_mobile_app' })}
        primaryButtonOnClick={async () => {
          const link = await getAppLink('/wallet', false)
          window.open(link)
        }}
      />
    )
  if (resultState === 'error')
    return (
      <ResultMessage
        animationData={errorAnimation}
        title={formatMessage({ id: 'request_payout_error_title' })}
        message={formatMessage({ id: 'request_payout_error_body' })}
        primaryButtonTitle={formatMessage({ id: 'return_to_mobile_app' })}
        primaryButtonOnClick={async () => {
          const link = await getAppLink('/wallet', false)
          window.open(link)
        }}
      />
    )

  const onSubmit = async (values: FormFields) => {
    if (!wwfAccount) return

    try {
      await updateWWFAccount({
        id: wwfAccount.wwfAccountId,
        input: {
          email: values.email,
          dataProcessConfirmed: true,
          newslettersOptIn: true,
        },
      })
      setResultState('success')
    } catch (err) {
      setResultState('error')
    }
  }

  const dataProcessMessage = (
    <Typography variant="body2" color="gray">
      <FormattedMessage id="agree_send_data_wwf" />
      <a href={wwfTermsUrl} target="_blank" rel="noopener noreferrer">
        <FormattedMessage id="wwf_privacy_policy" />
      </a>
    </Typography>
  )

  const newsletterMessage = (
    <Typography variant="body2" color="gray">
      <FormattedMessage id="stay_up_date_wwf" />
    </Typography>
  )

  return (
    <Formik<FormFields>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, errors, handleSubmit, dirty, isValid }) => (
        <div>
          <Typography variant="h4" color="primary">
            <FormattedMessage id="wwf_bottom_sheet_title" />
          </Typography>
          <Typography className="mt-8" variant="caption" color="gray">
            <FormattedMessage id="provided_for_donations_amount" />
          </Typography>
          <div className="mt-20">
            <FormControlLabel
              label={dataProcessMessage}
              control={
                <Checkbox
                  checked={values.dataProcessConfirmed}
                  onChange={handleChange('dataProcessConfirmed')}
                />
              }
            />
          </div>
          <div className="mt-20">
            <FormControlLabel
              label={newsletterMessage}
              control={
                <Checkbox
                  checked={values.newslettersOptIn}
                  onChange={handleChange('newslettersOptIn')}
                />
              }
            />
          </div>
          <TextField
            fullWidth
            type="email"
            autoComplete="email"
            className="mt-20"
            label={formatMessage({ id: 'account_email' })}
            value={values.email}
            onChange={handleChange('email')}
            error={!!errors.email}
          />
          <Button
            className="mt-20"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!dirty || !isValid}
            loading={loadingUpdateWWFAccount}
            onClick={() => handleSubmit()}
          >
            <FormattedMessage id="bank_transfer_confirm" />
          </Button>
        </div>
      )}
    </Formik>
  )
}
