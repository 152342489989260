import { useQuery } from '@apollo/client'
import {
  CitiesDocumentInput,
  CitiesDocumentResponse,
  CitiesDocument,
  CityDocumentInput,
  CityDocumentResponse,
  CityDocument,
  CountriesDocument,
  CountriesDocumentInput,
  CountriesDocumentResponse,
  CountryDocument,
  CountryDocumentInput,
  CountryDocumentResponse,
} from '../docs'
import { QueryHook } from '../types'

export const useCities: QueryHook<CitiesDocumentInput, CitiesDocumentResponse['cities']> = (
  options
) => {
  const { data, loading, refetch } = useQuery<CitiesDocumentResponse>(CitiesDocument, options)

  return {
    data: data?.cities,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useCity: QueryHook<CityDocumentInput, CityDocumentResponse['city']> = (
  variables,
  options
) => {
  const { data, loading, refetch } = useQuery<CityDocumentResponse>(CityDocument, {
    variables,
    ...options,
  })

  return {
    data: data?.city,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useCountries: QueryHook<
  CountriesDocumentInput,
  CountriesDocumentResponse['countries']
> = (options) => {
  const { data, loading, refetch } = useQuery<CountriesDocumentResponse>(CountriesDocument, options)

  return {
    data: data?.countries,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useCountry: QueryHook<CountryDocumentInput, CountryDocumentResponse['country']> = (
  variables
) => {
  const { data, loading, refetch } = useQuery<CountryDocumentResponse>(CountryDocument, {
    variables,
  })

  return {
    data: data?.country,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

type UseCountryCitiesInput = {
  countryCode: string
}

export const useCountryCities: QueryHook<UseCountryCitiesInput, CitiesDocumentResponse['cities']> =
  ({ countryCode }, options) => {
    const { data = [], loading, refetch } = useCities()

    const cities = data.filter(({ country }) => country.code === countryCode)

    return {
      data: cities,
      loading,
      refetch,
    }
  }
