import { FC, useMemo } from 'react'
import { ButtonBase, useModalRef } from '@web-panel/shared'
import PageHeader from '@local/components/PageHeader'
import { useIntl } from 'react-intl'
import BottomTabs from '@local/components/BottomTabs'
import { GoldenCupActivityCard } from '@local/components/PromoCampaign/ActivityCard'
import { GoldenCupModal } from '@local/components/PromoCampaign/Modal'
import { SupportChatModal } from '@local/components/SupportChatModal'
import { MessageIcon, CogIcon } from '@local/components/Icons'
import { WalletStatus } from './Components/WalletsStatus'
import SettingsModal from './Modals/SettingsModal'
import { SummaryWidgets } from './Components/SummaryWidgets'
import { ListTabs } from './Components/ListTabs'

const Dashboard: FC = () => {
  const { formatMessage } = useIntl()
  const goldenCupModalRef = useModalRef()
  const settingsModalRef = useModalRef()
  const supportChatModalRef = useModalRef()

  const headerButtons = useMemo(() => {
    return [
      <GoldenCupActivityCard onClick={() => goldenCupModalRef.current?.open()} />,
      <ButtonBase key="support" onClick={() => supportChatModalRef.current?.open()}>
        <MessageIcon />
      </ButtonBase>,
      <ButtonBase key="signout" onClick={() => settingsModalRef.current?.open()}>
        <CogIcon />
      </ButtonBase>,
    ]
  }, [])

  return (
    <>
      <div className="px-24 pb-20">
        <PageHeader
          htmlTitle={formatMessage({ id: 'history' })}
          title={formatMessage({ id: 'history' })}
          buttons={headerButtons}
          status={<WalletStatus />}
        />
      </div>
      <div className="flex-1 flex flex-col w-full">
        <div className="px-12 pb-12">
          <SummaryWidgets />
        </div>
        <div className="flex-1" style={{ backgroundColor: '#F7F7F7' }}>
          <ListTabs />
        </div>
      </div>
      <BottomTabs />
      <SettingsModal ref={settingsModalRef} />
      <GoldenCupModal ref={goldenCupModalRef} />
      <SupportChatModal ref={supportChatModalRef} />
    </>
  )
}

export default Dashboard
