import { FC, useState, createContext, useContext } from 'react'
import { useLocalStorage } from '../../hooks/use-local-storage'

type NavbarContext = {
  isOpen: boolean
  isOpenMobile: boolean
  closeNavbar: () => void
  openNavbar: () => void
  closeMobileNavbar: () => void
  openMobileNavbar: () => void
}

const Context = createContext<NavbarContext>({
  isOpen: true,
  isOpenMobile: false,
  closeNavbar: () => {},
  openNavbar: () => {},
  openMobileNavbar: () => {},
  closeMobileNavbar: () => {},
})

export const NavbarProvider: FC = ({ children }) => {
  const [state, setState] = useLocalStorage('navbar-state', 'open')
  const [mobileState, setMobileState] = useState(false)
  const isOpen = state !== 'closed'

  return (
    <Context.Provider
      value={{
        isOpen,
        isOpenMobile: mobileState,
        openNavbar: () => setState('open'),
        closeNavbar: () => setState('closed'),
        openMobileNavbar: () => setMobileState(true),
        closeMobileNavbar: () => setMobileState(false),
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useNavbar = () => {
  return useContext(Context)
}
