import { FC } from 'react'
import { useTheme } from '@web-panel/shared'

export const LoyaltyCardContainer: FC = ({ children }) => {
  const theme = useTheme()
  return (
    <div className="pt-13 pb-18 px-16" style={{ backgroundColor: theme.palette.background.paper }}>
      {children}
    </div>
  )
}
