import { forwardRef } from 'react'
import { SnackbarContent } from 'notistack'
import { NotificationCard, NotificationItem } from './NotificationCard'

type NotificationProps = {
  item: NotificationItem
  onClose: (id: string) => void
  onClick?: () => void
}

const Notification = forwardRef<HTMLDivElement, NotificationProps>(
  ({ item, onClose, onClick }, ref) => {
    const handleOnClose = () => {
      onClose(item.id)
    }

    return (
      <SnackbarContent
        ref={ref}
        className="mx-auto max-w-320 w-full relative pointer-events-auto py-4"
      >
        <NotificationCard item={item} onClose={handleOnClose} onClick={onClick} />
      </SnackbarContent>
    )
  }
)

export default Notification
