export enum ActivityType {
  Created = 'CREATED',
  Updated = 'UPDATED',
  Deleted = 'DELETED',
  LocationDeleted = 'LOCATION_DELETED',
  CupReturn = 'CUP_RETURN',
  BoxFull = 'BOX_FULL',
  BoxEmpty = 'BOX_EMPTY',
  EmployeeCreated = 'EMPLOYEE_CREATED',
  EmployeeUpdated = 'EMPLOYEE_UPDATED',
  HolderChange = 'HOLDER_CHANGE',
  HolderChangeError = 'HOLDER_CHANGE_ERROR',
  StatusChange = 'STATUS_CHANGE',
  ItemBatched = 'ITEM_BATCHED',
  ItemChecked = 'ITEM_CHECKED',
  ItemReturnError = 'ITEM_RETURN_ERROR',
  ItemCycleTypeChanged = 'ITEM_CYCLE_TYPE_CHANGED',
  ContainerBatched = 'CONTAINER_BATCHED',
  ContainerAddedToOrder = 'CONTAINER_ADDED_TO_ORDER',
}

export enum ActivityTriggerType {
  Administrator = 'ADMINISTRATOR',
  Partner = 'PARTNER',
  Consumer = 'CONSUMER',
  Box = 'BOX',
  PartnerEmployee = 'PARTNER_EMPLOYEE',
  SystemAutomation = 'SYSTEM_AUTOMATION',
  BoxTask = 'BOX_TASK',
  ExternalOperation = 'EXTERNAL_OPERATION',
}
