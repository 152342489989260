import { FC } from 'react'
import { ConsumerWallet } from '@web-panel/api'
import { FormattedCurrency, Typography } from '@web-panel/shared'
import { FormattedNumber } from 'react-intl'
import chfIcon from '@local/assets/icons/chf-icon.svg'
import euIcon from '@local/assets/icons/eu-icon.svg'

import { useUserItemsCount } from '@web-panel/consumer-api'

type WalletAmountProps = {
  wallet: ConsumerWallet
  showFlagIcon?: boolean
}

export const WalletAmount: FC<WalletAmountProps> = ({ wallet, showFlagIcon = false }) => {
  const { data: itemsCount } = useUserItemsCount()

  const isInitial =
    !itemsCount || (itemsCount.inUse === 0 && itemsCount.returned === 0 && itemsCount.losted === 0)

  const icon = wallet.currency.code === 'CHF' ? chfIcon : euIcon
  const isWalletGolden = wallet.campaignIds.length > 0

  const amountColor = isWalletGolden ? '#FAB621' : 'primary.main'

  return (
    <div className="flex flex-row items-center">
      {showFlagIcon && <img className="mr-4" src={icon} alt="currency icon" />}
      <Typography variant="caption" fontWeight={600} color={amountColor}>
        {isInitial ? (
          <FormattedNumber
            style="decimal"
            value={wallet.amount}
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
        ) : (
          <FormattedCurrency
            value={wallet.amount}
            currency={wallet.currency.code}
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
        )}
      </Typography>
    </div>
  )
}
