import { VFC } from 'react'
import { styled, Button, Typography, Icon, PageTitle, useModalRef } from '@web-panel/shared'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { QrCodeScanner } from '@local/components/QrCodeScanner'
import ManualCodeEnterModal from './ManualCodeEnterModal'

const Background = styled('div')((theme) => ({
  backgroundColor: '#000000',
  width: '100%',
  height: '100%',
}))

type QrCodeScannerUIProps = {
  title: string
  onSubmit: (code: string) => void
  codeMatcher: RegExp
}

export const QrCodeScannerUI: VFC<QrCodeScannerUIProps> = ({ title, onSubmit, codeMatcher }) => {
  const modalRef = useModalRef()
  const navigate = useNavigate()

  return (
    <>
      <PageTitle title={title} />
      <Background>
        <QrCodeScanner codeMatcher={codeMatcher} onDetectCode={onSubmit} />
        <div className="absolute top-32 left-28 right-28 z-10">
          <Icon className="text-white" onClick={() => navigate('/')}>
            close
          </Icon>
          <Typography className="mt-20" variant="h2" color="white">
            {title}
          </Typography>
        </div>
        <div className="fixed bottom-0 left-0 right-0 pb-20 px-24">
          <Button
            fullWidth
            className="m-0"
            color="secondary"
            variant="contained"
            onClick={() => modalRef.current?.open()}
          >
            <FormattedMessage id="scan_cta" />
          </Button>
        </div>
        <ManualCodeEnterModal ref={modalRef} onCodeEnter={onSubmit} />
      </Background>
    </>
  )
}
