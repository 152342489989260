import { FC } from 'react'
import { Card, Typography, IconButton, Icon } from '@mui/material'
import classNames from 'classnames'
import NotificationIcon from './NotificationIcon'
import { NotificationVariant } from './types'

export type NotificationItem = {
  id: string
  message: string
  variant: NotificationVariant
}

type NotificationCardProps = {
  item: NotificationItem
  onClose: () => void
  onClick?: () => void
}

export const NotificationCard: FC<NotificationCardProps> = ({ item, onClose, onClick }) => {
  const { variant } = item

  return (
    <Card
      onClick={onClick}
      className={classNames(
        'flex items-center relative w-full rounded-16 p-20 min-h-64 shadow',
        variant === 'success' && 'bg-green-600 text-white',
        variant === 'info' && 'bg-blue-700 text-white',
        variant === 'error' && 'bg-red-600 text-white',
        variant === 'warning' && 'bg-orange-600 text-white',
        {
          'cursor-pointer': onClick,
        }
      )}
      elevation={0}
    >
      <NotificationIcon variant={variant} />
      <Typography component="div">{item.message}</Typography>
      <IconButton
        disableRipple
        className="top-0 right-0 absolute p-8"
        color="inherit"
        size="small"
        onClick={onClose}
      >
        <Icon className="text-12 opacity-75" color="inherit">
          close
        </Icon>
      </IconButton>
    </Card>
  )
}
