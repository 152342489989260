import { FC } from 'react'

import { styled, darken } from '@mui/material/styles'

const Root = styled('div')(({ theme }) => ({
  background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
    theme.palette.primary.dark,
    0.5
  )} 100%)`,
  color: theme.palette.primary.contrastText,
}))

export const AuthRightSection: FC = ({ children }) => {
  return (
    <Root className="hidden md:flex flex-1 items-center justify-center p-64">
      <div className="max-w-320">{children}</div>
    </Root>
  )
}
