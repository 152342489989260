import { FieldPolicy } from '@apollo/client'

function buildCachePolicy(keyArgs: string[], mergeField: string, mergeType: string): FieldPolicy {
  const refName = `${mergeField}@type({"name":"${mergeType}"})`

  return {
    keyArgs,
    // eslint-disable-next-line default-param-last
    merge: (existing = {}, incomming, { args }) => {
      if (!incomming) return existing

      const existingRefs = existing[refName] ?? []
      const incommingRefs = incomming[refName] ?? []
      const mergedRefs = args?.offset > 0 ? [...existingRefs, ...incommingRefs] : incommingRefs

      return {
        ...existing,
        ...incomming,
        [refName]: mergedRefs,
      }
    },
  }
}

export default buildCachePolicy
