import { VFC } from 'react'
import { Typography, CodeInput, useNotifications } from '@web-panel/shared'
import { useAuthContext } from '@web-panel/api'
import PageHeader from '@local/components/PageHeader'
import { useLocation, Navigate } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { useRequestEmailAuthTokens } from '@web-panel/consumer-api'

const EnterCodePage: VFC = () => {
  const { state } = useLocation()
  const { formatMessage } = useIntl()
  const { request: requestAuthTokens, loading } = useRequestEmailAuthTokens()
  const { pushNotification } = useNotifications()
  const { signin } = useAuthContext()

  const email = state?.email

  if (!email) return <Navigate to="/sign-in" replace />

  const onSubmit = async (token: string) => {
    try {
      const { accessToken, refreshToken } = await requestAuthTokens({
        email,
        token: Number(token),
      })

      signin({ accessToken, refreshToken })
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'login_error_code' }))
    }
  }

  return (
    <div>
      <PageHeader htmlTitle="Check your inbox" title="Check your inbox" />
      <Typography variant="body1" className="mt-7">
        Enter the 6 digits code we’ve sent to <b>{email}</b>.
      </Typography>
      <div className="flex flex-row justify-center mt-64">
        <CodeInput
          autoFocus
          disabled={loading}
          length={6}
          inputMode="numeric"
          onCodeEnter={onSubmit}
        />
      </div>
    </div>
  )
}

export default EnterCodePage
