import { FC } from 'react'
import { useIntl } from 'react-intl'
import { LoyaltyCardOutletInfo } from '@local/components/LoyaltyCard/OutletInfo'
import { LoyaltyCardContainer } from '@local/components/LoyaltyCard/Container'
import { LoyaltyCardItemsContainer } from '@local/components/LoyaltyCard/CardItemsContainer'

export const GhostLoyaltyCard: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <LoyaltyCardContainer>
      <LoyaltyCardOutletInfo
        logo={null}
        title={formatMessage({ id: 'ghost_loyalty_card_title' })}
        subtitle={formatMessage({ id: 'ghost_loyalty_card_subtitle' })}
        itemsCount={0}
      />
      <LoyaltyCardItemsContainer
        outlet={{ id: '', name: '', address: '', logo: null, itemsCount: 0 }}
        items={[]}
        nextReward={null}
      />
    </LoyaltyCardContainer>
  )
}
