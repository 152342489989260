export enum PartnerTypes {
  Washing = 'WASHING_CENTER',
  Fulfilment = 'FULFILMENT_CENTER',
  WashingFulfilment = 'WASHING_FULFILMENT_CENTER',
  Customer = 'CUSTOMER',
  Kooky = 'KOOKY',
}

const addressContactFields = `
  id
  fullName
  phone
  company
  mobilePhone
  email
`

export type Address = {
  id: string
  partnerId: string
  outletId: string
  partnerType: PartnerTypes
  address: string
  cityCode: string
  cityName: string
  ianaTimeZone: string
  countryCode: string
  zipCode: string
  active: boolean
  settingsEnabled: boolean
  operationsStartDate: string
  contactDetail: ContactDetails
}

export type ContactDetails = {
  id: string
  fullName: string
  phone: string
  company: string
  mobilePhone: string
  email: string
}

export const addressFields = `
  id
  partnerId
  outletId
  partnerType
  address
  cityCode
  cityName
  ianaTimeZone
  countryCode
  zipCode
  active
  settingsEnabled
  operationsStartDate
  contactDetail {
    ${addressContactFields}
  }
`
