import { VFC, useRef, useEffect } from 'react'
import { useTheme } from '@web-panel/shared'
import { Scanner } from './Scanner'

type QrCodeScannerProps = {
  onDetectCode: (code: string) => void
  codeMatcher: RegExp
}

export const QrCodeScanner: VFC<QrCodeScannerProps> = ({ onDetectCode, codeMatcher }) => {
  const theme = useTheme()
  const scannerRef = useRef<Scanner>(null)

  const width = window.innerWidth
  const height = window.innerHeight
  const aspectRatio = height / width

  const onDetectScan = async (scan: string[]) => {
    scannerRef.current?.stop()

    const qrCode = scan[1]
    onDetectCode(qrCode)
  }

  useEffect(() => {
    return () => {
      scannerRef.current?.stop()
    }
  }, [])

  return (
    <div
      className="w-full"
      ref={(el) => {
        if (!el) return
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        scannerRef.current = new Scanner(el, onDetectScan, {
          aspectRatio,
          contentMatcher: codeMatcher,
          borderColor: theme.palette.common.white,
          borderDetectedColor: theme.palette.secondary.main,
        })
      }}
    />
  )
}
