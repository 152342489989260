import { FC } from 'react'
import { ListSubheader } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'

const Root = styled(ListSubheader)(({ theme }) => ({
  height: 40,
  width: '100%',
  borderRadius: '6px',
  margin: '24px 0 4px 0',
  paddingRight: 12,
  color: alpha(theme.palette.text.primary, 0.7),
  fontWeight: 600,
  letterSpacing: '0.025em',
}))

type NavbarSectionTitleProps = {
  title: string
}

export const NavbarSectionTitle: FC<NavbarSectionTitleProps> = ({ title }) => {
  return (
    <Root className="list-subheader flex items-center" disableSticky>
      <span className="list-subheader-text uppercase text-12">{title}</span>
    </Root>
  )
}
