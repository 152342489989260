import { red } from '@mui/material/colors'
import { createTheme, Theme, ThemeOptions } from '@mui/material/styles'
import _merge from 'lodash/merge'
import { getRegisteredTheme } from '../../registrations'
import { dark, skyBlue, accent } from './colors'

const defaultThemeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: ['Inter', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    htmlFontSize: 10,
    fontSize: 13,
    body1: {
      fontSize: '1.3rem',
    },
    body2: {
      fontSize: '1.4rem',
    },
    h1: {
      fontSize: '3.2rem',
      lineHeight: '3.75rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2.8rem',
      lineHeight: '3.35rem',
    },
    h3: {
      fontSize: '2.4rem',
      lineHeight: '2.95rem',
    },
    h4: {
      fontSize: '2.1rem',
      lineHeight: '2.65rem',
    },
    h5: {
      fontSize: '1.8rem',
      lineHeight: '2.35rem',
    },
    h6: {
      fontSize: '1.6rem',
      lineHeight: '2.15rem',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          whiteSpace: 'pre-line',
        },
      },
    },
    MuiIcon: {
      defaultProps: {
        // Replace the `material-icons` default value.
        baseClassName: 'material-icons-outlined',
      },
    },
    MuiAppBar: {
      defaultProps: {
        enableColorOnDark: true,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '18px',
          '&.Mui-disabled': {
            opacity: '0.5',
            borderRightWidth: 0,
          },
        },
        sizeSmall: {
          borderRadius: '15px',
        },
        sizeLarge: {
          borderRadius: '21px',
        },
        contained: {
          boxShadow: 'none',
          '&:hover, &:focus': {
            boxShadow: 'none',
          },
          '&.MuiButtonGroup-grouped': {
            borderWidth: '0 !important',
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        contained: {
          borderRadius: 18,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          height: '7.2rem',
          textTransform: 'none',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 16,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingTop: '24px',
          paddingBottom: '24px',
          paddingLeft: '16px',
          paddingRight: '16px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 16,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          height: '7.2rem',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          '&:before, &:after': {
            display: 'none',
          },
        },
      },
    },
    MuiSlider: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiTextField: {
      defaultProps: {
        inputProps: {
          autoComplete: 'do-not-autocomplete',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiLink: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          '&.MuiPaper-outlined': {
            borderWidth: 0,
            '.MuiCardContent-root': {
              paddingTop: '3rem',
              paddingBottom: '3rem',
              paddingLeft: '3rem',
              paddingRight: '3rem',
              backgroundColor: 'rgba(11,60,77, .05)',
            },
            '.MuiCardActions-root': {
              paddingTop: '2.4rem',
              paddingBottom: '2.4rem',
              paddingLeft: '3rem',
              paddingRight: '3rem',
              backgroundColor: '#202734',
              color: '#FFFFFF',
            },
          },
        },
      },
    },
  },
  palette: {
    mode: 'light',
    text: {
      primary: 'rgb(17, 24, 39)',
      secondary: 'rgb(107, 114, 128)',
      disabled: 'rgb(149, 156, 169)',
      commentary: 'rgb(167, 167, 167)',
    },
    common: {
      black: 'rgb(17, 24, 39)',
      white: 'rgb(255, 255, 255)',
    },
    primary: dark,
    secondary: {
      light: skyBlue[100],
      main: skyBlue[500],
      dark: skyBlue[900],
    },
    background: {
      paper: '#FFFFFF',
      default: '#f6f7f9',
    },
    accent: {
      light: accent[100],
      main: accent[500],
      dark: accent[900],
      contrastText: '#ffffff',
    },
    accent2: {
      light: accent[100],
      main: accent[500],
      dark: accent[900],
      contrastText: '#ffffff',
    },
    action: {
      disabled: '',
      disabledBackground: '',
    },
    error: red,
  },
}

function extendThemeWithMixins(obj: ThemeOptions) {
  const th = createTheme(obj)

  return {
    border: (width = 1) => ({
      borderWidth: width,
      borderStyle: 'solid',
      borderColor: th.palette.divider,
    }),
    borderLeft: (width = 1) => ({
      borderLeftWidth: width,
      borderStyle: 'solid',
      borderColor: th.palette.divider,
    }),
    borderRight: (width = 1) => ({
      borderRightWidth: width,
      borderStyle: 'solid',
      borderColor: th.palette.divider,
    }),
    borderTop: (width = 1) => ({
      borderTopWidth: width,
      borderStyle: 'solid',
      borderColor: th.palette.divider,
    }),
    borderBottom: (width = 1) => ({
      borderBottomWidth: width,
      borderStyle: 'solid',
      borderColor: th.palette.divider,
    }),
  }
}

export function getTheme(): Theme {
  const registeredTheme = getRegisteredTheme()
  const mergedTheme = _merge({}, defaultThemeOptions, registeredTheme)

  const data = _merge({}, mergedTheme, {
    mixins: extendThemeWithMixins(mergedTheme),
    direction: 'ltr',
  })

  const muiTheme = createTheme(data)

  return muiTheme
}
