import { FC } from 'react'
import { Typography } from '@web-panel/shared'
import illustrations from '@local/assets/illustrations'

type GhostCardItemProps = {
  id: number
}

export const GhostCardItem: FC<GhostCardItemProps> = ({ id }) => {
  return (
    <div className="flex flex-col items-center">
      <img className="mb-5" src={illustrations.cupGhost} width={60} height={60} alt="cup" />
      <div className="w-full truncate text-center">
        <Typography
          className="whitespace-nowrap"
          variant="caption"
          sx={{ fontWeight: 600 }}
          color="text.commentary"
        >
          {id}
        </Typography>
      </div>
    </div>
  )
}
