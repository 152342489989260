import { FC } from 'react'
import DataDog from 'react-datadog'
import { useAppEnv } from '../../hooks'
import { datadogApplicationId, datadogClientToken, ApplicationBuild } from '../../globals'

type SystemMonitoringProps = {
  serviceName: string
}

export const SystemMonitoring: FC<SystemMonitoringProps> = ({ serviceName, children }) => {
  const { appEnv, isAppEnv } = useAppEnv()
  const isEnabled = isAppEnv(['production', 'staging'])
  const isSessionReplayRecording = isAppEnv('production')
  const isTrackInteractionsEnabled = isSessionReplayRecording

  if (!datadogApplicationId || !datadogClientToken || !isEnabled) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  return (
    <DataDog
      site="datadoghq.eu"
      sessionReplayRecording={isSessionReplayRecording}
      trackInteractions={isTrackInteractionsEnabled}
      env={appEnv}
      applicationId={datadogApplicationId}
      version={ApplicationBuild}
      clientToken={datadogClientToken}
      service={serviceName}
      enabled={isEnabled}
      defaultPrivacyLevel="mask-user-input"
      sampleRate={100}
      premiumSampleRate={100}
    >
      {children}
    </DataDog>
  )
}
