import { gql } from '@apollo/client'
import { User, userFields, UserRole } from '../data-types'

export type CurrentUserDocumentInput = undefined
export type CurrentUserDocumentResponse = {
  currentUser: User
}
export const CurrentUserDocument = gql`
  query CurrentUser {
    currentUser @rest(type: "User", path: "/users-service/user") {
      ${userFields}
    }
  }
`

export type CurrentUserAuthorizationsDocumentInput = undefined
export type CurrentUserAuthorizationsDocumentResponse = {
  currentUserAuthorizations: {
    role: UserRole
    resources: string[]
  }
}

export const CurrentUserAuthorizationsDocument = gql`
  query CurrentUserRoleAuthorizations {
    currentUserAuthorizations
      @rest(type: "UserAuthorizations", path: "/users-service/user/authorizations") {
      role
      resources
    }
  }
`
