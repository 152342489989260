/* eslint-disable */
// @ts-nocheck

export function detectBrowser() {
  // Opera 8.0+
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isOpera =
    (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0
  if (isOpera) return 'Opera'

  // Firefox 1.0+
  const isFirefox = typeof InstallTrigger !== 'undefined'
  if (isFirefox) return 'Firefox'

  // Safari 3.0+ "[object HTMLElementConstructor]"
  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === '[object SafariRemoteNotification]'
    })(!window.safari || (typeof safari !== 'undefined' && window.safari.pushNotification))
  if (isSafari) return 'Safari'

  // Internet Explorer 6-11
  const isIE = /* @cc_on!@ */ false || !!document.documentMode
  if (isIE) return 'IE'

  // Edge 20+
  const isEdge = !isIE && !!window.StyleMedia
  if (isEdge) return 'Edge'

  // Chrome 1 - 79
  const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
  if (isChrome) return 'Chrome'

  // Edge (based on chromium) detection
  const isEdgeChromium = isChrome && navigator.userAgent.indexOf('Edg') != -1
  if (isEdgeChromium) return 'EdgeChromium'

  // Blink engine detection
  const isBlink = (isChrome || isOpera) && !!window.CSS
  if (isBlink) return 'Blink'
}
/* eslint-enable */
