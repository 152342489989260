import { gql } from '@apollo/client'
import {
  ConsumerPaymentMethods,
  consumerPaymentMethodsFields,
  WWFAccount,
  wwfAccountFields,
} from '@web-panel/api'

export type PaymentMethodsDocumentInput = undefined
export type PaymentMethodsDocumentResponse = {
  paymentMethods: ConsumerPaymentMethods
}
export const PaymentMethodsDocument = gql`
  query PaymentMethods {
    paymentMethods @rest(type: "ConsumerPaymentMethods", path: "/finance-service/payment/methods") {
      ${consumerPaymentMethodsFields}
    }
  }
`

export type CreateWWFAccountDocumentInput = {
  input: {
    countryCode: string
    city: string
    postalCode: string
    address: string
    firstName: string
    lastName: string
    title?: string
  }
}
export type CreateWWFAccountDocumentResponse = void
export const CreateWWFAccountDocument = gql`
  mutation CreateWWFAccount($input: any) {
    createWWFAccount(input: $input)
      @rest(
        type: "NoResponse"
        path: "/finance-service/payment/methods/wwf_account"
        method: "POST"
      ) {
      NoResponse
    }
  }
`

export type UpdateWWFAccountDocumentInput = {
  id: string
  input: Partial<{
    email: string
    birthdate: string
    dataProcessConfirmed: boolean
    newslettersOptIn: boolean
  }>
}
export type UpdateWWFAccountDocumentResponse = {
  updateWWFAccount: WWFAccount
}
export const UpdateWWFAccountDocument = gql`
  mutation UpdateWWFAccount($id: String!, $input: any) {
    updateWWFAccount(id: $id, input: $input) @rest(type: "ConsumerWWFAccount", path: "/finance-service/payment/methods/wwf_account/{args.id}", method: "PUT") {
      ${wwfAccountFields}
    }
  }
`

export type DeleteWWFAccountDocumentInput = {
  id: string
}
export type DeleteWWFAccountDocumentResponse = void
export const DeleteWWFAccountDocument = gql`
  mutation DeleteWWFAccount($id: String!) {
    deleteWWFAccount(id: $id)
      @rest(
        type: "NoResponse"
        path: "/finance-service/payment/methods/wwf_account/{args.id}"
        method: "DELETE"
      ) {
      NoResponse
    }
  }
`
