import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { DialogContent, buildModal, ModalBodyProps, Icon, Typography } from '@web-panel/shared'
import { SupportChat } from '@local/components/SupportChat'

const SupportChatModalComponent: FC<ModalBodyProps> = ({ onClose }) => {
  return (
    <DialogContent className="p-0">
      <div className="h-full flex flex-col">
        <div className="relative w-full text-center py-20">
          <Typography color="primary" variant="h4">
            <FormattedMessage id="account_support_chat_title" />
          </Typography>
          <div className="absolute top-0 bottom-0 right-24 flex flex-col justify-center">
            <Icon color="primary" onClick={onClose}>
              close
            </Icon>
          </div>
        </div>
        <SupportChat authenticated />
      </div>
    </DialogContent>
  )
}

export const SupportChatModal = buildModal(SupportChatModalComponent, {
  classes: {
    paperFullScreen: 'rounded-none',
  },
  fullScreen: true,
})
