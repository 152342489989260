import { FC } from 'react'
import { styled } from '@mui/material/styles'
import classNames from 'classnames'

export const CardedContentContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '20px 20px 0 0',
}))

type CardedContentProps = {
  padded?: boolean
  paddedSm?: boolean
  w3xl?: boolean
}

export const CardedContent: FC<CardedContentProps> = ({
  children,
  padded = false,
  paddedSm = false,
  w3xl = false,
}) => {
  if (!padded) return <CardedContentContainer>{children}</CardedContentContainer>

  return (
    <CardedContentContainer>
      <div className={classNames(w3xl ? 'max-w-3xl' : 'max-w-2xl', paddedSm ? 'p-16' : 'md:p-16')}>
        {children}
      </div>
    </CardedContentContainer>
  )
}
