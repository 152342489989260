import { ActivityType } from './activity'

export enum ContainerType {
  Container = 'CONTAINER',
}

export enum ContainerStatus {
  Operations = 'OPERATIONS',
  Ready = 'READY',
  Delivered = 'DELIVERED',
  ReadyReturn = 'READY_RETURN',
  Unknown = 'UNKNOWN',
  Deleted = 'DELETED',
}

export enum ContainerCapacityType {
  Small = 'SMALL',
  Large = 'LARGE',
  DispenserTube = 'DISPENSER_TUBE',
  Default = 'DEFAULT',
}

export enum ContainerHolderType {
  OperationsCenter = 'OPERATIONS_CENTER',
  PartnerOutlet = 'PARTNER_OUTLET',
  Consumer = 'CONSUMER',
  Box = 'BOX',
  Unknown = 'UNKNOWN',
}

export type Container = {
  id: string
  qrCode: string
  type: ContainerType
  status: ContainerStatus
  statusUpdateDate: string
  capacityType: ContainerCapacityType
  capacity: number
  holderId: string | null
  holderType: ContainerHolderType
  holderUpdateDate: string
  orderId: string | null
  orderExternalId: number | null
}

export const containerFields = `
  id
  qrCode
  type
  status
  statusUpdateDate
  capacityType
  capacity
  holderId
  holderType
  holderUpdateDate
  orderId
  orderExternalId
`

export enum ContainerActivityTrigger {
  Administrator = 'ADMINISTRATOR',
  OperationsManager = 'OPERATIONS_MANAGER',
  Partner = 'PARTNER',
  Consumer = 'CONSUMER',
  Box = 'BOX',
  PartnerEmployee = 'PARTNER_EMPLOYEE',
  SystemAutomation = 'SYSTEM_AUTOMATION',
  ExternalOperation = 'EXTERNAL_OPERATION',
}

export enum ContainerReferenceLinkType {
  Item = 'ITEM',
  Container = 'CONTAINER',
  Batch = 'BATCH',
  Order = 'ORDER',
  Box = 'BOX',
  OperationsCenter = 'OPERATIONS_CENTER',
  PartnerOutlet = 'PARTNER_OUTLET',
  Consumer = 'CONSUMER',
}

export type ContainerActivity = {
  id: string
  containerId: string
  type: ActivityType
  comment: string
  doneBy: string
  containerStatus: ContainerStatus
  referenceLinkId: string
  referenceLinkType: ContainerReferenceLinkType
  trigger: ContainerActivityTrigger
  createDate: string
}

export const containerActivityFields = `
  id
  containerId
  type
  comment
  doneBy
  containerStatus
  referenceLinkId
  referenceLinkType
  trigger
  createDate
`
