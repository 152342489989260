import { FC } from 'react'
import { Typography, Button, FormattedCurrency } from '@web-panel/shared'
import { Campaign } from '@web-panel/api'
import PageHeader from '@local/components/PageHeader'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import Lottie from 'lottie-react'
import confettiAnimation from '@local/assets/animations/confetti.json'
import { getCampaignTheme } from '@local/components/PromoCampaign/consts'

type PromoCampaignWinScreenProps = {
  campaign: Campaign
}

export const PromoCampaignWinScreen: FC<PromoCampaignWinScreenProps> = ({ campaign }) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  const campaignTheme = getCampaignTheme(campaign.code)

  const handleOnShare = () => {
    if (!navigator.share) return
    const text = formatMessage({ id: 'golden_cup_share_message_text' })
    navigator.share({ text })
  }

  const handleOnSkip = () => {
    navigate('/')
  }

  const htmlTitle = [
    formatMessage({ id: 'golden_cup_win_screen_title1' }),
    formatMessage({ id: 'golden_cup_win_screen_title2' }),
    formatMessage({ id: `promo_campaign_name.${campaign.code}` }),
  ].join(' ')

  return (
    <div className="flex flex-col h-full items-center justify-between">
      <div className="px-24">
        <PageHeader
          closable
          htmlTitle={htmlTitle}
          title={
            <>
              <FormattedMessage id="golden_cup_win_screen_title1" />
              <br /> <FormattedMessage id="golden_cup_win_screen_title2" />
              &nbsp;
              <span style={{ color: campaignTheme?.text }}>
                <FormattedMessage id={`promo_campaign_name.${campaign.code}`} />
              </span>
            </>
          }
          titleAlign="center"
        />
        <div className="relative">
          <div className="flex flex-row justify-center py-10">
            <img width="200px" src={campaignTheme?.cupIllustration} alt="golden cup" />
          </div>
          <div className="absolute top-0 -left-24 -right-24 bottom-0">
            <Lottie animationData={confettiAnimation} loop />
          </div>
        </div>
        <div>
          <Typography variant="h1" color="primary.main" align="center">
            <FormattedMessage id="golden_cup_win_screen_message" />
          </Typography>
          <Typography
            variant="h1"
            color="primary.main"
            align="center"
            sx={{ color: campaignTheme?.text }}
          >
            <FormattedCurrency
              value={campaign?.amount ?? 0}
              currency={campaign?.currencyCode ?? ''}
              minimumFractionDigits={0}
            />
            !
          </Typography>
          <Typography variant="body1" color="secondary.main" align="center" className="mt-12">
            <FormattedMessage id="golden_cup_win_screen_description" />
          </Typography>
        </div>
      </div>
      <div className="px-24 w-full pb-24">
        <div className="h-72">
          <div className="fixed bottom-44 left-0 right-0 px-24">
            <Button fullWidth variant="contained" onClick={handleOnShare}>
              <FormattedMessage id="golden_cup_win_screen_share" />
            </Button>
          </div>
        </div>
        <Typography
          variant="body2"
          sx={{ fontWeight: 600, color: '#A7A7A7' }}
          align="center"
          onClick={handleOnSkip}
        >
          <FormattedMessage id="golden_cup_win_screen_share_skip_share" />
        </Typography>
      </div>
    </div>
  )
}
