import { RewardTemplateType } from '@web-panel/api'
import illustrations from '@local/assets/illustrations'

const rewardToIllustration: Record<RewardTemplateType, string> = {
  [RewardTemplateType.Donation]: illustrations.wwfLoyaltyReward,
  [RewardTemplateType.CashBack]: illustrations.cashbackLoyaltyReward,
  [RewardTemplateType.Physical]: illustrations.physicalLoyaltyReward,
  [RewardTemplateType.Voucher]: illustrations.voucherLoyaltyReward,
}

export function getIllustrationForRewardType(rewardType: RewardTemplateType): string {
  return rewardToIllustration[rewardType]
}

export function getLoyaltyRewardLabelKey(rewardType: RewardTemplateType): string {
  switch (rewardType) {
    case RewardTemplateType.Donation:
      return 'loyalty_card_reward_donation'
    case RewardTemplateType.CashBack:
      return 'loyalty_card_reward_cashback'
    case RewardTemplateType.Physical:
      return 'loyalty_card_reward_physical'
    case RewardTemplateType.Voucher:
      return 'loyalty_card_reward_voucher'
    default:
      return ''
  }
}

export function getLoyaltyRewardModalTitle(rewardType: RewardTemplateType): string {
  switch (rewardType) {
    case RewardTemplateType.Donation:
      return 'loyalty_card_reward_kooky_donation'
    case RewardTemplateType.CashBack:
      return 'loyalty_card_reward_kooky_cashback'
    case RewardTemplateType.Physical:
      return 'loyalty_card_reward_kooky_physical'
    case RewardTemplateType.Voucher:
      return 'loyalty_card_reward_kooky_voucher'
    default:
      return ''
  }
}

export function getLoyaltyRewardModalDescription(rewardType: RewardTemplateType): string {
  switch (rewardType) {
    case RewardTemplateType.Donation:
      return 'loyalty_reward_modal_donation_description_web'
    case RewardTemplateType.CashBack:
      return 'loyalty_reward_modal_cashback_description_web'
    case RewardTemplateType.Physical:
      return 'loyalty_reward_modal_physical_description_web'
    case RewardTemplateType.Voucher:
      return 'loyalty_reward_modal_voucher_description_web'
    default:
      return ''
  }
}
