import { FC } from 'react'
import Lottie from 'lottie-react'
import loaderAnimation from '@local/assets/animations/loader.json'

export const Loader: FC = () => {
  return (
    <div className="h-full flex flex-column justify-center">
      <Lottie animationData={loaderAnimation} loop />
    </div>
  )
}
