import { setContext } from '@apollo/client/link/context'

export const headersLink = setContext((_request, previousContext) => {
  const headers: Record<string, string> = {
    Accept: 'application/json',
  }

  if (previousContext.apiVersion) {
    headers['x-version'] = previousContext.apiVersion
  }

  return { headers }
})
