import { useQuery, useMutation } from '@apollo/client'
import { QueryHook, MutationHook } from '@web-panel/api'
import {
  CampaignsDocument,
  CampaignsDocumentInput,
  CampaignsDocumentResponse,
  CampaignExceptionsDocument,
  CampaignExceptionsDocumentInput,
  CampaignExceptionsDocumentResponse,
  UpdateCampaignExceptionsDocument,
  UpdateCampaignExceptionsDocumentInput,
  UpdateCampaignExceptionsDocumentResponse,
} from '../docs'

export const useCampaigns: QueryHook<
  CampaignsDocumentInput,
  CampaignsDocumentResponse['campaignsCollection']['campaigns']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<CampaignsDocumentResponse>(CampaignsDocument, {
    ...options,
    variables,
    context: { isPublic: true },
  })

  return {
    data: data?.campaignsCollection.campaigns,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useCampaignExceptions: QueryHook<
  CampaignExceptionsDocumentInput,
  CampaignExceptionsDocumentResponse['campaignExceptions']['userCampaignExceptions']
> = (options) => {
  const { data, loading, refetch } = useQuery<CampaignExceptionsDocumentResponse>(
    CampaignExceptionsDocument,
    options
  )

  return {
    data: data?.campaignExceptions.userCampaignExceptions,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useUpdateCampaignExceptions: MutationHook<
  UpdateCampaignExceptionsDocumentInput,
  UpdateCampaignExceptionsDocumentResponse
> = (options) => {
  const [execute, { loading }] = useMutation(UpdateCampaignExceptionsDocument, {
    ...options,
    refetchQueries: [{ query: CampaignExceptionsDocument }],
    awaitRefetchQueries: true,
  })

  async function request(variables: UpdateCampaignExceptionsDocumentInput) {
    await execute({ variables })
  }

  return {
    request,
    loading,
  }
}
