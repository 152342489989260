import { FC } from 'react'

import { defaultColor, activeColor, defaultSize } from './constants'
import { IconProps } from './types'

export const LoyaltyListIcon: FC<IconProps> = ({ active = false, size = defaultSize }) => {
  const color = active ? activeColor : defaultColor

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="17" r="1" fill={color} />
      <circle cx="12" cy="17" r="1" fill={color} />
      <circle cx="16" cy="17" r="1" fill={color} />
      <path
        d="M12 1L13.854 4.94953L18 5.58675L15 8.65931L15.708 13L12 10.9495L8.292 13L9 8.65931L6 5.58675L10.146 4.94953L12 1Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.487 6H8.64831L10.0715 7.45466L9.51411 8H14.354L13.4222 7.08834L14.487 6ZM18.1028 8.00173L19.7524 6.31568C21.6495 7.02569 23 8.85512 23 11V18C23 20.7614 20.7614 23 18 23H6C3.23858 23 1 20.7614 1 18V11C1 8.89139 2.30526 7.08764 4.15189 6.35265L5.77196 8.00854C4.22164 8.12506 3 9.41987 3 11V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V11C21 9.37754 19.712 8.0559 18.1028 8.00173Z"
        fill={color}
      />
    </svg>
  )
}
